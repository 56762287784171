import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const StyledButton = styled(Button)`
 && {
  background-color: #ffc425 !important;
  padding:10px 15px !important;
  border-radius:15px !important;
  color:#242424 !important;
  font-weight:900 !important;
  text-shadow:none !important;
  font-family: industry, sans-serif !important;
  && :hover {
    background-color: #ffc425 !important;
  border-radius:15px !important;
  color:#242424 !important;
  font-weight:900 !important;
  text-shadow:none !important;
  font-family: industry, sans-serif !important;
  }
}
  `;

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
 && {
  width: 16px;
  margin-left: 5px;
}
  `;

function CTA(props) {
    return (
      <StyledButton href={props.href} target={props.target} >
      {props.text} <StyledArrowForwardIcon />
      </StyledButton>
    );
  }

  CTA.propTypes = {
    text: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string

  };

export default CTA;