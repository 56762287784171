import React from 'react';
import {useState } from 'react';
import Notes from './Notes';
import ProjectDetails from './ProjectDetails';
import Logo from './Logo'

function makeOpts(list){
    return list.map(e => {
        return(<option key={e} value={e}>{e}</option>);
    });
}

function makeLinks(obj){
    return Object.keys(obj).map(e => {
        return <a href={obj[e]}>{e}</a>
    });
}

const makeTable = (head, list) => {
    let out = [];

    if(Array.isArray(list)){
        list.forEach((e)=>{
            out.push(<tr><td>{e}</td></tr>);
        });
        return(
            <table>
                <tr><th colSpan="2">{head}: </th></tr>
                <tbody>
                    {out}
                </tbody>
            </table>
        );
    }
}

export default function SweatBeeTemplate(props){

    let fan = props.fan;

    const [size, setSize] = useState("");
    const [volts, setVolts] = useState("");
    const [mount, setMount] = useState("");

    return (
        <div>
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
                <div>
                <ProjectDetails />
                <table>
                    <thead><th colSpan="2">Fan Details: </th></thead>
                    <tbody>
                        <tr><td>Quantity: </td><td><input type="number" min="1" required></input></td></tr>
                        {fan.sizes && <tr><td>Size (in): </td><td><select required onChange={e => {setSize(e.target.value)}}><option></option>{makeOpts(fan.sizes)}</select></td></tr>}                        
                        {fan.volts[size] && <tr><td>Voltage/Phase:</td><td><select required onChange={e => setVolts(e.target.value)}><option></option>{makeOpts(fan.volts[size])}</select></td></tr>}
                        {fan.motor[size] && <tr><td>Motor: </td><td>{fan.motor[size]} HP</td></tr>}
                        {fan.rpm[size] && <tr><td>Max RPM: </td><td>{fan.rpm[size]} RPM</td></tr>}
                        {fan.maxSound[size] && <tr><td>Max Sound Level: </td><td>{fan.maxSound[size]} dBA</td></tr>}
                        {fan.maxAmps[volts] && <tr><td>Max Amps: </td><td>{fan.maxAmps[volts]} A</td></tr>}
                        {fan.directionalMounts[size] && <tr><td>Mounting Hardware: </td><td><select required onChange={e => {setMount(e.target.value)}}><option></option>{makeOpts(fan.directionalMounts[size])}</select></td></tr>}
                        {fan.weights[size+volts+mount] && <tr><td>Standard Weight: </td><td>{fan.weights[size+volts+mount]} lbs</td></tr>}
                        {fan.rcs[volts] && <tr><td>Supply Circuit Size: </td><td>{fan.rcs[volts]} A</td></tr>}
                        {mount === "Yoke Mnt" && fan.yokeET && <tr><td>ET Length: </td><td><select required><option></option>{makeOpts(fan.yokeET)}</select></td></tr>}
                    </tbody>
                </table>
                <table>
                    <thead><th colSpan="2">Critical Dimensions: </th></thead>
                    <tbody>
                        <tr><td>Height: </td><td>{fan.height[size+volts+mount]}"</td></tr>
                        <tr><td>Width: </td><td>{fan.width[size+volts+mount]}"</td></tr>
                        <tr><td>Depth: </td><td>{fan.depth[size+volts+mount]}"</td></tr>
                    </tbody>
                </table>
                </div>
                <div>
                <div>
                    {fan === undefined && <Logo name={"Generic Name"} />}
                    {fan !== undefined && <Logo name={fan.name === 'Sweat Bee Swivel' ? fan.name : fan.name  + " " + size} />}
                    {fan.features && makeTable("Features", fan.features)}
                    {fan.appearanceOpts && 
                        <table>
                    <thead>
                        <tr><th colSpan="2">Appearance Options: </th></tr>
                        <tr><th>Part</th><th>Style</th></tr>
                    </thead>
                    <tbody>
                        <tr><td><select><option></option>{makeOpts(fan.parts)}</select></td><td><select><option></option>{makeOpts(fan.appearanceOpts)}</select></td></tr>
                        <tr><td><select><option></option>{makeOpts(fan.parts)}</select></td><td><select><option></option>{makeOpts(fan.appearanceOpts)}</select></td></tr>
                        <tr><td><select><option></option>{makeOpts(fan.parts)}</select></td><td><select><option></option>{makeOpts(fan.appearanceOpts)}</select></td></tr>
                    </tbody>
                    </table>}
                </div>
                <table><thead><th>Additional Options: </th></thead>
                        <tbody>
                            <tr><td><select><option></option>{makeOpts(fan.additions)}</select></td></tr>
                            <tr><td><select><option></option>{makeOpts(fan.additions)}</select></td></tr>
                            <tr><td><select><option></option>{makeOpts(fan.additions)}</select></td></tr>
                        </tbody>
                </table>
                <Notes />
                    {fan.links && makeLinks(fan.links)}
                    {fan.warranty && 
                        <table>
                            <thead>
                                <tr><th colSpan="2">Warranty Information </th></tr>
                                <tr><th>Item</th><th>Period of Coverage</th></tr>
                            </thead>                            
                            <tbody>
                                {Object.keys(fan.warranty).map(e => {
                                    return <tr><td>{e}</td><td>{fan.warranty[e]}</td></tr>
                                })}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            <div style={{width:"50%", margin:"auto", display:"grid", height:"100", alignContent:"center" }}>{size && <img style={{maxHeight:150, maxWidth:600}} alt={`${fan.name + " " + size} dwg`} src={`${window.location.origin}/technicalDrawings/${fan.name === 'Sweat Bee Swivel' ? fan.name : fan.name  + " " + size}.png`} />}</div>
        </div>
    );
}