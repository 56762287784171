import React, {useState} from 'react';

export default function ProjectDetails(){
    let today = new Date();

    const [date, setDate] = useState((today.getMonth() + 1)+ '/' + today.getDate() + '/' + today.getFullYear())

    return(
        <div style={{projectStyle}}>
                <div >
                <table>
                    <tr>
                        <th colSpan="2">Project Details:</th>
                    </tr>
                    <tbody>
                    <tr><td>Project:</td><td><input required></input></td></tr>
                    <tr><td>Location: </td><td><input required></input></td></tr>
                    <tr><td>Date: </td><td><input value={date} onChange={(e) => {setDate(e.target.value)}}></input></td></tr>
                    <tr><td>Submitted by: </td><td><input required></input></td></tr>
                    <tr><td>Architect: </td><td><input></input></td></tr>
                    <tr><td>Engineer: </td><td><input></input></td></tr>
                    <tr><td>Contractor: </td><td><input></input></td></tr>
                    <tr><td>Tags: </td><td><input></input></td></tr>
                    </tbody>
                </table>
                </div>
            </div>
    );
}

  const projectStyle = {
    border:"none"
  }