import React from "react";
import styled from "styled-components";

const CustomTable = styled.table`
  &&& {
    text-align: left;
    width: 100%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
  th {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 20px 20px;
  }
  td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 10px 20px;
  }
`;

function Table1() {
  return (
    <CustomTable>
      <tbody>
        <tr>
          <th>Factor</th>
          <th>Description</th>
        </tr>
        <tr>
          <td>Dry bulb temperature</td>
          <td>
            The temperature measured by a dry thermometer in the open air. As
            the temperature is reduced, the cooling sensation is increased.
          </td>
        </tr>
        <tr>
          <td>Relative humidity</td>
          <td>
            The amount of water vapor present in the air relative to saturated
            air. As the relative humidity is reduced, the cooling sensation is
            increased.
          </td>
        </tr>
        <tr>
          <td>Radiant temperature</td>
          <td>
            The average temperature of surfaces such as walls, floors, ceilings,
            etc. in the space. As the mean radiant temperature is reduced, the
            cooling sensation is increased.
          </td>
        </tr>
        <tr>
          <td>Air speed</td>
          <td>
            The speed of air passing over the occupant. As the air speed is
            increased, the cooling sensation is increased.
          </td>
        </tr>
        <tr>
          <td>Metabolic rate</td>
          <td>
            An occupant’s heat generation resulting from their activity or
            stress level. As the metabolic rate is increased, the body generates
            more heat and the cooling sensation is decreased.
          </td>
        </tr>
        <tr>
          <td>Clothing level</td>
          <td>
            The insulating properties of an occupant’s attire. As the clothing
            level is increased, less heat is removed from the body and the
            cooling sensation is decreased.
          </td>
        </tr>
      </tbody>
    </CustomTable>
  );
}

export default Table1;
