import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import { ListItemText } from '@material-ui/core';

const StyledListItem = styled(ListItemText) `
&& {
    color: #242424;
    line-height: 1;
    margin-bottom:0px;
    font-size:12px;
    text-decoration: underline;
    font-family: 'Proxima Nova W15';
}
`

function ListItem(props)  {
    return (
        <StyledListItem>{props.name}</StyledListItem>
    );
}

ListItem.propTypes = {
    name: PropTypes.string
  };

export default ListItem;