import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ListItemText } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";

const StyledListItem = styled(ListItemText)`
  && {
    color: #fff;
    line-height: 1;
    margin-bottom: 0px;
    font-size: 14px;
    font-family: 'Proxima Nova W15';
  }
`;
const StyledHomeIcon = styled(HomeIcon)`
  && {
    vertical-align: text-bottom;
    padding-right: 5px;
  }
`;

function HomeNavItem(props) {
  return (
    <StyledListItem {...props.href}>
      <StyledHomeIcon />
      {props.name}
    </StyledListItem>
  );
}

HomeNavItem.propTypes = {
  name: PropTypes.string,
  href: PropTypes.string
};

export default HomeNavItem;
