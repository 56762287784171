import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C2S2() {
  return (
    <div className="chapter-container">
      <Anchor id="2-2-1" />
      <H2 name="2.2 Heat Stress and Productivity" />
      <H4 name="2.2.1 What Is Heat Stress?" />
      <p>
        Heat stress is the buildup of heat in the body to unhealthy levels. In
        severe cases, it can lead to illnesses such as heat exhaustion and heat
        stroke. Both internal and external factors can contribute to heat
        stress. For example, strenuous activity that increases the metabolic
        rate will increase the amount of heat generated by the body.
        Additionally, if a person is in a hot environment, their body is less
        able to reject heat to the surroundings and overheating can occur. While
        individuals may be able to control the internal causes of heat stress by
        limiting activity, it is up to building operators and engineers to
        design a safe, comfortable indoor environment. Especially in situations
        where occupants are expected to have high levels of activity, such as
        gyms or warehouses, thermal comfort is key to keeping workers safe and
        healthy.
      </p>
      <p>
        The Occupational Safety and Health Administration (OSHA) has created a
        heat index to evaluate the risk of heat-related illness in a given
        environment. The index takes into account both the temperature and
        relative humidity in a space, as both contribute to the body’s capacity
        to reject heat. The heat index ranges from temperatures less than 91°F
        (33°C) to temperatures above 115°F (46°C) and assigns risk levels of
        Lower, Moderate, High, and Very Extreme to High. OSHA provides
        recommendations of precautions to take at each risk level. The OSHA heat
        index is shown in Figure 2.2.1a below.
      </p>

      <Container maxWidth="lg" className="center mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/2_2_HeatIndex.jpg"
          width="100%"
          alt="Heat Index Image"
        />
        <Caption text="Figure 2.2.1a - Heat Index is a measure of how hot it “feels” to the body." />
      </Container>

      <H4 name="2.2.2 How Thermal Comfort Impacts Productivity" />
      <p>
        In addition to the health risks presented by overheating, studies have
        also found productivity decreases when occupants are too warm. This loss
        of productivity can occur at much lower temperatures than the range that
        causes heat stress, providing an incentive to strive for thermal comfort
        beyond basic safety. According to a study conducted by Olli Seppanen and
        William Fisk at Helsinki University of Technology in Finland, worker
        performance starts to decrease at only 77°F (25°C). And an additional 2%
        decrease in performance is seen for every additional 1.8°F (1°C)
        increase up to 90°F (32°C). Above 90°F (32°C), heat-related illness risk
        increases and safety becomes a greater concern.{" "}
      </p>
      <p>
        In buildings with comfort cooling, maintaining an indoor temperature
        below 77°F (25°C) is typically as simple as setting a thermostat;
        however, not all buildings have a mechanical cooling system. In some
        space types, such as warehouses, it is not uncommon to forego an AC
        system. Christian Taber and Donald Colliver simulated the impact of air
        movement and elevated air speed on thermal comfort in these settings in
        a published ASHRAE Journal piece (Thermal Comfort in
        Heated-and-Ventilated Only Warehouses). The study simulates a typical
        warehouse (50,000 ft2 (4645 m2)) built to increasingly stringent energy
        codes and predicts the occupant thermal comfort and productivity levels
        at each code revision. Additional simulations also introduce elevated
        air speed and evaluate the impact on productivity. The study found that
        without any cooling system in place, there were a significant number of
        hours outside of the ASHRAE Standard 55-defined thermal comfort range,
        especially in warmer climate zones. An equation developed by Srinavin
        and Mohamed was then used to calculate productivity loss based on
        thermal discomfort. The equation is based on PMV, the index of predicted
        occupant comfort defined by ASHRAE Standard 55. By calculating
        productivity loss, the financial impacts of reduced productivity may be
        ascertained based on worker occupancy levels and wages. The
        Taber/Colliver study calculates annual cost of productivity loss due to
        high PMV both with and without elevated air speed. Simulations showed
        that adding 160 fpm (0.81 m/s) resulted in a significant decrease in the
        number of uncomfortable hours and the financial impact of reduced
        productivity. Productivity losses varied by climate zone, with warmer
        zones seeing larger losses, but on average, annual productivity losses
        under the 90.1-2016 energy code were $6,272. This decreased to $5,227
        with the addition of elevated air speed (at 160 fpm (0.81 m/s)), or a
        17% reduction in losses.
      </p>

      <Anchor id="2-2-3" />
      <H4 name="2.2.3 Overview of Productivity Calculator" />
      <p>
        Cost is a major factor in the selection of an HVAC system. Building
        owners and operators will look at first cost, and sometimes even
        operating cost, when deciding on a system, but often the value of
        increased occupant productivity is overlooked. This may be due in part
        to the perception that calculating the impact of productivity loss is
        difficult. Big Ass Fans provides a simple online tool to assist with
        this calculation.{" "}
      </p>
      <p>
        The BAF Productivity Calculator is available on the Big Ass Fans website
        under Resources. It utilizes the same methodology described in the
        Taber/Colliver study to calculate productivity loss due to thermal
        comfort. The only required inputs are the state where the building is
        located, number of employees, average employee hourly wages, and average
        hours per week per employee. The Calculator computes the annual losses
        due to reduced productivity, as well as the potential gains in
        productivity and profit achieved by adding elevated air speed. While
        these savings are approximate, they provide concrete justification for
        installing a fan system and show the magnitude of savings that stands to
        be gained. This information demonstrates how savings from increased
        productivity can offset the first cost of a fan system over the lifetime
        of a building.{" "}
      </p>
      <p>
        Examples of the financial justifications for elevated air speed due to
        productivity gains can be found in Chapter 3 Section 8.{" "}
      </p>
    </div>
  );
}

export default C2S2;
