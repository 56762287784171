import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledImage = styled.img`
text-align:center;
max-height:auto;
`;

function Image(props) {
  const { src, alt, width, maxWidth } = props;
  return <StyledImage src={src} alt={alt} width={width} maxWidth={maxWidth} />;
}

Image.propTypes = {
  alt: PropTypes.string,
  bg: PropTypes.string,
  maxWidth: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.string
};

export default Image;
