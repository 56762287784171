import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import ListSubHeader from '@material-ui/core/ListSubheader';

const StyledListHeader = styled(ListSubHeader) `
&& {
    color: #242424;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom:10px;
    font-size:18px;
    font-family: industry, sans-serif;
    padding-left:0px;
    font-weight: 700;
    margin-top: 3px;
    @media (max-width: 768px) {
        font-size: 2em;
      }
}
`

function ListHeader(props)  {
    return (
        <StyledListHeader>{props.name}</StyledListHeader>
    );
}

ListHeader.propTypes = {
    name: PropTypes.string
  };

export default ListHeader;