import React, { Fragment } from "react";
import { HashLink } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Add from "@material-ui/icons/Add";
import Remove from "@material-ui/icons/Remove";

function DropdownC2S3() {
  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex"
    }
  }));

  const classes = useStyles();

  const [openCollapse, setOpenCollapse] = React.useState(false);

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }

  return (
    <Fragment>
      <ListItem button onClick={handleOpenSettings}>
        <ListItemText
          primary="2.3 Ceiling Fan Use in Heating"
          className="white"
        />
        {openCollapse ? (
          <Remove className="white" />
        ) : (
          <Add className="white" />
        )}
      </ListItem>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <HashLink scroll={el =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            } to="/chapter-2#2-3-1">
            <ListItem button className={classes.nested}>
              <FiberManualRecordIcon
                className="yellow"
                style={{ fontSize: "1rem" }}
              />
              <span className="nav-link">2.3.1 What Is Stratification?</span>
            </ListItem>
          </HashLink>
          <Divider />
          <HashLink scroll={el =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            } to="/chapter-2#2-3-2">
            <ListItem button className={classes.nested}>
              <FiberManualRecordIcon
                className="yellow"
                style={{ fontSize: "1rem" }}
              />
              <span className="nav-link">
                2.3.2 Destratification Using Ceiling Fans
              </span>
            </ListItem>
          </HashLink>
          <Divider />
          <HashLink scroll={el =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            } to="/chapter-2#2-3-3">
            <ListItem button className={classes.nested}>
              <FiberManualRecordIcon
                className="yellow"
                style={{ fontSize: "1rem" }}
              />
              <span className="nav-link">
                2.3.3 How Fan CFM Performance Is Measured
              </span>
            </ListItem>
          </HashLink>
          <Divider />
          <HashLink scroll={el =>
              el.scrollIntoView({ behavior: "smooth", block: "start" })
            } to="/chapter-2#2-3-4">
            <ListItem button className={classes.nested}>
              <FiberManualRecordIcon
                className="yellow"
                style={{ fontSize: "1rem" }}
              />
              <span className="nav-link">
                2.3.4 Improved Ventilation in Heating
              </span>
            </ListItem>
          </HashLink>
          <Divider />
        </List>
      </Collapse>
    </Fragment>
  );
}

export default DropdownC2S3;
