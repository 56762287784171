import React, { Component, Fragment } from "react";
import C1S1 from "./Chapter-1/1.1";
import C1S2 from "./Chapter-1/1.2";
import C1S3 from "./Chapter-1/1.3";
import C1S4 from "./Chapter-1/1.4";
import Copyright from "../components/UI-Kit/Copyright";
import MobileRecommendationButton from "./UI-Kit/Mobile-Recommendation";

const isMobile = window.innerWidth <= 500;
class ScrollToTopOnMount extends Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

function Chapter1() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className="main-content">
        <C1S1 />
        <C1S2 />
        <C1S3 />
        <C1S4 />
        {isMobile ? <MobileRecommendationButton /> : null}
        <Copyright text="Copyright 2019 Delta T, LLC. All rights reserved" className="mt75"/>
      </div>
    </Fragment>
  );
}

export default Chapter1;
