import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const StyledH4= styled.h4 `
    color: #a6a6a6;
    line-height: 1;
    text-transform: uppercase;
    margin: 20px 0px 0px 0px;
    font-size:1.25em;
`

function H4(props)  {
    return (
        <StyledH4>{props.name}</StyledH4>
    );
}

H4.propTypes = {
    name: PropTypes.string
  };
  
export default H4;