import React from "react";
import H1 from "../UI-Kit/H1";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Table1 from "./table-1.1";

function C1S1() {
  return (
    <div className="chapter-container">
      <Anchor id="1-1-1" />
      <H1 name="Introduction" />
      <H2 name="1.1 Benefits of Ceiling Fans" />
      <H4 name="1.1.1 Thermal Comfort" />
      <p>
        Thermal comfort is an occupant’s level of satisfaction with the
        perceived temperature of their surrounding environment. The level of
        satisfaction is directly related to how much heat is lost from an
        occupant’s body. Too little and an occupant will feel hot. Too much and
        an occupant will feel cold. Six factors that affect occupant heat
        transfer and resulting thermal comfort are described in the American
        Society of Heating Refrigeration and Air-conditioning Engineers (ASHRAE)
        Standard 55: Thermal Environmental Conditions for Human Occupancy. These
        factors are: dry bulb temperature, relative humidity, radiant
        temperature, air speed, metabolic rate, and clothing level.
      </p>
      <p>
        Two of these factors are determined by occupants themselves metabolic
        rate and clothing level. A typical HVAC system directly controls dry
        bulb temperature in a building. The other three factors (relative
        humidity, radiant temperature, and air speed) are not directly controlled
        by the HVAC system but often result by maintaining the space’s desired
        dry bulb temperature.
      </p>
      <p>
        When occupants feel hot or cold they typically respond by adjusting the
        thermostat, as it is often the only means of adjusting the indoor
        environment. But it is not uncommon for occupants to achieve thermal
        comfort by using fans and space heater devices that do not
        significantly change dry bulb temperature but affect thermal comfort.
        Fans increase air speed, which increases heat transfer from an occupant
        to the space and creates a cooling effect. Space heaters increase mean
        radiant temperature by emitting radiant energy that strikes the occupant
        and creates a heating effect.
      </p>
      <p>
        This guide will explore how adjusting air speed can impact building and
        HVAC system design while maintaining the same level of occupant thermal
        comfort as a typical HVAC system.
      </p>
      <div className="chapter-content mt40">
        <Table1 />
      </div>
      <Anchor id="1-1-2" />
      <H4 name="1.1.2 Improved Air Distribution" />
      <p>
        Ceiling fans improve air distribution by increasing the circulation rate
        of air within a space relative to typical HVAC systems without ceiling
        fans. Increasing the air circulation within a space has positive effects
        on HVAC systems by reducing the extent of supply air ductwork,
        increasing ventilation effectiveness, reducing air temperature
        gradients, and improving cooling thermal comfort. This has the benefit
        of simplifying the HVAC system installation and improving its
        performance.
      </p>

      <Anchor id="1-1-3" />
      <H4 name="1.1.3 First Cost Savings" />
      <p>
        The benefits of improved air distribution offered by ceiling fans can
        reduce the first cost of HVAC systems in multiple ways. Reducing the
        extent of the supply air ductwork reduces not only sheet metal costs but
        also costs associated with reduced system heating and cooling capacity,
        fewer ductwork accessories, fewer terminals, and lower testing and
        balancing costs. This has the added benefit of lowering the first cost
        of the HVAC system where ceiling fans can be incorporated into the
        building design.
      </p>

      <Anchor id="1-1-4" />
      <H4 name="1.1.4 Energy Savings" />
      <p>
        While it is true that ceiling fans consume energy, the energy saved by
        reducing the burden on the HVAC system is far greater. This results in a
        net energy savings for the building. There are several ways that ceiling
        fans reduce the heating and cooling burden on HVAC systems and reduce
        energy consumption. They do this primarily by reducing conductive heat
        losses through the building envelope, improving ventilation
        effectiveness, and reducing HVAC fan energy.
      </p>
    </div>
  );
}

export default C1S1;
