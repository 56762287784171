import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import styled from "styled-components";
import Logo from "./Logo/logo";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import Routes from "./routes";
import Chapter1SubNav from "./Sidebar/chapter-1-dropdown";
import Chapter2SubNav from "./Sidebar/chapter-2-dropdown";
import Chapter3SubNav from "./Sidebar/chapter-3-dropdown";
import NavHeader from "./UI-Kit/NavHeader";
import HomeLink from "./Sidebar/Navigation/home";
import CTA from "./UI-Kit/RecommendationButton";
import { Route, Switch } from "react-router-dom";
import Chapter4SubNav from "./Sidebar/chapter-4-dropdown";


const isMobile = window.innerWidth <= 500;
const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    backgroundColor: "#000;",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "#fff"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#0a0a0a"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  ExpandLess: {
    fill: "#fff"
  }
}));

const StyledContainer = styled(Container)`
  && {
    padding: 0px;
  }
`;

export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          {isMobile ? (
            <StyledContainer className="right mt20">
              <Logo src="../../../HVLS-Logo-BW.png" alt="Big Ass Fans" />
            </StyledContainer>
          ) : (
            <StyledContainer className="left mt20">
              <Logo src="../../../HVLS-Logo-BW.png" alt="Big Ass Fans" />
            </StyledContainer>
          )}
          <Switch>
            <Route path="/recommendation" render={() => null}></Route>
            <Route
              path="/"
              render={() => (
                <StyledContainer className="right mobile-hide">
                  <CTA href="/recommendation" text="Get Recommendation" />
                </StyledContainer>
              )}
            ></Route>
          </Switch>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <CloseIcon className="closeIcon" />
            ) : (
              <MenuIcon />
            )}
          </IconButton>
        </div>

        <HomeLink />

        <NavHeader title="Chapter 1 – Introductions" />

        <Chapter1SubNav />

        <NavHeader title="Chapter 2 – Background &amp; Rationale" />

        <Chapter2SubNav />

        <NavHeader title="Chapter 3 - Applications and Examples" />

        <Chapter3SubNav />

        <NavHeader title="Chapter 4 - Case Studies" />

        <Chapter4SubNav />
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.drawerHeader} />
        <Routes />
      </main>
    </div>
  );
}
Dashboard.propTypes = {
  history: PropTypes.string
};
