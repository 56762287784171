import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const StyledLabel = styled.label `
    color: #242424;
    line-height: 1;
    font-size: 16px;
    font-weight: 700;
    text-align:left;
    margin-top:20px;
    margin-bottom:20px;

`

function FormLabel(props)  {

    return (
        <StyledLabel>
            {props.text}
        </StyledLabel>
    );
}

FormLabel.propTypes = {
    text: PropTypes.string
  };
  
export default FormLabel;