import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

const StyledListItem = styled(ListItem)`
 && {
  color: #242424;
  background-color: #ffc425;
  font-weight: bold;
  text-align: center;
  display: block;
  font-family: industry, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  padding: 10px 0px 10px 0px;
}
  `;

function NavHeader(props) {
    return (
      <List>
        <StyledListItem>{props.title}</StyledListItem>
      </List>
    );
  }

  NavHeader.propTypes = {
    title: PropTypes.string
  };

export default NavHeader;