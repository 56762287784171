import React from 'react';
import {useState } from 'react';
import Notes from './Notes';
import ProjectDetails from './ProjectDetails';
import Logo from './Logo'

function makeOpts(list){
    return list.map(e => {
        return(<option key={e} value={e}>{e}</option>);
    });
}

function makeLinks(obj){
    return Object.keys(obj).map(e => {
        return <a href={obj[e]}>{e}</a>
    });
}

const makeTable = (head, list) => {
    let out = [];

    if(Array.isArray(list)){
        list.forEach((e)=>{
            out.push(<tr><td>{e}</td></tr>);
        });
        return(
            <table>
                <tr><th colSpan="2">{head}: </th></tr>
                <tbody>
                    {out}
                </tbody>
            </table>
        );
    }
}

export default function CoolSpaceTemplate(props){
    const [volts, setVolts] = useState("");
    let fan = props.fan;

    return (
        <div>
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
                <div>
                <ProjectDetails />
                <table>
                    <thead><th colSpan="2">Fan Details: </th></thead>
                    <tbody>
                        <tr><td>Quantity: </td><td><input type="number" min="1" required></input></td></tr>
                        <tr><td>Model: </td><td>{fan.name}</td></tr>
                        <tr><td>Motor: </td><td>{fan.motor} hp</td></tr>
                        <tr><td>Standard Weight: </td><td>{fan.weight} lbs</td></tr>
                        <tr><td>Water Capacity: </td><td>{fan.waterCapacity} gal</td></tr>
                        <tr><td>Inlet Water Pressure: </td>{fan.waterPressure} PSI Max</tr>
                        <tr><td>Voltage/Phase: </td><td><select required onChange={e => {setVolts(e.target.value)}}><option></option>{makeOpts(fan.volts)}</select></td></tr>
                        <tr><td>Supply Circuit Size: </td><td>{fan.rcs[volts]} A</td></tr>
                        <tr><td>Max Amperage: </td><td>{fan.maxAmps[volts]} A</td></tr>
                        <tr><td>Airflow: </td><td>{fan.airflow} cfm</td></tr>
                        <tr><td>Coverage Area: </td><td>{fan.coverageArea} sq ft</td></tr>
                        <tr><td>Sound Level: </td><td>{fan.maxSound} dBA</td></tr>
                    </tbody>
                </table>
                {fan.warranty && 
                        <table>
                            <thead>
                                <tr><th colSpan="2">Warranty Information </th></tr>
                                <tr><th>Item</th><th>Period of Coverage</th></tr>
                            </thead>                            
                            <tbody>
                                {Object.keys(fan.warranty).map(e => {
                                    return <tr><td>{e}</td><td>{fan.warranty[e]}</td></tr>
                                })}
                            </tbody>
                      </table>}
                </div>
                <div>
                <div>
                    {fan === undefined && <Logo name={"Generic Name"} />}
                    {fan !== undefined && <Logo name={fan.name} />}
                    {fan.features && makeTable("Features", fan.features)}
                </div>
                <table>
                    <thead><tr><th colSpan="2">Technical Notes </th></tr></thead>                    
                    <tbody>
                        <tr><td >All data is subject to change without notice. Data indicated in this document were correct at the time of printing with the exception of printing and clerical errors.</td></tr>
                    </tbody>
                </table>
                <Notes />
                    {fan.links && makeLinks(fan.links)}
                </div>
            </div>
            <div style={{width:"50%", margin:"auto", display:"grid", height:"100", alignContent:"center" }}><img style={{maxHeight:500, maxWidth:600}} alt={`${fan.name} dwg`} src={`${window.location.origin}/technicalDrawings/${fan.name}.png`} /></div>
        </div>
    );
}