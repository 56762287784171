import React from 'react';
import LargeFanTemplate from './LargeFanTemplate';
import HaikuTemplate from './HaikuTemplate';
import CoolSpaceTemplate from './CoolSpaceTemplate';
import SweatBeeTemplate from './SweatBeeTemplate';
import BAFConTemplate from './BAFConTemplate';
import ESeriesTemplate from './ESeriesTemplate';
import IRHTemplate from './IRHTemplate';
import UnitHeaterTemplate from './UnitHeaterTemplate';

export default function ProductTemplate(props){
    let fan = props.obj;

    return(
        <div style={{margin:20, marginTop: 0}}>
            <div>
                <hr style={{backgroundColor:'#ffc425', height: 10}} />
            </div>        
            <div>
                {fan !== undefined && (fan.template === "LargeFanTemplate") && <LargeFanTemplate fan={fan}/>}
                {fan !== undefined && (fan.template === "HaikuTemplate") && <HaikuTemplate fan={fan} />}
                {fan !== undefined && (fan.template === "CoolSpaceTemplate") && <CoolSpaceTemplate fan={fan} />}
                {fan !== undefined && (fan.template === "SweatBeeTemplate") && <SweatBeeTemplate fan={fan} />}
                {fan !== undefined && (fan.template === "BAFConTemplate") && <BAFConTemplate fan={fan} />} 
                {fan !== undefined && (fan.template === "ESeriesTemplate") && <ESeriesTemplate fan={fan} />}
                {fan !== undefined && (fan.template === "IRHTemplate") && <IRHTemplate fan={fan} />}
                {fan !== undefined && (fan.template === "UHTemplate") && <UnitHeaterTemplate fan={fan} />}
                {
                    // if there was another template do a similar thing again
                    // i.e. {fan !== undefined && (fan.template === "ExampleFanTemplate") && <ExampleFanTemplate fan={fan}/>}
                }
            </div>
        </div>
    );
}
