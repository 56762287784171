import React from 'react';
import {useState } from 'react';
import Notes from './Notes';
import ProjectDetails from './ProjectDetails';
import Logo from './Logo'
import get from 'lodash/get';

function makeOpts(list){
    if(!Array.isArray(list)){
        return;
    }
    return list.map(e => {
        return(<option key={e} value={e}>{e}</option>);
    });
}

function makeLinks(obj){
    return Object.keys(obj).map(e => {
        return <a href={obj[e]}>{e}</a>
    });
}

const makeTable = (head, list) => {
    let out = [];

    if(Array.isArray(list)){
        list.forEach((e)=>{
            out.push(<tr><td>{e}</td></tr>);
        });
        return(
            <table>
                <tr><th colSpan="2">{head}: </th></tr>
                <tbody>
                    {out}
                </tbody>
            </table>
        );
    }
}

export default function HaikuTemplate(props){

    let fan = props.fan;

    const [size, setSize] = useState("");
    const [mount, setMount] = useState("");
    const [ET, setET] = useState("");
    const [finish, setFinish] = useState("");

    const test = get(fan, `mounts[${size}]`, []);
    return (
        <div>
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
                <div>
                <ProjectDetails />
                <table>
                    <thead><th colSpan="2">Fan Details: </th></thead>
                    <tbody>
                        <tr><td>Quantity: </td><td><input type="number" min="1" required></input></td></tr>
                        {fan.sizes && <tr><td>Size (in): </td><td><select required onChange={e => {setSize(e.target.value)}}><option></option>{makeOpts(fan.sizes)}</select></td></tr>}                        
                        {size !== '' && fan.mounts && get(fan, `mounts[${size}]`) && <tr><td>Mount Type: </td><td><select required onChange={e => {setMount(e.target.value); setET("")}}><option></option>{makeOpts(test)}</select></td></tr>}
                        {size && mount && !get(fan, `variableET[${size + mount}]`) && fan.ET[mount] && <tr><td>Extension Tube (in): </td><td><select required onChange={e => {setET(e.target.value)}}><option></option>{makeOpts(fan["ET"][mount])}</select></td></tr>}
                        {size && mount && fan.variableET && fan.variableET[size + mount] &&  <tr><td>Extension Tube (in): </td><td><select required onChange={e => {setET(e.target.value)}}><option></option>{makeOpts(fan.variableET[size+mount])}</select></td></tr>}
                        {fan.rpm && <tr><td>Max RPM: </td><td>{fan.rpm[size + " " + mount]} RPM</td></tr>}
                        {fan.maxAirflow && <tr><td>Max Airflow: </td><td>{fan.maxAirflow[size + mount + fan.material[finish]]} CFM</td></tr>}
                        <tr><td>Standard Weight: </td><td>{fan.weights[size + mount + (fan.material[finish] ? fan.material[finish] : "")]} lbs</td></tr>
                        {!fan.maxWatts && fan.fla && <tr><td>Max Wattage: </td><td>{fan.fla[size]} W</td></tr>}
                        {fan.maxWatts && <tr><td>Max Wattage: </td><td>{fan.maxWatts[size + mount + fan.material[finish]]} W</td></tr>}
                        <tr><td>Voltage/Phase: </td><td>{fan.volts}</td></tr>
                    </tbody>
                </table>
                {(fan.airfoilFinish || fan.hardwareFinish || fan.finish) &&
                    <table>
                        <thead><th colSpan="2">Color Options:</th></thead>
                        <tbody>
                            {fan.airfoilFinish && <tr><td>Airfoil Finish: </td><td><select required onChange={e => {setFinish(e.target.value)}}><option></option>{makeOpts(fan.airfoilFinish)}</select></td></tr>}
                            {fan.finish && <tr><td>Motor, Hub & Airfoils: </td><td><select required onChange={e => {setFinish(e.target.value)}}><option></option>{makeOpts(fan.finish)}</select></td></tr>}
                            {fan.hardwareFinish && <tr><td>Hardware Finish: </td><td><select required ><option></option>{Array.isArray(fan.hardwareFinish) ?  makeOpts(fan.hardwareFinish) : makeOpts(fan.hardwareFinish[finish])}</select></td></tr>}
                        </tbody>
                    </table>
                }
                <table><thead><th>Additional Options: </th></thead>
                        <tbody>
                            <tr><td><select><option></option>{mount === "Universal" ? makeOpts(fan.additions) : makeOpts(fan.additions.filter(item => item !== "Haiku LED Uplight"))}</select></td></tr>
                            <tr><td><select><option></option>{makeOpts(fan.additions)}</select></td></tr>
                            <tr><td><select><option></option>{makeOpts(fan.additions)}</select></td></tr>
                        </tbody>
                </table>
                <table>
                    <thead><th colSpan="2">Critical Dimensions: </th></thead>
                    <tbody>
                        {fan.hb && <tr><td>Mount to Hub: </td><td>{fan.hb[mount + " " + ET]}"</td></tr>}
                        <tr><td>Fan Diameter: </td><td>{size}"</td></tr>
                        <tr><td>Mount Diameter: </td><td>{fan.mountDiameter[size + " " + mount]}"</td></tr>
                        {fan.showDownrod && <tr><td>Downrod: </td><td>{ET}"</td></tr>}
                    </tbody>
                </table>
                {fan.warranty && 
                        <table>
                            <thead>
                                <tr><th colSpan="3">Warranty Information </th></tr>
                                <tr><th>Item</th><th>Residential</th><th>Non-Residential</th></tr>
                            </thead>                            
                            <tbody>
                                <tr><td>Indoor Fans</td><td>{fan.warranty.residential.indoor}</td><td>{fan.warranty.nonresidential.indoor}</td></tr>
                                {fan.warranty.residential.outdoor && fan.warranty.nonresidential.outdoor && <tr><td>Outdoor Fans</td><td>{fan.warranty.residential.outdoor}</td><td>{fan.warranty.nonresidential.outdoor}</td></tr>}
                                {fan.warranty.residential.CAS && fan.warranty.nonresidential.CAS &&  <tr><td>CAS</td><td>{fan.warranty.residential.CAS}</td><td>{fan.warranty.nonresidential.CAS}</td></tr>}
                            </tbody>
                        </table>
                    }
                </div>
                <div>
                <div>
                    {fan === undefined && <Logo name={"Generic Name"} />}
                    {fan !== undefined && <Logo name={fan.name} />}
                    {fan.features && makeTable("Features", fan.features)}
                    {fan.senseME && makeTable("SenseME Features", fan.senseME)}
                    {fan.ledModule && <table>
                        <thead><th colSpan="2">Optional LED Lighting Module</th></thead>
                        <tbody>
                            {Object.keys(fan.ledModule).map(e => (<tr><td>{e}</td><td>{fan.ledModule[e]}</td></tr>))}
                        </tbody>
                    </table>}
                    {fan.UVledModule && <table>
                        <thead><th colSpan="2">UV-C LED Module</th></thead>
                        <tbody>
                            {Object.keys(fan.UVledModule).map(e => (<tr><td>{e}</td><td>{fan.UVledModule[e]}</td></tr>))}
                        </tbody>
                    </table>}
                    {fan.integratedLight && 
                        <table><thead><th colSpan="2">Integrated LED Lighting Module</th></thead>
                        <tbody>
                            {Object.keys(fan.integratedLight).map(e => (<tr><td>{e}</td><td>{fan.integratedLight[e]}</td></tr>))}
                        </tbody></table>
                    }
                </div>
                <Notes />
                    {fan.links && makeLinks(fan.links)}
                </div>
            </div>
            <img style={{display:'block', marginLeft:'auto', marginRight:'auto', width: '50%', maxHeight:150, maxWidth:600}} alt={`${fan.name} dwg`} src={`${window.location.origin}/technicalDrawings/${fan.name}.png`} />
        </div>
    );
}