import React, { Fragment } from "react";
import { HashLink } from "react-router-hash-link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

function DropdownC1S3() {
  return (
    <Fragment>
      <ListItem button>
        <HashLink
          scroll={el =>
            el.scrollIntoView({ behavior: "smooth", block: "start" })
          }
          to="/chapter-1#1-3"
        >
          <ListItemText primary="1.3 How to Use the Guide" className="white" />
        </HashLink>
      </ListItem>
    </Fragment>
  );
}

export default DropdownC1S3;
