import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const StyledH1 = styled.h1 `
    color: #242424;
    line-height: 1;
    text-transform: uppercase;
    font-size:3.5em;
`

function H1(props)  {
    return (
        <StyledH1>{props.name}</StyledH1>
    );
}

H1.propTypes = {
    name: PropTypes.string
  };

export default H1;