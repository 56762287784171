import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C3S6() {
  return (
    <div className="chapter-container">
      <Anchor id="3-6-1" />
      <H2 name="3.6 Heating and Cooling Load Calculations" />
      <p>
        As described in Chapter 2, incorporating ceiling fans can have a
        significant impact on overall building design. Some ways in which
        ceiling fans can impact heating and cooling loads in a building include:
        raising the temperature setpoint in cooling, affecting conductive heat
        transfer through the envelope, and reducing the required outdoor airflow
        rate. The following example illustrates how load calculations can vary
        between a traditional HVAC system without ceiling fans and one with fans
        in a real world application.{" "}
      </p>
      <p>An example of this concept is featured in the diagram below.</p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6a - Diagram illustrating impact of fans on room temperature and stratification." />
      </Container>

      <H4 name="3.6.1 Atrium Example" />
      <p>
        A new university building features a three-story atrium in the lobby. It
        is 1,130 ft2 (105 m2), 36 ft. (11 m) tall, and has one exterior wall,
        which is entirely glass. See Figure 3.6.1a below for floor plans. The
        atrium is to be served by a packaged air handler with direct expansion
        cooling and gas-fired heating.{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1a.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6.1a Atrium Floor Plan - Level 1" />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1b.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6.1b - Atrium Floor Plan - Level 2" />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1c.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6.1c - Atrium Floor Plan - Level 3" />
      </Container>

      <p>
        Space characteristics were entered into a load calculation software,
        including all envelope and internal heat gain values. These values were
        entered identically into both the model with the fan and the one
        without.{" "}
      </p>
      <p>
        For the system with no fan, the space temperature was set to 75°F (24°C)
        in cooling. In heating, the temperature was also input as 75°F (24°C) to
        account for stratification. In a heated space that is not sufficiently
        mixed, warm air will rise to the top of the room. The thermostat is at a
        lower height and does not sense the warmer air toward the ceiling. As a
        result, the thermostat will continue to call for heat, even when the
        mean temperature in the room is above the set point. To simulate this
        effect, a 75°F (24°C) setpoint was entered into the model, assuming that
        the actual room setpoint would be closer to 70°F (21°C) and with
        stratification the mean temperature would be approximately 75°F (24°C).
        Outdoor airflow rate was calculated using ASHRAE Standard 62.1
        requirements.{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1d.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6.1d - SpecLab CFD analysis. Airspeed at a horizontal plane 43” above the floor." />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1e.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6.1e - SpecLab CFD analysis. Airspeed at a horizontal plane 67” above the floor." />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1f.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6.1f - SpecLab CFD analysis. Airspeed using a vertical cut plane through the center of the room." />
      </Container>

      <p>
        From the area and people based rates, a ventilation rate of 323 CFM (152
        L/s) was calculated. In a traditional HVAC system, air is typically
        supplied from and returned via the ceiling, resulting in a zone air
        distribution effectiveness of 0.8 in heating and 1.0 in cooling. The
        lower effectiveness value is selected to account for the worst case
        distribution, and the required outdoor air is divided by 0.8, resulting
        in an increased rate of 404 CFM (191 L/s).{" "}
      </p>
      <p>
        For the system with a fan, the space temperature in cooling was
        increased to 80°F (27°C). This set point was determined by selecting a
        fan and running a CFD analysis for the proposed layout in SpecLab as
        illustrated in Figure 3.6.1d through Figure 3.6.1f. The minimum air
        speed observed in the SpecLab model at 43” above the floor was 100 fpm
        (0.51 m/s). By plugging this value into the CBE Thermal Comfort Tool,
        the cooling effect allowed for a thermostat offset of 5°F (2.8°C) and
        the baseline setpoint of 75°F (24°C) was increased to 80°F (26.7°C). In
        heating, the room setpoint was 70°F (21°C) since the fan will destratify
        the space. The addition of the fan also provides better air-mixing in
        the space. As a result, a zone air distribution effectiveness of 1.0 was
        used to calculate the minimum outdoor airflow. In this scenario, a lower
        outdoor airflow rate of 323 CFM (152 L/s) was used.{" "}
      </p>
      <p>
        A summary of all input parameters may be found in Figure 3.6.1g below.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
      <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1g.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6.1g - Summary of Load Calculation Inputs" />
      </Container>

      <p>
        Cooling and heating loads were calculated using the Radiant Time Series
        (RTS)/Heat Balance and U-factor*Area*Temperature Difference (UATD)
        methodologies, respectively. Results from both load calculations are
        summarized in Figure 3.6.1h below.{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
      <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-6-1h.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.6.1h - Summary of Load Calculation Results" />
      </Container>

      <p>
        As can be seen in the table, both the heating and cooling loads were
        lower for the design with a fan. Reductions in required capacity are due
        largely to the changes in temperature setpoint allowed by providing
        elevated air speed in cooling and destratification in heating.
        Additionally, by providing a well-mixed space and selecting temperature
        setpoints slightly closer to outdoor temperature, conductive heat
        transfer through the building envelope is reduced.{" "}
      </p>
      <p>
        The total required cooling capacity was 133.3 MBh (39 kW) for the design
        without a fan and 123.0 MBh (36 kW) for the design that utilized a fan.
        This project is to be served by a packaged rooftop unit, which is often
        selected by required cooling tonnage. Without a fan, the system requires
        11.1 tons (39 kW) of cooling. With a fan, 10.3 tons (36.2 kW) are
        required. While this may not be a large difference, packaged RTUs are
        typically provided in integral increments and actual cooling capacity
        delivered is lower than nominal tonnage. For example, an 11 ton (38.6
        kW) RTU may only provide closer to 10.5 tons (36.9 kW) of cooling. This
        would be sufficient for the case with a circulator fan, but for the
        design without a fan, a larger nominal unit would need to be selected.
        Actual RTU sizes depend on unit manufacturer and model; however,
        whenever a smaller unit may be selected, it typically provides multiple
        benefits including lower first cost, smaller footprint, and lighter
        weight.{" "}
      </p>

      <p>
        Additionally, reducing heating and cooling loads also reduced required
        supply airflow of the system. This decreases first costs by allowing
        ductwork sizes to be reduced. These savings are in addition to savings
        associated with reducing the extent of supply ductwork, as described in
        Section 3.5.{" "}
      </p>
    </div>
  );
}

export default C3S6;
