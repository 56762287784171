import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";

function C2S11() {
  return (
    <div className="chapter-container">
      <Anchor id="2-11-1" />
      <H2 name="2.11 Financial Justification" />
      <H4 name="2.11.1 Reduced Ductwork Costs" />
      <p>
        To effectively distribute conditioned air throughout a space, a standard
        HVAC system uses various forms of air distribution terminals to control
        air movement within a conditioned space. Due to the large differential
        in temperature between the supply air and the room air, it is often
        undesirable to direct supply air directly toward occupants. Instead,
        supply air is “diffused” at the ceiling or “thrown” directly toward
        windows, walls, and other objects where thermal loads are generated.
        This method requires many supply air terminals to evenly distribute
        supply air and eliminate air temperature gradients. The large number of
        supply grilles used with this method requires an extensive supply air
        ductwork system to direct supply air from a central air handler to
        supply air grilles in the space.{" "}
      </p>
      <p>
        With a standard HVAC system, typical air change rates range from 4-6 Air
        Changes per Hour (ACH). Ceiling fans circulate a much larger volume of
        air in the same period of time, increasing the circulation of air within
        the room to 20-40 Air Turnovers per Hour (ATH). ACH represents the
        number of times the entire room air volume is exchanged per hour by a
        typical HVAC system. The air circulated by an HVAC system is filtered,
        mixed with outdoor air, conditioned, and returned to the room. ATH
        represents the number of times the entire room air volume is
        recirculated within a room per hour by a ceiling fan. Although 1 ATH is
        equal to 1 ACH in volume, the condition of the air in an ATH has not
        been affected.{" "}
      </p>
      <p>
        The high ATH provided by ceiling fans is highly effective at mixing and
        distributing the air within a room. When using ceiling fans however, far
        fewer supply air grilles are required to uniformly distribute the air
        within the room. To achieve this, the distribution system uses fewer
        supply air terminals to inject conditioned air into the room at much
        wider injection points than a typical HVAC system and the ceiling fan
        distributes the air through the room. The level of mixing and air
        temperature uniformity achieved by this approach is nearly impossible to
        achieve with standard HVAC supply air distribution systems primarily due
        to relatively poor mixing and low ACH.{" "}
      </p>
      <p>
        By reducing the number of supply air terminals, the need for supply air
        ductwork is reduced. The reduction of ductwork saves costs associated
        with the construction and installation of ductwork. Ductwork
        construction includes not only the sheet metal used to construct the
        ductwork but also internal duct liner or external ductwork insulation,
        ductwork sealing, ductwork hangers and supports, supply air grille
        installation, and balancing dampers. The quantity of all of these items
        decreases proportionally with the reduction of the ductwork sheet metal.{" "}
      </p>
      <p>
        In a typical supply air distribution system each supply air terminal not
        only includes a branch duct but also a balancing damper. Balancing
        dampers are required to direct supply air to all areas of the room. To
        balance a ductwork system, the balancing contractor must iteratively
        adjust each balancing damper until all branches are within the allowable
        range of the flow requested. Where less is air is required from a
        branch, the balancing damper is closed and where more air is required
        the damper is opened. Therefore by reducing the number of supply air
        terminals, the test and balance effort is significantly reduced. Thus,
        the addition of a ceiling fan can significantly lower labor and material
        cost of a typical HVAC system. Refer to{" "}
        <a href="/chapter-3#3-5-1">Section 3.5</a> for an example duct scope
        reduction.{" "}
      </p>

      <Anchor id="2-11-2" />
      <H4 name="2.11.2 Reduced System Capacity" />
      <p>
        As discussed in the energy savings section, the addition of a ceiling
        fan can reduce heating and cooling energy consumption as well as HVAC
        fan energy consumption. This reduced energy consumption is associated
        with lower heating and cooling loads. Reducing the peak loads has the
        potential to reduce the size of the HVAC equipment required to condition
        the building. This includes the heating coil, cooling coil, and the
        supply fan.This savings also extends to the ductwork. Reducing the
        heating and cooling loads reduces the supply airflow required which
        allows downsizing of the ductwork.{" "}
      </p>
      <p>
        Ductwork first cost savings is not only associated with smaller
        ductwork, but is also associated with elimination of ductwork that would
        otherwise be required in a typical HVAC system. As noted previously, the
        use of ceiling fans improves air distribution without the need for an
        extensive ductwork distribution system.{" "}
      </p>

      <Anchor id="2-11-3" />
      <H4 name="2.11.3 Resiliency" />
      <p>
        The goal of a resilient design is to improve the safety of occupants
        during periods of crisis. In hot climates, elevated air speed provides a
        very low power option to reduce heat stress. When combined with
        renewable energy, thermal mass, and passive cooling, a building with
        elevated air speed can maintain manageable thermal condition during an
        extended power outage.{" "}
      </p>
    </div>
  );
}

export default C2S11;
