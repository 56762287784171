import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";

function C2S10() {
  return (
    <div className="chapter-container">
      <Anchor id="2-10-1" />
      <H2 name="2.10 Controls" />
      <H4 name="2.10.1 General" />
      <p>
        HVLS fans have a wide array of control options. Fan control can be as
        simple as an on/off switch to full automation through integration with
        an advanced building automation system. The level of control necessary
        for a given project is dependent upon the space conditions required and
        the budget allowed for controls.
      </p>

      <Anchor id="2-10-2" />
      <H4 name="2.10.2 Standalone" />
      <p>
        Standalone controls are the most common solution for fan control. They
        are generally simple and economical but can be as sophisticated as
        necessary for the application. Standalone controls are those that
        control the ceiling fan only with no interaction or communication with
        any other systems. The following is a list of common control options for
        HVLS fans.{" "}
      </p>
      <div className="chapter-content">
        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.10.2.1 Manual Control
        </h5>
        <p>
          The most common and simple form of fan control is an on/off wall
          switch that provides manual control of the fan with no user interface
          for speed control. This type of control may be applicable in spaces
          where the fan is required to operate continuously at a fixed speed. An
          example might be a distribution center in a predominantly cooling
          climate that operates 24/7 and requires constant elevated air speed to
          maintain occupant comfort. In this scenario, there is no need to turn
          the fan off on a regular basis and there’s no need to reduce the speed
          of the fan since there is no heating.{" "}
        </p>
        <p>
          If there is a desire for the users to have control over the fan speed
          as a means to adjust the thermal comfort in the space, or as required
          for a process, it may be necessary to add adjustment for speed
          control. This could be a rotary dial, a digital display, or buttons
          with designated preset speeds. A rotary dial is robust and simple but
          does not provide precise control. A digital display with incremental
          speed adjustment provides precise control but may not be as robust or
          easy to use. Buttons with assigned fan speeds could be used to
          simplify operation. For example, a fan could be provided with
          “heating” and “cooling” buttons where the cooling button increases the
          fan speed to provide perceptible air movement while the heating button
          would decrease the fan speed to destratify warm air at low air speeds
          imperceptible to the occupants. Alternatively, if set fan speeds are
          required for a process application, buttons could be assigned to each
          process that requires a specific air speed.{" "}
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.10.2.2 Thermostat Control
        </h5>
        <p>
          If air speed is required to vary based on temperature, and little to
          no human interaction is desired, it may be advantageous to provide the
          fan with a thermostatic control. This type of control could be used in
          a comfort cooling application or a process cooling application. In a
          comfort cooling application, the thermostat can be used to increase
          fan speed in response to elevated air temperature. For example:
        </p>
        <blockquote>
          When the space temperature is 70°F (21°C) or below the fan maintains
          minimum speed to provide destratification. When the space temperature
          is 90°F (32°C) or above, the fan maintains maximum speed to provide
          maximum air movement. Between 70°F (21°C) and 90°F (32°C) the fan
          speed is adjusted linearly between minimum and maximum speed directly
          proportional to the space temperature.
        </blockquote>
        <p>
          Conversely, if there were a process application where it is important
          to attempt to cool a product when the space temperature is low and
          reduce heat transfer as the space warms, the inverse of the logic
          above could be applied:
        </p>
        <blockquote>
          When the space temperature is 60°F (16°C) or below the fan maintains
          maximum speed to maximize heat transfer. When the space temperature is
          80°F (27°C) or above, the fan is stopped to minimize heat transfer.
          Between 60°F (16°C) and 80°F (27°C) the fan speed is adjusted linearly
          between maximum and minimum speed directly proportional to the space
          temperature.
        </blockquote>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.10.2.3 Optional Control Accessories
        </h5>
        <p>
          With either of the above control methods, it may be necessary to
          control when the fan is permitted to operate. If the hours of
          operation repeat according to a schedule, a time clock schedule can be
          added to the controls. Like a residential programmable thermostat, a
          time clock schedule will set the time of day and days the fan is
          operational. Within those operational hours, the fan will run
          according to the standard sequence of operation. All other times, the
          fan would remain off. Alternatively, there could be more than one
          sequence of operation that implements each sequence of operation at
          the time it applies.
        </p>
        <p>
          If the ceiling fan is used for occupant thermal comfort and the fan is
          not required during unoccupied times, occupancy sensors can be used to
          change the sequence of operation. For example, comfort cooling is
          required only during occupied periods to reduce energy consumption.
          However, heating destratification saves energy regardless of
          occupancy. So it would be advantageous to stop the fan when the space
          is unoccupied and warm, but run the fan when the space is cool,
          regardless of occupancy.
        </p>
      </div>

      <Anchor id="2-10-3" />
      <H4 name="2.10.3 Integration with BAS" />
      <p>
        Where more sophisticated control sequences or interaction with other
        building systems is required, HVLS fans can be integrated with a
        Building Automation System (BAS) where one exists. Building Automation
        Systems are common in medium to large commercial buildings that use
        central heating and cooling equipment in lieu of unitary equipment. A
        BAS provides two-way communication between building systems. Primarily,
        it is used for HVAC systems but can be integrated with other systems
        such as lighting systems, computer equipment, or industrial equipment.
        This integration enables the HVAC system to “listen” to these other
        systems and respond with a programmed reaction. Conversely, the other
        systems have the ability to “listen” to the HVAC system and respond.
      </p>
      <p>
        Because a BAS can incorporate custom programming, the logic achieved by
        the integration is unlimited. However, common reasons to integrate an
        HVLS fan with the HVAC system are as follows:
      </p>
      <ul>
        <li>
          The operating/occupancy schedule in the BAS can be used to control the
          HVLS fan and run it only when appropriate.
        </li>
        <li>
          The BAS can be programmed with logic to adjust the HVLS fan speed in
          response to other HVAC system parameters.
        </li>
        <li>
          The BAS can adjust the HVAC system operation to maintain occupant
          thermal comfort according to ASHRAE Standard 55 by adjusting the space
          temperature dry bulb setpoint in response to the elevated air speed
          generated by the HVLS fan.
        </li>
        <li>
          HVLS fan alarms can be transferred to the BAS to notify maintenance
          personnel.
        </li>
      </ul>
      <p>
        To enable integration with the Building Automation System, BAF fans that
        make use of a Variable Frequency Drive (VFD) and an induction motor can
        be equipped with a BAS interface that allows the VFD to communicate with
        the BAS using BACnet, an industry standard protocol. BACnet is a
        manufacturer agnostic, open source, “plug and play” protocol that
        enables two-way communication between building systems. Because building
        automation systems can use a myriad of inputs and can be programmed with
        completely custom logic, the possibilities for interaction between an
        HVLS fan and other systems is extremely flexible.
      </p>
      <div className="chapter-content">
        <h4 className="mb0 mt40 grey center">
          Example Integrated HVLS Control Sequence
        </h4>
        <h4 className="mb20 mt0 grey center">
          Cooling, Heating, Occupied, Unoccupied
        </h4>
        <ol>
          <li>
            The building automation system shall monitor the following points:
            <ol type="a">
              <li>
                Space Temperature Sensor – Thermostat for space or dry bulb
                temperature sensor mounted near thermostat
              </li>
              <li>Big Ass Fan operating speed</li>
              <li>Big Ass Fan On/Off</li>
              <li>Air handling unit operating mode</li>
            </ol>
          </li>
          <li>
            The air handling unit shall be capable of operating in the following
            modes:
            <ol type="a">
              <li>Occupied Cooling</li>
              <li>Occupied Heating</li>
              <li>Unoccupied Cooling</li>
              <li>Unoccupied Heating</li>
            </ol>
          </li>
          <li>
            In each operating mode, the automation system shall control the
            system as indicated in the following descriptions:
            <ol type="a">
              <li>
                Occupied heating
                <ol type="i">
                  <li>
                    When the building enters occupied heating mode, the
                    thermostat shall be set to [ 70°F (21°C)].
                  </li>
                  <li>The Big Ass Fans shall be controlled on.</li>
                  <li>
                    The fan speed shall be adjusted to the winter operating
                    speed, [ 20% ] of maximum rpm.
                  </li>
                  <li>
                    If the space temperature falls below [ 68°F (20°C)], the
                    mechanical heating shall be activated to maintain the space
                    temperature at [ 70°F (21°C)].
                  </li>
                </ol>
              </li>
              <li>
                Unoccupied heating
                <ol type="i">
                  <li>
                    When the building enters unoccupied heating mode, the
                    thermostat shall be set to [ 50°F (10°C)].
                  </li>
                  <li>The Big Ass Fans shall be controlled on.</li>
                  <li>
                    The fan speed shall be adjusted to the winter operating
                    speed, [ 20% ] of maximum rpm.
                  </li>
                  <li>
                    If the space temperature falls below [ 48°F (9°C)], the
                    mechanical heating shall be activated to maintain the space
                    temperature at [ 50°F (10°C)].
                  </li>
                </ol>
              </li>
              <li>
                Occupied cooling
                <ol type="i">
                  <li>
                    When the building enters occupied cooling mode, the
                    thermostat shall be set to [ 79°F (26°C)].
                  </li>
                  <li>The Big Ass Fans shall be controlled on.</li>
                  <li>
                    The high volume low speed fans shall be controlled to the
                    summer minimum speed [ 30% ] of maximum rpm.
                  </li>
                  <li>
                    If the space temperature exceeds [ 75°F (24°C)], the fan
                    speed shall be increased by [ 15% ] for every 1°F (0.6°C) of
                    temperature increase up to a maximum speed of [ 90% ] of
                    maximum rpm.
                  </li>
                  <li>
                    If the space temperature exceeds [ 80°F (27°C)], the
                    mechanical cooling shall be activated to maintain the space
                    temperature at [ 79°F (26°C)].
                  </li>
                </ol>
              </li>
              <li>
                Unoccupied cooling
                <ol type="i">
                  <li>
                    When the building enters unoccupied cooling mode, the
                    thermostat shall be set to [ 90°F (32°C)].
                  </li>
                  <li>
                    The high volume low speed fans shall be controlled off.
                  </li>
                  <li>
                    If the space temperature exceeds [ 92°F (33°C)], the
                    mechanical cooling shall be activated to maintain the space
                    temperature at [ 90°F (32°C)].
                  </li>
                </ol>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
  );
}

export default C2S10;
