import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C3S7() {
  return (
    <div className="chapter-container">
      <Anchor id="3-7-1" />
      <H2 name="3.7 Building Energy Consumption" />
      <p>
        Section 3.6.1 described the effects of adding ceiling fans on heating
        and cooling load calculations for a given space. In addition to the
        first cost and design benefits of reducing required equipment capacity,
        the incorporation of ceiling fans also results in lower energy
        consumption and operating costs. More detail on how fans save energy is
        provided in Section 2.5.4.{" "}
      </p>

      <H4 name="3.7.1 Atrium Example" />
      <p>
        To illustrate these energy savings, an energy model was completed for
        the atrium example in the previous section. Modeling inputs are
        summarized in Figure 3.7.1a. All inputs for the load calculation portion
        of the energy model are the same as in Section 3.6.1 and can be found in
        Figure 3.6.1d.{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
      <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-7-1a.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.7.1a - Summary of Energy Model Inputs" />
      </Container>

      <p>
        Energy modeling inputs, in this case, were identical between both
        scenarios; however, it should be noted that in other cases there could
        be differences. For example, in this atrium space, supply ductwork was
        already fairly minimal due to limited wall and ceiling space. Often, a
        reduction in supply ductwork is seen when ceiling fans are incorporated
        into a design and facilitate air distribution. When supply ductwork is
        decreased, supply fan static pressure is also decreased, resulting in
        lower energy consumption. Additionally, it was discussed in Section
        3.6.1 how including ceiling fans in a design can decrease heating and
        cooling loads and, as a result, HVAC equipment size. In this example,
        the decrease in loads was small enough that it was assumed the RTUs in
        both scenarios would have the same efficiency. It is possible, in a
        different example, the difference in required equipment capacity could
        result in a more efficient unit being selected; as size of RTUs
        increases, efficiency also typically increases.{" "}
      </p>
      <p>
        A full year (8760 hour) energy model simulation was run for the atrium
        example. Modeling results are summarized in Figure 3.7.1b.{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
      <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-7-1b.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.7.1b - Summary of Energy Model Results" />
      </Container>

      <p>
        In the design with a fan, energy consumption is reduced by about 42
        million Btu/yr (12,000 kWh/yr) and annual energy savings totals
        approximately $600. Savings are largely due to the reduction in heating
        and cooling requirements in the space as a result of temperature
        setpoints and reduced envelope losses. When heating and cooling
        requirements are decreased, the RTU may be run less, resulting in energy
        savings. Additionally, even when heating and cooling are required, when
        the circulator fan may be used in lieu of the RTU, less energy is
        consumed by the system. It is more efficient for the ceiling fan to
        provide a 5°F (2.8°C) cooling effect with elevated air speeds than for
        the RTU to run the DX coil and supply fan to provide the same cooling
        effect.{" "}
      </p>
      <p>
        Savings are achieved in the case with the fan due to increased air
        economizer hours. Since the space temperature setpoint is higher in the
        case with a fan, the economizer high limit temperature may also be
        greater, resulting in more hours when economizer may be used in lieu of
        DX cooling. For this weather location, increasing the high-limit shutoff
        setting by 5°F (2.8°C) equates to an additional 410 hours of economizer
        operation.{" "}
      </p>

      <Anchor id="3-7-2" />
      <H4 name="3.7.2 Energy Models for Code Compliance" />
      <p>
        Often energy models are performed not only to compare two design
        options, as in Section 3.7.1, but to demonstrate compliance with a code.
        In this case, the proposed design would be compared to a baseline,
        code-defined building to evaluate performance improvements of the
        proposed design over minimum requirements. One common methodology used
        to demonstrate compliance is ASHRAE Standard 90.1 Appendix G.{" "}
      </p>
      <p>
        Appendix G outlines all necessary requirements for modeling both the
        baseline and proposed buildings and specifies how credit may be taken
        for efficient designs. Table G3.1 Section 4 lists requirements for
        thermostat and equipment schedules. In general, baseline and proposed
        models must use identical schedules, unless one of the listed exceptions
        applies. Exception 1 under Baseline Building Performance states:{" "}
      </p>
      <blockquote>
        Set points and schedules for HVAC systems that automatically provide
        occupant thermal comfort via means other than directly controlling the
        air dry-bulb and wet-bulb temperature may be allowed to differ, provided
        that equivalent levels of occupant thermal comfort are demonstrated via
        the methodology in ASHRAE Standard 55, Section 5.3.3, “Elevated Air
        Speed,” or Standard 55, Appendix B, “Computer Program for Calculation of
        PMV-PPD.”
      </blockquote>
      <p>
        Under this exception, set points and schedules may vary between the
        baseline and proposed models when the proposed model uses a fan to
        provide occupant comfort through elevated air speed. This allows the
        modeler to take credit for the savings discussion that results from
        incorporating a ceiling fan into an HVAC design, as discussed in this
        section.
      </p>
    </div>
  );
}

export default C3S7;
