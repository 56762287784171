import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { NavHashLink as NavLink } from "react-router-hash-link";
import ListItem from "@material-ui/core/ListItem";
import HomeNavItem from "../../UI-Kit/HomeNavItem";

function HomeLink(props) {
  return (
    <Fragment>
      <ListItem button>
        <NavLink onClick={props.onClick} to="/">
          <HomeNavItem name="Home" />
        </NavLink>
      </ListItem>
    </Fragment>
  );
}

HomeLink.propTypes = {
  onClick: PropTypes.func
};
export default HomeLink;
