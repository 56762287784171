import React from "react";
import H1 from "../UI-Kit/H1";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import AirSpeedTable from "./table-2.1.6";
import Caption from "../UI-Kit/Caption";

function C2S1() {
  return (
    <div className="chapter-container main" id="main">
      <Anchor id="2-1-1" />
      <H1 name="Background &amp; Rationale" />
      <H2 name="2.1 Thermal Comfort" />
      <H4 name="2.1.1 Human Body Thermoregulation" />
      <p>
        As discussed in Chapter 1, thermal comfort is an occupant’s level of
        satisfaction with the perceived temperature of their surrounding
        environment. How satisfied an occupant is depends on how much heat is
        lost from an occupant’s body. There are four ways in which the body
        exchanges heat with its surrounding environment: convection,
        evaporation, radiation, and conduction.{" "}
      </p>
      <p>
        Convection occurs when heat is transferred between a solid source and a
        moving fluid. For example, convective heat transfer is at work when heat
        from the skin is transferred to the surrounding air. In still air, this
        method of heat transfer is responsible for about 15% of heat loss from
        the body. When air speed increases, so does the amount of heat loss
        through convection.{" "}
      </p>
      <p>
        Heat transfer from the body also occurs through the evaporation of
        water. As people sweat, moisture on the skin is converted to vapor. This
        phase change requires energy, which is provided by the skin. As energy
        is transferred from the skin to the water, a cooling effect is felt. The
        rate of evaporation is largely determined by the relative humidity in
        the space. Less humid air is able to hold more moisture, allowing more
        evaporation to occur. At rest, the human body experiences about 20% of
        its heat loss through evaporation. During exercise, this number
        increases significantly. Elevated air speeds increase evaporation by
        providing air movement over the skin to move humid air away from the
        body, which is then replaced by drier air that has the capacity to
        absorb more water.{" "}
      </p>
      <p>
        Radiation is the transfer of heat by electromagnetic waves. Radiant
        energy is transferred from an object at a higher temperature to one at a
        lower temperature, without the two objects touching. For example,
        radiant energy from the sun heats the skin making you feel warm. Heat
        from the body can also be lost to surfaces in the surrounding
        environment, resulting in a cooling effect. In an air conditioned space,
        approximately 60% of body heat loss is due to radiation.
      </p>
      <p>
        The last mechanism of heat transfer from the body is conduction.
        Conduction is defined as heat transfer between two objects in contact.
        When someone is holding a cold drink and the drink warms up while their
        hand feels cold, that is caused by conductive heat losses. Conduction is
        typically responsible for less than 5% of the body’s heat loss.
      </p>

      <Anchor id="2-1-2" />
      <H4 name="2.1.2 How Ceiling Fans Help Meet Thermal Comfort Goals" />
      <p>
        Fans increase air speed, which increases heat loss by two of the four
        thermoregulation mechanisms: convection and evaporation. This helps the
        body cool itself and increases thermal comfort for occupants.{" "}
      </p>
      <p>
        Elevated air speed also results in more air movement felt by occupants.
        The Center for the Built Environment (CBE) at the University of
        California Berkeley conducted studies where air movement was varied by
        the use of ceiling fans and occupants were surveyed on their level of
        satisfaction with their environment. Studies found that perceptible air
        movement significantly improved the occupants’ thermal comfort,
        perceived air quality, and satisfaction with environmental factors such
        as humidity.{" "}
      </p>
      <p>
        So air speed is known to increase thermal comfort and occupant
        satisfaction, but how is thermal comfort defined? And how much air
        movement is the right amount? To answer those questions and more,
        various standards have been established- including ASHRAE Standard 55.{" "}
      </p>

      <Anchor id="2-1-3" />
      <H4 name="2.1.3 ASHRAE Standard 55: Thermal Environmental Conditions for Human Occupancy" />
      <p>
        ASHRAE Standard 55 is titled Thermal Environmental Conditions for Human
        Occupancy. The purpose, as stated in the standard, is “to specify the
        combinations of indoor thermal environmental factors and personal
        factors that will produce thermal environmental conditions acceptable to
        a majority of the occupants within the space.” Simply stated, the
        standard identifies factors that affect thermal comfort and describes
        how adjusting each affects occupant satisfaction with an indoor
        environment. It also provides a method for determining optimal values
        for factors such as temperature and humidity to create a comfortable
        environment.{" "}
      </p>
      <p>
        Standard 55 identifies six factors that affect thermal comfort:
        metabolic rate, clothing insulation, air temperature, radiant
        temperature, air speed, and humidity. It then defines what combination
        of air temperature, radiant temperature, air speed, and humidity
        produces acceptable conditions for occupants with a certain metabolic
        rate and clothing level. The combination of these factors that predicts
        an acceptable thermal environment for occupants is defined as a thermal
        comfort zone.{" "}
      </p>
      <p>
        Metabolic rate is the rate of transformation of chemical energy into
        heat and mechanical work during a given activity. Therefore, the
        metabolic rate of occupants changes based on activity level. Table
        5.2.1.2 in Standard 55 provides a list of metabolic rates for typical
        tasks. For example, the typical metabolic rate for typing is 1.1 met.
        The typical rate for cooking varies from 1.6 to 2.0 met. For individuals
        with activities that vary, time-weighted average metabolic rates can be
        used.{" "}
      </p>
      <p>
        Clothing levels of occupants can also vary based on season, space use
        type, and/or time of day. The more clothing an occupant wears, the
        higher the resistance to sensible heat transfer between the occupant and
        the surrounding environment. Tables 5.2.2.2A and 5.2.2.2B provide
        clothing insulation values for various clothing ensembles and individual
        garments. For example, trousers and a short sleeve shirt have a clothing
        insulation value of 0.57 clo. A long-sleeve, thick sweater has a value
        of 0.36 clo. Clothing insulation values are additive, so an individual
        wearing trousers, a short sleeve shirt, and a sweater, would have a clo
        of 0.93. Using these metabolic rate and clothing level tables, the heat
        transfer and thermal comfort characteristics of the occupants in a space
        can be defined.{" "}
      </p>
      <p>
        The next variables to define are the characteristics of the space: air
        temperature, radiant temperature, air speed, and humidity. These values
        are typically set by a combination of the outdoor environment and the
        HVAC systems present in the building. A typical HVAC system directly
        controls the dry bulb temperature in a building. The other three
        factors: relative humidity, radiant temperature, and air speed may not
        be directly controlled by the HVAC system, but will often result from
        maintaining the desired space dry bulb temperature. In a system
        employing ceiling fans, air speed can be directly controlled. The
        additional flexibility this provides in defining a thermal comfort zone
        will be discussed in more detail later in this section.
      </p>
      <p>
        Standard 55 uses two different methods to determine acceptable thermal
        comfort zones: graphical and analytical. The graphical comfort zone
        method, defined by Section 5.3.1 of the standard, provides a graph
        illustrating the comfort zone for a specific range of occupant metabolic
        rates and clothing insulation levels. Space conditions can be plotted on
        the graph to determine if they fall within the comfort zone. The
        graphical method may only be used when occupant characteristics fall
        within certain ranges defined by the Standard. This method does not
        account for the increased thermal comfort provided by elevated air
        speeds. If conditions are outside the given range, the analytical method
        must be used.{" "}
      </p>
      <p>
        The analytical comfort zone method is described in Section 5.3.2 and
        applies to a wider range of occupant characteristics. A variation on the
        analytical method allows users to determine compliance for systems using
        elevated air speeds. This is called the Elevated Air Speed Comfort Zone
        Method and is described in Section 5.3.3. This method is explained in
        further detail below. The analytical method calculates a predicted mean
        vote (PMV) value for a certain combination of thermal comfort factors.
        PMV is an index that predicts the self-reported perceptions of an
        occupant group about its environment. PMV values are on a scale from -3
        to 3 where each number corresponds to a sensation: cold (-3), cool (-2),
        slightly cool (-1), neutral (0), slightly warm (+1), warm (+2), hot
        (+3). A compliant PMV (space conditions are within the thermal comfort
        zone) is between -0.5 and 0.5. The methodology for calculating PMV is
        described in two full pages of computer code in Normative Appendix B of
        the standard. For the busy engineer who doesn’t have time to write a
        program to calculate PMV, there’s the CBE Thermal Comfort Tool.{" "}
      </p>

      <Anchor id="2-1-4" />
      <H4 name="2.1.4 CBE Thermal Comfort Tool" />
      <p>
        The Center for the Built Environment at the University of California at
        Berkeley developed a web-based tool for thermal comfort calculations
        using ASHRAE Standard 55 methodology. The user enters operative
        temperature, air speed, humidity, metabolic rate, and clothing level.
        Metabolic rates and clothing levels are provided for a number of typical
        activities and ensembles. The Thermal Comfort Tool will calculate the
        results, including PMV, and determine compliance with Standard 55. It
        also generates a chart illustrating the thermal comfort zone for those
        conditions and plots the input operating point.{" "}
      </p>
      <p>
        The CBE Thermal Comfort Tool allows a user to take credit for elevated
        air speeds by accounting for average air speed in the calculation. As
        airspeed increases, the range of acceptable operating temperatures
        increases as well. This lets the user pick a higher temperature
        setpoint, while still being compliant with Standard 55. The Thermal
        Comfort Tool also has an input for whether local air speed control is
        provided. Standard 55 limits the maximum average air speed permitted
        when occupants do not have control over the system. The Comfort Tool
        verifies compliance by checking that the air speed is below this maximum
        value if no local control is provided.{" "}
      </p>
      <p>
        For examples of how to use the CBE Thermal Comfort Tool and how thermal
        comfort zones change with elevated air speeds, refer to Chapter 3
        Section 2.{" "}
      </p>

      <Anchor id="2-1-5" />
      <H4 name="2.1.5 Thermal Comfort Calculations with Elevated Air Speeds" />
      <p>
        In Section 5.3.3, Standard 55 provides a means of calculating thermal
        comfort in applications with elevated air speed. The Elevated Air Speed
        Comfort Zone Method uses the Analytical Comfort Zone Method combined
        with the Standard Effective Temperature (SET) method described in
        Normative Appendix D.{" "}
      </p>
      <p>
        SET is the hypothetical temperature of a standardized environment (50%
        RH, still air, mean radiant temperature equal to average air
        temperature) with a standardized occupant (1.0 met and 0.6 clo) where
        the total heat loss from the occupant is equal to the heat loss from an
        actual occupant in the actual environment. Appendix D uses SET to
        account for the cooling effect of air speeds that exceed the ranges
        covered by the Graphical and Analytical methods. In this method, SET is
        first calculated at the design environmental and occupant conditions.
        Then, the average air speed is replaced by that of still air, 20 fpm
        (0.1 m/s), and the air and radiant temperatures are adjusted until the
        resulting SET is equal to the SET calculated in the first step. The
        amount the air and radiant temperatures were adjusted is defined as the
        cooling effect (CE) of the elevated air speed. Those adjusted average
        air and mean radiant temperatures are then used to calculate the PMV
        value, along with an average air speed of 20 fpm (0.1 m/s), the original
        relative humidity, original clo value, and original metabolic rate.{" "}
      </p>
      <p>
        Cooling effect also plays an important role in the calculation of
        Cooling Fan Efficiency (CFE). This metric is defined by ASHRAE Standard
        216 as the ratio of the cooling effect to the input power of the fan.
        CFE provides a standardized way of comparing ceiling fans and how much
        cooling they provide relative to energy consumption. Standard 216
        outlines a framework for testing fans and providing standardized
        performance data to facilitate an even comparison.{" "}
      </p>

      <Anchor id="2-1-6" />
      <H4 name="2.1.6 How To Calculate Air Speed" />
      <p>
        Average air speed is defined by ASHRAE Standard 55 as “the average air
        speed surrounding a representative occupant… with respect to location
        and time.” The average is calculated using three heights (ankle, waist,
        and head level) over a time interval between one and three minutes. Head
        and waist height values depend on whether occupants are seated or
        standing. For seated occupants, heights of 4 in. (0.1 m), 24 in. (0.6
        m), and 43 in. (1.1 m) are used. For standing occupants, heights are 4
        in. (0.1m), 43 in. (1.1 m), and 67 in. (1.7 m). Averaging air speed over
        these three heights provides an idea of how air movement is felt across
        the whole body.{" "}
      </p>
      <p>
        Standard 55 defines elevated air speed as an average air speed greater
        than 40 fpm (0.2 m/s). Still air is defined as an average air speed of
        20 fpm (0.1 m/s). When designing for elevated air speed, a target air
        speed should be determined based on the space use type. Spaces with
        higher activity levels typically benefit from higher average air speeds,
        since occupants have higher cooling needs. Figure 2.1.6a below provides
        reference points for various velocities and some context for how
        different air speeds are perceived by humans.{" "}
      </p>
      <div className="mt40 mb40">
        <AirSpeedTable />
        <Caption text="Figure 2.1.6a - Reference points for various air speeds." />
      </div>
      
      <p>
        Average air speed is typically calculated using computational fluid
        dynamics (CFD). CFD modelers allow a user to input room and air
        characteristics and generate an airflow profile in the space. Big Ass
        Fans (BAF) SpecLab is an example of one such CFD modeler. In SpecLab,
        the BAF team is able to create a 3D representation of the space being
        modeled. The modeler inputs all floors, walls, and ceilings as well as
        space temperature and humidity set points. Floor level obstructions and
        occupant locations may be added to the space if desired. The modeler
        then calculates average air speed and other variables at various points
        throughout the room. These results allow a designer to see uniformity of
        airflow and temperature throughout the space and to determine necessary
        values to perform thermal comfort calculations.{" "}
      </p>
      <p>
        Examples of CFD models generated by SpecLab are included in Chapter 3
        Section 3.{" "}
      </p>
    </div>
  );
}

export default C2S1;
