import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C2S5() {
  return (
    <div className="chapter-container">
      <Anchor id="2-5-1" />
      <H2 name="2.5 Building Energy Efficiency Regulation" />
      <H4 name="2.5.1 Energy Efficiency Benefits" />
      <p>
        Reducing energy consumption is an increasingly important issue in
        today’s world. Due to the depletion of natural resources and the
        negative impact of traditional fossil fuels on the environment, reducing
        energy consumption has become a focus for building owners, environmental
        organizations, and building codes. Buildings consume approximately 40%
        of total energy production in the US. Therefore, the building industry
        can have a significant impact on overall US energy savings. Reducing
        building energy consumption provides environmental benefits and also
        offers energy cost benefits. This means building owners can have a
        positive environmental impact while lowering utility bills.{" "}
      </p>

      <Anchor id="2-5-2" />
      <H4 name="2.5.2 Utility Incentives" />
      <p>
        Some energy efficiency measures can increase the first cost of
        constructing a building. To offset increased first costs, many energy
        efficient building measures are promoted by utility companies through
        rebates and financial incentives. These incentives lower the first cost
        of more efficient systems to bring them into parity with less efficient,
        lower cost systems. Although utility incentives vary by provider, some
        utility providers offer prescriptive incentives for HVLS fans. Where
        prescriptive incentives are absent, utility providers may offer
        performance based incentives that use the calculated energy savings of
        HVLS fans to determine the value of the incentive.
      </p>

      <Anchor id="2-5-3" />
      <H4 name="2.5.3 Energy Conservation Codes" />
      <p>
        Building code enforcement agencies understand the benefits of energy
        conservation are not always enough to adequately promote responsible
        building design. As a result, building energy conservation codes are
        used to establish minimum energy efficiency standards that limit
        building energy consumption. As the need to save energy increases,
        energy conservation codes grow more stringent.
      </p>
      <p>
        One group that regulates the ceiling fan performance is the United
        States Federal Government. In an effort to standardize the efficiency
        measurement and rating method for HVLS fans, Large Diameter Ceiling Fans
        (HVLS Fans) are a federally regulated product. Published performance
        data for HVLS fans must be determined using the federally mandated test
        procedure. Per the Department Of Energy’s (DOE) representation
        requirements at 10 CFR 429.32, all ceiling fan manufacturers are
        required to use the DOE testing methods as the basis of any published
        performance data. These requirements (listed in 10 CFR Appendix U to
        Subpart B of Part 430) specify how fan performance is to be measured and
        how efficiency is to be represented, CFM/W (L/s/W) (weighted average
        estimated volumetric airflow rate per electrical power input). By
        regulating the method of test and reporting of fans, published data can
        be more easily compared with assurance performance has been established
        using consistent methodology. Furthermore, large diameter ceiling fans
        manufactured on or after January 21, 2020 must meet the following
        minimum efficiency, CFM/W = 0.91*D-30, where D is the fan diameter in
        inches (meters). As will be discussed later, CFM/W (L/s/W) is only one
        means of quantifying ceiling fan efficiency and omits other ceiling fan
        performance metrics valuable in selecting ceiling fans.
      </p>
      <p>
        The International Energy Conservation Code (IECC), published by the
        International Code Council (ICC), is one of the most commonly adopted
        energy codes in the US. The IECC is published every three years, with
        each edition being slightly more stringent than the last. The IECC
        specifies minimum energy performance requirements for building
        envelopes, mechanical systems, service water heating, electrical power,
        artificial lighting, existing buildings, and more. As of the writing of
        this guide, the IECC does not directly regulate the efficiency of
        ceiling fans as part of the mandatory or prescriptive requirements.
      </p>
      <p>
        An additional energy code for commercial buildings is ASHRAE Standard
        90.1: Energy Standard for Buildings Except Low-Rise Residential
        Buildings. This Standard is also published every three years, with its
        publication occurring two years prior to each IECC publication (e.g.,
        2018 IECC is preceded by ASHRAE 90.1-2016). Like the IECC, ASHRAE
        Standard 90.1 also regulates building envelopes, mechanical systems,
        service water heating, and electric power and lighting. As with the
        IECC, at the time of the writing of this guide ASHRAE Standard 90.1-2016
        does not regulate the efficiency of ceiling fans as part of the
        mandatory or prescriptive requirements. Addendum cq to 90.1-2016 adds
        requirements for ceiling fans greater than 84.5 in. (2.1 m) in diameter
        to the 2019 version of the standard. The blade span (blade tip diameter)
        and the rated airflow and power consumption at maximum speed must be
        provided. The data must be determined using either 10 CFR 430 Appendix U
        (US projects) or AMCA 230-15 (non-US projects). The data is required to
        be either determined by an independent laboratory, included in a
        database published by US DOE, or from a certified rating program (such
        as AMCA CRP).
      </p>
      <p>
        Although there are no mandatory or prescriptive minimum energy
        efficiency requirements in the IECC or Standard 90.1, ceiling fans can
        be used with the Performance Rating Method (PRM) option of Standard
        90.1-2016 or Section C407 Total Building Performance of the 2018 IECC to
        demonstrate projected energy savings associated with the use of ceiling
        fans. These performance paths provide rulesets for calculating annual
        energy consumption of buildings using energy modeling software. The
        results establish the relative performance of a proposed building to
        that of a theoretical baseline building in terms of annual energy cost.
        When using the latest rules, the thermostat setpoints used in the
        baseline and proposed buildings must match. However, specific exceptions
        allow different setpoints when using elevated air speed to provide equal
        occupant thermal comfort. The use of higher cooling thermostat setpoints
        decreases the conductive heat transfer through the building envelope and
        the cooling required to treat the outdoor air.
      </p>
      <p>
        ASHRAE also publishes Standard 189.1: Standard for the Design of
        High-Performance Green Buildings. The purpose of Standard 189.1 is “to
        provide minimum requirements for the siting, design, construction, and
        plans for operation of high-performance green buildings.” While Standard
        189.1 references Standard 90.1, the breadth of 189.1 is much wider and
        the energy efficiency requirements more stringent. Standard 189.1 is
        also published as the International Green Conservation Code (IgCC) by
        the ICC. These standards are not widely adopted; however, a local
        jurisdiction may choose to adopt the IgCC/Standard 189.1, in which case
        compliance must be demonstrated. Similar to the IECC and Standard 90.1,
        these codes offer performance paths for demonstrating energy
        conservation compliance that can be used to take advantage of the energy
        savings offered by ceiling fans.
      </p>
      <p>
        In addition to codes and standards that dictate energy requirements,
        building owners may set project-specific requirements for energy
        efficiency. These can take the form of specific owner goals or
        participation in a green building rating system, such as LEED or Green
        Globes. Green building rating systems typically award points for
        improving the building’s energy conservation. This creates an incentive
        to design energy efficient building systems. In addition, these rating
        systems also award points for buildings that provide adequate thermal
        comfort and indoor air quality to occupants. As discussed previously,
        ceiling fans enhance these aspects in buildings and earn points that
        contribute to achieving green building certifications.
      </p>

      <Anchor id="2-5-4" />
      <H4 name="2.5.4 How Ceiling Fans Reduce Energy Consumption" />
      <p>
        Ceiling fans reduce energy consumption in buildings by reducing the
        heating and cooling burden on the HVAC systems. As noted in the
        introduction, although ceiling fans themselves consume energy, HVAC
        energy savings are significantly greater than the energy cost of the
        ceiling fans. HVAC energy savings is attributed to reduced conductive
        heat transfer through the building envelope, ventilation effectiveness
        improvement, and HVAC fan energy reduction.
      </p>
      <div className="chapter-content">
        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.5.4.1 Higher Thermostat Cooling Setpoint
        </h5>
        <p>
          As discussed previously, when air speed is increased at the occupant
          level, the temperature experienced by the occupant feels cooler. As a
          result, when using a ceiling fan to circulate air in a room, the
          thermostat setpoint can be raised and provide the same cooling
          sensation as a room with a lower temperature setpoint and no air
          movement. Increasing the thermostat setpoint by 2°F to 3°F (1°C to
          2°C) may not seem significant until several factors are considered.
          When the cooling temperature setpoint inside a room is increased, the
          conductive losses through the walls to the outdoors is reduced and the
          energy required to cool the outdoor ventilation air to the indoor air
          temperature is also reduced. A 2°F to 3°F (1°C to 2°C) increase in
          space temperature reduces each of these loads by approximately 10%.
        </p>
        <p>
          In addition, when the thermostat cooling setpoint is increased, the
          supply air temperature from the HVAC system can be increased. This not
          only reduces the energy required to cool the outdoor air, but also
          increases the number of hours the outdoor air can be used to help cool
          the building (air economizer). Air economizer is required by the
          energy code in most climate zones, but ceiling fans can increase the
          savings seen by air economizer as a result of the increased space
          thermostat setpoint increase. The additional separation between the
          cooling setpoint and the heating thermostat setpoint can lead to
          significant energy savings during colder months. Careful consideration
          must be given to the system’s ability to dehumidify when increasing
          the supply air temperature. Using a higher supply air temperature
          during design conditions must consider the indoor space relative
          humidity. If a high internal latent load exists or the building is in
          a humid climate, increasing the supply air setpoint will decrease the
          amount of dehumidification provided by the cooling coil, leading to
          high humidity concerns.
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.5.4.2 Lower Mean Heating UADT
        </h5>
        <p>
          In heating applications, destratification can reduce heating
          requirements by lowering the mean temperature in the room. When air
          stratifies in a room during heating, hot air rises to the ceiling and
          slowly falls as it cools. This generates a temperature gradient from
          the floor and the temperature rises with elevation. The temperature at
          the thermostat, typically about 4 ft. (1.2 m) above the floor, is the
          desired space temperature and all air temperatures above the
          thermostat will be greater, increasing the mean air temperature in the
          room. As the mean air temperature in the room increases, the
          conductive heat transfer through the building envelope increases
          proportionally. With a ceiling fan, the temperature gradient can
          nearly be eliminated and maintain the mean air temperature much closer
          to the thermostat setpoint than without a ceiling fan.
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.5.4.3 OA Distribution Effectiveness
        </h5>
        <p>
          Outdoor ventilation air loads are further reduced when using a ceiling
          fan as the distribution effectiveness is frequently higher than
          without. Both ASHRAE Standard 62.1 and the International Mechanical
          Code use distribution effectiveness in calculating the total required
          volume of outdoor air an HVAC system must provide. The distribution
          effectiveness is associated with the method of distributing
          ventilation air to the space. Due to the elevated mixing associated
          with ceiling fans, the distribution effectiveness for a typical HVAC
          system using a ceiling fan is often 20% higher than a typical HVAC
          system without a ceiling fan that supplies and returns air at the
          ceiling. Increasing the distribution effectiveness by 20% reduces the
          required outdoor airflow rate by 20%, further reducing the energy
          consumed by conditioning outdoor air.
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.5.4.4 HVAC Supply Fan Energy Reduction
        </h5>
        <p>
          Energy savings provided by ceiling fans is not only achieved through
          heating and cooling savings but also from reduced HVAC system fan
          energy. As discussed previously in the air distribution section, less
          HVAC distribution ductwork is required to effectively distribute air
          when using a ceiling fan. Reducing the ductwork system reduces HVAC
          system fan energy by lowering the External Static Pressure (ESP), the
          resistance to airflow experienced by the HVAC fan. This can have a
          significant effect on building energy consumption as HVAC fans run
          constantly during all occupied hours and are often one of the primary
          energy consumers in a building. Reducing the fan power proportionally
          reduces the fan energy consumption. For example, reducing the ESP of a
          7,500 CFM (3540 L/s) HVAC system from 2.5 in. w.c. (622 Pa) by 20%
          reduces the Brake Horsepower (BHP) from 5.46 BHP (4.07 kW) to 4.37 BHP
          (3.26 kW). Running an average of 14 hours per day, 365 days per year,
          this reduction in fan power saves approximately $460 per year at
          $0.11/kWh.
        </p>
        <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-5-4-4-Table.png"
          width="100%"
          alt=""
        />
      </Container>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.5.4.5 Replacement of Directional Fans
        </h5>
        <p>
          Often, occupants or building owners add small directional fans to
          provide additional cooling in a space. While these do provide elevated
          air speed and cooling to a specifically targeted area, multiple fans
          will be needed to serve a single area. Multiple directional fans
          consume more energy than a single ceiling fan. Directional fans
          generally consume more energy than HVLS ceiling fans due to multiple
          factors:
        </p>
        <ul>
          <li>
            Directional fans typically use &quot;low cost&quot; induction motors
            which will have less material (copper and lamination steel). These
            motors are significantly less efficient than the high efficiency
            induction motors and electronically commutated motors (ECM) used in
            BAF products.
          </li>
          <li>
            Directional fans typically use small, imprecise, and inefficient
            plastic or sheet metal impellers to move air. BAF uses airfoil fan
            blades engineered for high mechanical efficiency.
          </li>
          <li>
            Directional fans are typically single speed or offer a limited
            number of speeds. If the directional fan model has a 2 or 3 speed
            option, the “lower” speeds will likely be 80% of maximum speed or
            higher due to limitations of the low cost induction motor design.
            HVLS fans by BAF are variable speed and can slow down to less than
            20% of maximum speed.
          </li>
          <li>
            Size matters according to the laws of physics. The combination of
            tip speed, which leads to higher lift generation (aka airflow) and
            low RPM operation, which means efficient motor loading, results in
            HVLS fans being more efficient.
          </li>
          <li>
            Directional fans are typically standalone devices that require the
            user to turn them off when not needed. HVLS fans are more often
            connected to controls, which will vary speed and operation based on
            variables such as occupancy and/or air dry bulb temperature.{" "}
          </li>
          <li>
            It should be noted higher efficiency directional fans with ECM
            motors and highly engineered fan blades and occupancy sensors are
            available, but are not typical of the low cost directional fan
            product offerings.
          </li>
        </ul>
        <p>
          Due to the factors above, directional fans often draw 3 to 5 times as
          much power per CFM (L/s) as a similar BAF fan. Compared to BAF ceiling
          fans, directional fans often draw 10 to 15 times as much power per CFM
          (L/s). When combined with the number of directional fans required to
          adequately cool occupants in a space, directional fans can
          significantly increase the electrical demand on the building. Properly
          designed ceiling fans provide uniform air speeds to the entire space
          and reduce the electric demand on the building’s electrical system.
        </p>
      </div>
    </div>
  );
}

export default C2S5;
