import React from "react";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import Dashboard from "./components/dashboard";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import MobileDashboard from './components/Mobile/dashboard';

const isMobile = window.innerWidth <= 500;
const theme = createMuiTheme({
  typography: {
    fontFamily: "Proxima Nova W15"
  },
  palette: {
    primary: {
      main: "#ffc425"
    }
  }
});

export default function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <div id="top">
          {isMobile ?
          <MobileDashboard history={window.location.pathname} /> :
          <Dashboard history={window.location.pathname} /> }
        </div>
      </Router>
    </MuiThemeProvider>
  );
}
