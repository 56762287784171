import React from 'react';
import {useState } from 'react';
import Notes from './Notes';
import ProjectDetails from './ProjectDetails';
import Logo from './Logo'

function makeOpts(list){
    return list.map(e => {
        return(<option key={e} value={e}>{e}</option>);
    });
}

function makeLinks(obj){
    return Object.keys(obj).map(e => {
        return <a href={obj[e]}>{e}</a>
    });
}

export default function LargeFanTemplate(props){
    // what props should this take?
    // maybe just the object from the db
    const [size, setSize] = useState("");
    const [voltage, setVolts] = useState("");
    const [tube, setTube] = useState("");
    const [controller, setController] = useState("");
    const [mount, setMount] = useState("");
    const [standardColor, setStandardColor ] = useState("");

    const fan = props.fan;
    const sizes = fan.sizes;
    const volts = fan.volts;
    const maxSound = fan.maxSound;
    const weights = fan.weights;
    const motor = fan.motor;
    const rpm = fan.rpm;
    const rcs = fan.rcs;
    const fla = fan.fla;
    const parts = fan.parts;
    const appearanceOpts = fan.appearanceOpts;
    const hb = fan.hb;
    const mounts = fan.mounts;
    const additions = fan.additions;
    const features = fan.features;
    const controllerFeatures = fan.controllerFeatures;

    const makeTable = (head, list) => {
        let out = [];

        if(Array.isArray(list)){
            list.forEach((e)=>{
                out.push(<tr><td>{e}</td></tr>);
            });
            return(
                <table>
                    <tr><th colSpan="2">{head}: </th></tr>
                    <tbody>
                        {out}
                    </tbody>
                </table>
            );
        }
    }

    // this function returns two collumns
    // auto populated by database information
    return(
        <div>
        <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
            <div>
                { <ProjectDetails /> }
                <div>
                    <table>
                    <thead><tr><th colSpan="2">Product Details: </th></tr></thead>
                    <tbody>
                        <tr><td>Quantity: </td><td><input type="number" min="1" required></input></td></tr>
                        {fan.sizes && <tr><td>Size {fan.sizeUnits && `(${fan.sizeUnits})`}: </td><td><select onChange={e => setSize(e.target.value)} required>
                        <option></option>{
                            sizes.map(e => {
                                return <option key={e} value={e}>{e}</option>
                            })
                            }</select></td></tr>}
                        {fan.volts && <tr><td>Voltage/Phase: </td><td><select onChange={e => setVolts(e.target.value)} required>
                        <option></option>
                        {volts.map(e => {
                                return <option key={e} value={e}>{e}</option>
                            })
                            }</select></td></tr>}
                        {size && fan.variableVolts && <tr><td>Voltage/Phase: </td><td><select required onChange={ e => {setVolts(e.target.value)}}><option></option>{makeOpts(fan.variableVolts[size])}</select></td></tr>}
                        {fan.weights && <tr><td>Standard Weight: </td><td>{weights[size]} lbs</td></tr>}
                        {fan.motor && <tr><td>Motor: </td><td>{motor[size]} HP</td></tr>}
                        {fan.rpm && <tr><td>Max RPM: </td><td>{rpm[size]} RPM</td></tr>}
                        {fan.variableRpm && <tr><td>Max RPM: </td><td>{fan.variableRpm[size + " " + voltage]} RPM</td></tr>}
                        {fan.maxAirflow && <tr><td>Max Airflow: </td>{fan.maxAirflow[size]} CFM</tr>}
                        {fan.rcs && <tr><td>Supply Circuit Size: </td><td>{rcs[size + " " + voltage]} A</td></tr>}                        
                        {fan.fla && <tr><td>Full Load Amperage: </td><td>{fla[size + " " + voltage]} A</td></tr>}
                        {fan.directionalWeight && <tr><td>Standard Weight: </td><td>{fan.directionalWeight[mount]} lbs</td></tr>}
                        {fan.maxAmps && <tr><td>Max Amperage: </td><td>{fan.maxAmps[size +" "+voltage]} A</td></tr>}
                        {fan.maxWatts && <tr><td>Max Wattage: </td><td>{fan.maxWatts[size +" "+voltage]} W</td></tr>}
                        {fan.ionWatts && <tr><td>Ionizer Wattage: </td><td>{fan.ionWatts} W</td></tr>}
                        {fan.staticMotor && <tr><td>Motor: </td><td>{fan.staticMotor} HP</td></tr>}
                        {fan.staticWeight && <tr><td>Standard Weight: </td><td>{fan.staticWeight} lbs</td></tr>}
                        {fan.waterCapacity && <tr><td>Water Capacity: </td><td>{fan.waterCapacity}</td></tr>}
                        {fan.waterPressure && <tr><td>Inlet Water Supply Pressure: </td><td>{fan.waterPressure}</td></tr>}
                        {fan.staticVoltage && <tr><td>Voltage/Phase: </td><td>{fan.staticVoltage}</td></tr>}
                        {fan.staticRCS && <tr><td>Supply Circuit Size: </td><td>{fan.staticRCS}</td></tr>}
                        {fan.staticMaxAmps && <tr><td>Supply Circuit Size: </td><td>{fan.staticRCS}</td></tr>}
                        {fan.airflow && <tr><td>Airflow: </td><td>{fan.airflow}</td></tr>}
                        {fan.coverageArea && <tr><td>Coverage Area: </td><td>{fan.coverageArea}</td></tr>}
                        {fan.maxSound && <tr><td>Max Sound Level: </td><td>{maxSound} dBA</td></tr>}
                        {fan.directionalMounts && <tr><td>Mounting Hardware: </td><td><select onChange={e => setMount(e.target.value)} required><option></option>
                            {fan.directionalMounts.map(e => {
                                return <option key={e} value={e}>{e}</option>
                            })}
                        </select></td></tr>}
                        {fan.yokeET && (mount === "Yoke Mnt") && <tr><td>ET Length: </td><td><select required><option></option>
                            {fan.yokeET.map(e => {
                                return <option key={e} value={e}>{e}</option>
                            })}
                        </select></td></tr>}
                        </tbody>
                    </table>
                </div>
                {fan.airfoilFinish && fan.hardwareFinish &&
                    <table>
                        <thead><th colSpan="2">Color Options:</th></thead>
                        <tbody>
                            <tr><td>Airfoil Finish: </td><td><select><option></option>{makeOpts(fan.airfoilFinish)}</select></td></tr>
                            <tr><td>Hardware Finish: </td><td><select><option></option>{makeOpts(fan.hardwareFinish)}</select></td></tr>
                        </tbody>
                    </table>
                }
                <div>  
                    {fan.mounts && <table>
                    <thead><tr><th colSpan="2">Mounting Hardware: </th></tr></thead>                    
                    <tbody>
                        {fan.hb && <tr><td>Extension Tube: </td><td><select onChange={e => setTube(e.target.value)} required><option></option>{makeOpts(Object.keys(hb))}</select></td></tr>}
                        {fan.mounts && <tr><td>Type: </td><td><select required><option></option>{makeOpts(mounts)}</select></td></tr>}
                    </tbody>
                    </table>}
                    { fan.controllerFeatures && 
                    <table>
                    <tr><th colSpan="2">Controller Setup: </th></tr>
                    <tbody>
                        <tr><td>Controller Type: </td><td><select onChange={e => {
                            setController(e.target.value)
                        }} required><option></option>{makeOpts(Object.keys(controllerFeatures))}</select></td></tr>
                        {controller === "BAFCon (Advanced)" &&  <tr><td>Single/Multi Fan Control: </td><td><select><option>Single</option><option>Multi</option></select></td></tr>}
                    </tbody>
                    </table> }
                    {!fan.noDims && <table>
                    <thead><tr><th colSpan="2">Critical Dimensions: </th></tr></thead>                    
                    <tbody>
                        {fan.hb && <tr><td>Foil to Mount (Hb): </td><td>{hb[tube]}"</td></tr>}
                        {fan.sizes && !fan.width && !fan.cageDiameter && <tr><td>Fan Diameter (W): </td><td>{size ? (size%1!==0 ? (size*12).toFixed(2) : size*12) : ""}"</td></tr>}
                        {fan.cageDiameter && <tr><td>Cage Diameter (A): </td><td>{fan.cageDiameter[size]}"</td></tr>}
                        {fan.height && <tr><td>Height: </td><td>{fan.height}"</td></tr>}
                        {fan.cageDepth && <tr><td>Cage Depth: </td><td>{fan.cageDepth}"</td></tr>} 
                        {fan.depth && <tr><td>Depth: </td><td>{fan.depth}"</td></tr>}
                        {fan.directionalHeight && <tr><td>Height: </td><td>{fan.directionalHeight[mount]}"</td></tr>}
                        {fan.width &&  <tr><td>Width: </td><td>{fan.width}"</td></tr>}
                        {fan.directionalDepth && <tr><td>Depth: </td><td>{fan.directionalDepth[mount]}"</td></tr>}
                        {fan.bladeDiameter && <tr><td>Blade Diameter: </td><td>{fan.bladeDiameter[size]}"</td></tr>}
                    </tbody>
                    </table>}
                    {fan.warranty && 
                        <table>
                            <thead>
                                <tr><th colSpan="2">Warranty Information </th></tr>
                                <tr><th>Item</th><th>Period of Coverage</th></tr>
                            </thead>                            
                            <tbody>
                                {Object.keys(fan.warranty).map(e => {
                                    return <tr><td>{e}</td><td>{fan.warranty[e]}</td></tr>
                                })}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
            <div>
                <div>
                    {fan === undefined && <Logo name={"Generic Name"} />}
                    {fan !== undefined && <Logo name={fan.name} />}
                </div>
                { appearanceOpts !== undefined && (
                    <table>
                    <thead>
                        <tr><th colSpan="2">Appearance Options: </th></tr>
                        <tr><th>Part</th><th>Style</th></tr>
                    </thead>
                    <tbody>
                        <tr><td><select onChange={e => setStandardColor(e.target.value)}><option></option>{makeOpts(parts)}</select></td><td><select><option></option>{standardColor !== 'Standard' ? makeOpts(fan.appearanceOpts) : fan.standardColorOpts && makeOpts(fan.standardColorOpts)}</select></td></tr>
                        <tr><td><select><option></option>{makeOpts(parts)}</select></td><td><select><option></option>{makeOpts(appearanceOpts)}</select></td></tr>
                        <tr><td><select><option></option>{makeOpts(parts)}</select></td><td><select><option></option>{makeOpts(appearanceOpts)}</select></td></tr>
                    </tbody>
                    </table>)
                    }
                    {fan.appearencePackage && 
                    <table>
                        <thead><th colSpan="2">Appearance  Package: </th></thead>
                        <tbody>
                            {Object.keys(fan.appearencePackage).map(part => <tr><td>{part}</td><td>{fan.appearencePackage[part]}</td></tr>)}
                        </tbody>
                    </table>
                    }
                        { (fan.additions !== undefined) && 
                    <table>
                    <thead>
                        <tr><th colSpan="2">Additions: </th></tr>
                    </thead>                    
                    <tbody>
                        <tr><td><select><option></option>{makeOpts(additions)}</select></td><td><select><option></option>{makeOpts(additions)}</select></td></tr>
                        <tr><td><select><option></option>{makeOpts(additions)}</select></td><td><select><option></option>{makeOpts(additions)}</select></td></tr>
                        <tr><td><select><option></option>{makeOpts(additions)}</select></td><td><select><option></option>{makeOpts(additions)}</select></td></tr>
                    </tbody>
                    </table>} 
                    {fan.features && makeTable("Features", features)}
                    {fan.controllerFeatures && makeTable(`${controller} Features`, controllerFeatures[controller])}
                    {fan.senseME && makeTable("SenseME Features", fan.senseME)}
                    <table>
                    <thead><tr><th colSpan="2">Technical Notes </th></tr></thead>                    
                    <tbody>
                        <tr><td >All data is subject to change without notice. Data indicated in this document were correct at the time of printing with the exception of printing and clerical errors.</td></tr>
                    </tbody>
                    </table>
                    <Notes />
                    {fan.links && makeLinks(fan.links)}
            </div>
        </div> 
        <img style={{display:'block', marginLeft:'auto', marginRight:'auto', width: '50%', maxHeight:300, maxWidth:800}} alt={`${fan.name} dwg`} src={`${window.location.origin}/technicalDrawings/${fan.name}.png`} />
        </div>
    );
}