import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C3S5() {
  return (
    <div className="chapter-container">
      <Anchor id="3-5-1" />
      <H2 name="3.5 Duct Design" />
      <H4 name="3.5.1 Standard supply duct design without ceiling fans" />
      <p>
        To uniformly distribute air and maintain a consistent space temperature
        without gradients or stratification, the standard practice for supply
        ductwork design in gyms and other large spaces is to provide multiple
        injection points at multiple locations throughout the space. An example
        of this type of ductwork configuration for a gym is illustrated in
        Figure 3.5.1a.
      </p>

      <Anchor id="3-5-2" />
      <H4 name="3.5.2 Sample supply duct layout with large diameter ceiling fans" />
      <p>
        To reduce HVAC construction cost associated with the standard air
        distribution method, ceiling fans can be added. The fans help distribute
        supply air evenly throughout the space without the need for the
        additional ductwork. This saves construction cost by reducing materials
        and labor for the following equipment and services:
      </p>
      <ul>
        <li>Ductwork and fittings</li>
        <li>Ductwork insulation or liner</li>
        <li>Ductwork sealing</li>
        <li>Ductwork painting (if applicable)</li>
        <li>Manual balancing dampers</li>
        <li>Supply grilles or diffusers</li>
        <li>Air balancing</li>
      </ul>

      <Anchor id="3-5-3" />
      <H4 name="3.5.3 Sample first cost estimate" />
      <p>
        Using the gym example below, the ductwork can be reduced as shown in
        Figure 3.5.2a with the addition of two 14 ft. (4.3 m) diameter ceiling
        fans. By adding two ceiling fans, twelve (12) supply grilles, 68 ft. of
        14 in.ø (21 m of 355 mmø) supply duct, and 84 ft. of 18 in.ø (26 m of 457
        mmø) supply duct can be eliminated. The first cost of the system with
        the ceiling fans was estimated, as was the first cost of the fully
        ducted system without the ceiling fans. The cost of the system with the
        ceiling fans was found to be less than the fully ducted system. The HVAC
        scope removed from this example project costs approximately 1.5 times
        that of the two large diameter ceiling fans added to the project, thus
        more than offsetting the cost of the fans.
      </p>

      <p>
        In this example, the savings is associated with the reduction of air
        distribution hardware only and does not include first-cost savings
        associated with reducing the size of the central air handling equipment.
        In situations where the central air handling unit serving the space can
        be reduced in size, the HVAC scope removed could be up to 4.5 times that
        of the two large diameter ceiling fans added to the project.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-5-1a.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.5.1a - Above is a plan view of a conventional duct layout in a typical gym where no circulator fans are used." />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-5-1b.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.5.2a - An example of the same gym as above but with reduced ductwork and the addition of large diameter ceiling fans." />
      </Container>
    </div>
  );
}

export default C3S5;
