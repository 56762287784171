/* 
Michael White
Hello! and Welcome to movie phone.
This javascript object is the database for the submittals web portal.
An object is a way to store key value pairs.
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object
Basically we use each fan's name in the dropdown as a key
from that key we load in data about that fan as more key value pairs
 
QQ: Why isn't this a real database?
Not sure how to exactly set up a db at big ass fans
*/

const db = {
  PFD: {
    template: "LargeFanTemplate",
    name: "Powerfoil D",
    sizes: [8, 10, 12, 14, 16, 18, 20, 24],
    sizeUnits: "ft",
    volts: ["200-277V/1", "200-277V/3", "380-480V/3"],
    maxSound: "---",
    weights: {
      8: 212,
      10: 220,
      12: 227,
      14: 235,
      16: 242,
      18: 273,
      20: 280,
      24: 295,
    },
    motor: {
      8: 1.5,
      10: 1.5,
      12: 1.5,
      14: 1.5,
      16: 1.5,
      18: 2.0,
      20: 2.0,
      24: 2.0,
    },
    rpm: { 8: 200, 10: 145, 12: 145, 14: 120, 16: 108, 18: 97, 20: 84, 24: 64 },
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Motor",
      "Gear Box",
      "Lower Cover",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
      "AirFence system",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    additions: [
      "AFRS (Airfoil Restraint)",
      '10" L-Bracket',
      '8" L-Bracket',
      "BAFWorks",
      "Big Fan Pkg",
      "Texas Edition",
      "Dewtect",
      "EMI Filter",
      "NEMA 4X Enclosure",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
      "Powerfoil LED",
      "Occupancy sensor",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/common/industrial-overhead-install-guide.pdf",
    },
    features: [
      "Intertek/ETL certified to UL 507 and CSA 22.2	",
      "(6) patented aluminum Powerfoil airfoils	",
      "IP-66 rated; Suitable for indoor & outdoor use	",
      "Static tube for optional LED or occupancy sensor	",
      "Eight redundant safety features	",
    ],
    hb: {
      "Direct Mt": "30",
      "No Tube": "40",
      "1' (Standard)": "48",
      "2'": "60",
      "3'": "72",
      "4'": "84",
      "5'": "96",
      "6'": "108",
      "7'": "120",
      "8'": "132",
      "9'": "144",
      "10'": "156",
      "11'": "168",
      "12'": "180",
      "13'": "192",
      "14'": "204",
      "15'": "216",
    },
    rcs: {
      "8 200-277V/1": "10",
      "8 200-277V/3": "10",
      "8 380-480V/3": "10",
      "10 200-277V/1": "10",
      "10 200-277V/3": "10",
      "10 380-480V/3": "10",
      "12 200-277V/1": "10",
      "12 200-277V/3": "10",
      "12 380-480V/3": "10",
      "14 200-277V/1": "10",
      "14 200-277V/3": "10",
      "14 380-480V/3": "10",
      "16 200-277V/1": "15",
      "16 200-277V/3": "15",
      "16 380-480V/3": "10",
      "18 200-277V/1": "15",
      "18 200-277V/3": "15",
      "18 380-480V/3": "10",
      "20 200-277V/1": "15",
      "20 200-277V/3": "15",
      "20 380-480V/3": "10",
      "24 200-277V/1": "15",
      "24 200-277V/3": "15",
      "24 380-480V/3": "10",
    },
    fla: {
      "8 200-277V/1": "3.0",
      "8 200-277V/3": "3.0",
      "8 380-480V/3": "1.3",
      "10 200-277V/1": "2.9",
      "10 200-277V/3": "2.9",
      "10 380-480V/3": "1.2",
      "12 200-277V/1": "5.3",
      "12 200-277V/3": "5.3",
      "12 380-480V/3": "2.4",
      "14 200-277V/1": "6.1",
      "14 200-277V/3": "6.1",
      "14 380-480V/3": "2.7",
      "16 200-277V/1": "7.6",
      "16 200-277V/3": "7.6",
      "16 380-480V/3": "3.4",
      "18 200-277V/1": "8.2",
      "18 200-277V/3": "8.2",
      "18 380-480V/3": "3.8",
      "20 200-277V/1": "8.8",
      "20 200-277V/3": "8.8",
      "20 380-480V/3": "3.7",
      "24 200-277V/1": "7.9",
      "24 200-277V/3": "7.9",
      "24 380-480V/3": "3.4",
    },
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control of up to 8 fans per controller",
        "Multi-level password protection and SmartSense ",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system	",
        "Compatible with BACnet MSTP and BACnet over IP	",
        "One adapter enables individual control of up to 32 fans	",
      ],
    },
    warranty: {
      Mechanical: "15 years",
      Electrical: "15 years (with factory install), 7 years",
      Labor: "1 year",
    },
  },
  CB6: {
    name: "CB6",
    template: "LargeFanTemplate",
    sizes: [12, 16, 20, 24],
    sizeUnits: "ft",
    volts: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
    weights: { 12: 185, 16: 204, 20: 217, 24: 231 },
    maxSound: 55,
    motor: { 12: 1.5, 16: 2.0, 20: 2.0, 24: 2.0 },
    rpm: { 12: 135, 16: 98, 20: 76, 24: 61 },
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/common/industrial-overhead-install-guide.pdf",
    },
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    features: [
      "Intertek/ETL certified to UL 507 and CSA 22.2	",
      "(6) patented aluminum Powerfoil airfoils	",
      "Powerfoil winglets eliminate wind noise	",
      "NEMA 4X VFD and Standard Controller (WSC)	",
      "Fire relay and multiple safety redundancies	",
      "Airfoil Restraint System (AFRS) Standard on 20'-24'	",
    ],
    rcs: {
      "12 200-240V/1": "25",
      "16 200-240V/1": "25",
      "20 200-240V/1": "25",
      "24 200-240V/1": "25",
      "12 200-240V/3": "15",
      "16 200-240V/3": "15",
      "20 200-240V/3": "15",
      "24 200-240V/3": "15",
      "12 400-480V/3": "10",
      "16 400-480V/3": "10",
      "20 400-480V/3": "10",
      "24 400-480V/3": "10",
      "12 575-600V/3": "10",
      "16 575-600V/3": "10",
      "20 575-600V/3": "10",
      "24 575-600V/3": "10",
    },
    fla: {
      "12 200-240V/1": "13.3",
      "16 200-240V/1": "13.3",
      "20 200-240V/1": "13.3",
      "24 200-240V/1": "13.3",
      "12 200-240V/3": "5.0",
      "16 200-240V/3": "8.1",
      "20 200-240V/3": "8.1",
      "24 200-240V/3": "8.1",
      "12 400-480V/3": "2.6",
      "16 400-480V/3": "4.8",
      "20 400-480V/3": "4.8",
      "24 400-480V/3": "4.8",
      "12 575-600V/3": "2.0",
      "16 575-600V/3": "2.7",
      "20 575-600V/3": "2.7",
      "24 575-600V/3": "2.7",
    },
    hb: {
      "Direct Mt": "29",
      "No Tube": "40",
      "1' (Standard)": "47",
      "2'": "59",
      "3'": "71",
      "4'": "83",
      "5'": "95",
      "6'": "107",
      "7'": "119",
      "8'": "131",
      "9'": "143",
      "10'": "155",
      "11'": "167",
      "12'": "179",
      "13'": "191",
      "14'": "203",
      "15'": "215",
    },
    warranty: {
      Mechanical: "1 year",
      Electrical: "1 year",
      labor: "1 year",
    },
  },
  "Basic 6": {
    template: "LargeFanTemplate",
    name: "Basic 6",
    sizes: [8, 10, 12, 14, 16, 18, 20, 24],
    sizeUnits: "ft",
    variableVolts: {
      8: ["100-125V/1", "200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      10: [
        "100-125V/1",
        "200-240V/1",
        "200-240V/3",
        "400-480V/3",
        "575-600V/3",
      ],
      12: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      14: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      16: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      18: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      20: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      24: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
    },
    maxSound: "55",
    weights: {
      8: 124,
      10: 135,
      12: 185,
      14: 192,
      16: 204,
      18: 210,
      20: 217,
      24: 231,
    },
    motor: {
      8: 1,
      10: 1,
      12: 1.5,
      14: 1.5,
      16: 2,
      18: 2,
      20: 2,
      24: 2,
    },
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    features: [
      "Intertek/ETL certified to UL 507 and CSA 22.2	",
      "(6) patented aluminum Powerfoil airfoils	",
      "Powerfoil winglets eliminate wind noise	",
      "Fire relay and multiple safety redundancies	",
      "Airfoil Restraint System (AFRS) Standard on 20'-24'	",
    ],
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    hb: {
      "Direct Mt": "29",
      "No Tube": "40",
      "1' (Standard)": "47",
      "2'": "59",
      "3'": "71",
      "4'": "83",
      "5'": "95",
      "6'": "107",
      "7'": "119",
      "8'": "131",
      "9'": "143",
      "10'": "155",
      "11'": "167",
      "12'": "179",
      "13'": "191",
      "14'": "203",
      "15'": "215",
    },
    rcs: {
      "8 100-125V/1": "15",
      "8 200-240V/1": "15",
      "8 200-240V/3": "10",
      "8 400-480V/3": "10",
      "8 575-600V/3": "10",
      "10 100-125V/1": "15",
      "10 200-240V/1": "15",
      "10 200-240V/3": "10",
      "10 400-480V/3": "10",
      "10 575-600V/3": "10",
      "12 100-125V/1": "N/A",
      "12 200-240V/1": "25",
      "12 200-240V/3": "15",
      "12 400-480V/3": "10",
      "12 575-600V/3": "10",
      "14 100-125V/1": "N/A",
      "14 200-240V/1": "25",
      "14 200-240V/3": "15",
      "14 400-480V/3": "10",
      "14 575-600V/3": "10",
      "16 100-125V/1": "N/A",
      "16 200-240V/1": "25",
      "16 200-240V/3": "15",
      "16 400-480V/3": "10",
      "16 575-600V/3": "10",
      "18 100-125V/1": "N/A",
      "18 200-240V/1": "25",
      "18 200-240V/3": "15",
      "18 400-480V/3": "10",
      "18 575-600V/3": "10",
      "20 100-125V/1": "N/A",
      "20 200-240V/1": "25",
      "20 200-240V/3": "15",
      "20 400-480V/3": "10",
      "20 575-600V/3": "10",
      "24 100-125V/1": "N/A",
      "24 200-240V/1": "25",
      "24 200-240V/3": "15",
      "24 400-480V/3": "10",
      "24 575-600V/3": "10",
    },
    fla: {
      "8 100-125V/1": "9",
      "8 200-240V/1": "8.8",
      "8 200-240V/3": "5.0",
      "8 400-480V/3": "2.6",
      "8 575-600V/3": "1.3",
      "10 100-125V/1": "9",
      "10 200-240V/1": "8.8",
      "10 200-240V/3": "5.0",
      "10 400-480V/3": "2.6",
      "10 575-600V/3": "1.3",
      "12 100-125V/1": "N/A",
      "12 200-240V/1": "13.3",
      "12 200-240V/3": "5.0",
      "12 400-480V/3": "2.6",
      "12 575-600V/3": "2.0",
      "14 100-125V/1": "N/A",
      "14 200-240V/1": "13.3",
      "14 200-240V/3": "5.0",
      "14 400-480V/3": "2.6",
      "14 575-600V/3": "2.0",
      "16 100-125V/1": "N/A",
      "16 200-240V/1": "13.3",
      "16 200-240V/3": "8.1",
      "16 400-480V/3": "4.8",
      "16 575-600V/3": "2.7",
      "18 100-125V/1": "N/A",
      "18 200-240V/1": "13.3",
      "18 200-240V/3": "8.1",
      "18 400-480V/3": "4.8",
      "18 575-600V/3": "2.7",
      "20 100-125V/1": "N/A",
      "20 200-240V/1": "13.3",
      "20 200-240V/3": "8.1",
      "20 400-480V/3": "4.8",
      "20 575-600V/3": "2.7",
      "24 100-125V/1": "N/A",
      "24 200-240V/1": "13.3",
      "24 200-240V/3": "8.1",
      "24 400-480V/3": "4.8",
      "24 575-600V/3": "2.7",
    },
    additions: [
      '10" L-Bracket',
      '8" L-Bracket',
      "BACnet Adapter",
      "BAFWorks",
      "Big Fan Pkg",
      "Dewtect",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Onboard VFD",
      "Security Kit for Wall Control",
      "SmartSense",
      "AFRS (Airfoil Restraint)",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
    ],
    warranty: {
      Mechanical: "7 years",
      Electrical: "3 years",
      Labor: "1 year",
    },
  },
  PFX3: {
    template: "LargeFanTemplate",
    name: "Powerfoil X 3.0",
    sizes: [12, 14, 16, 18, 20, 24],
    sizeUnits: "ft",
    volts: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
    maxSound: "55",
    weights: { 12: 245, 14: 254, 16: 264, 18: 281, 20: 290, 24: 347 },
    motor: { 12: 1.5, 14: 1.5, 16: 1.5, 18: 2.0, 20: 2.0, 24: 2.0 },
    rpm: { 12: 139, 14: 118, 16: 99, 18: 88, 20: 78, 24: 65 },
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Motor",
      "Gear Box",
      "Lower Cover",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
      "AirFence system",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    additions: [
      "AFRS (Airfoil Restraint)",
      '10" L-Bracket',
      '8" L-Bracket',
      "BAFWorks",
      "Big Fan Pkg",
      "Texas Edition",
      "Dewtect",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Off Board VFD",
      "Security Kit for Wall Control",
      "Occupancy Sensor",
      "Powerfoil LED",
      "Standard Camera",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/common/industrial-overhead-install-guide.pdf",
    },
    features: [
      "CE marked and Intertek/ETL certified	",
      "Built to UL 507 and CSA 22.2	",
      "(8) patented aluminum Powerfoil airfoils	",
      "IP43 motor and NitroSeal Drive gearbox	",
      "Onboard VFD with built-in heatsink	",
      "Fire relay and multiple safety redundancies	",
      "Airfoil restraint system (AFRS) standard on 20'-24'	",
    ],
    hb: {
      "Direct Mt": "28",
      "No Tube": "39",
      "1' (Standard)": "46",
      "2'": "58",
      "3'": "70",
      "4'": "82",
      "5'": "94",
      "6'": "106",
      "7'": "118",
      "8'": "130",
      "9'": "142",
      "10'": "154",
      "11'": "166",
      "12'": "178",
      "13'": "190",
      "14'": "202",
      "15'": "214",
    },
    fla: {
      "12 200-240V/1": "8.7",
      "12 200-240V/3": "5.0",
      "12 400-480V/3": "2.5",
      "12 575-600V/3": "1.9",
      "14 200-240V/1": "8.7",
      "14 200-240V/3": "5.0",
      "14 400-480V/3": "2.5",
      "14 575-600V/3": "1.9",
      "16 200-240V/1": "8.7",
      "16 200-240V/3": "5.0",
      "16 400-480V/3": "2.5",
      "16 575-600V/3": "1.9",
      "18 200-240V/1": "10.7",
      "18 200-240V/3": "6.2",
      "18 400-480V/3": "3.0",
      "18 575-600V/3": "2.3",
      "20 200-240V/1": "10.7",
      "20 200-240V/3": "6.2",
      "20 400-480V/3": "3.0",
      "20 575-600V/3": "2.3",
      "24 200-240V/1": "10.7",
      "24 200-240V/3": "6.2",
      "24 400-480V/3": "3.0",
      "24 575-600V/3": "2.3",
    },
    rcs: {
      "12 200-240V/1": "30",
      "12 200-240V/3": "20",
      "12 400-480V/3": "10",
      "12 575-600V/3": "10",
      "14 200-240V/1": "30",
      "14 200-240V/3": "20",
      "14 400-480V/3": "10",
      "14 575-600V/3": "10",
      "16 200-240V/1": "30",
      "16 200-240V/3": "20",
      "16 400-480V/3": "10",
      "16 575-600V/3": "10",
      "18 200-240V/1": "30",
      "18 200-240V/3": "20",
      "18 400-480V/3": "10",
      "18 575-600V/3": "10",
      "20 200-240V/1": "30",
      "20 200-240V/3": "20",
      "20 400-480V/3": "10",
      "20 575-600V/3": "10",
      "24 200-240V/1": "30",
      "24 200-240V/3": "20",
      "24 400-480V/3": "10",
      "24 575-600V/3": "10",
    },
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control up to 8 fans per controller",
        "Multi-level password protection and SmartSense	",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system",
        "Compatible with BACnet MSTP and BACnet over IP",
        "One adapter enables individual control up to 32 fans",
      ],
    },
    warranty: {
      Mechanical: "15 years",
      Electrical: "15 years (with factory install), 7 years",
      Labor: "1 year",
    },
  },
  "PFX3 Plus": {
    template: "LargeFanTemplate",
    name: "Powerfoil X 3.0 Plus",
    sizes: [12, 14, 16, 18, 20, 24],
    sizeUnits: "ft",
    volts: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
    maxSound: "55",
    weights: { 12: 249, 14: 259, 16: 276, 18: 285, 20: 334, 24: 343 },
    motor: { 12: 1.5, 14: 1.5, 16: 1.5, 18: 2.0, 20: 2.0, 24: 2.0 },
    rpm: { 12: 118, 14: 99, 16: 89, 18: 78, 20: 70, 24: 62 },
    warranty: {
      Mechanical: "15 years",
      Electrical: "15 years (with factory install), 7 years",
      Labor: "1 year",
    },
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Motor",
      "Gear Box",
      "Lower Cover",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
      "AirFence system",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    additions: [
      "AFRS (Airfoil Restraint)",
      '10" L-Bracket',
      '8" L-Bracket',
      "BAFWorks",
      "Big Fan Pkg",
      "Texas Edition",
      "Dewtect",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Off Board VFD",
      "Security Kit for Wall Control",
      "Occupancy Sensor",
      "Standard Camera",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
      "Powerfoil LED",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/common/industrial-overhead-install-guide.pdf",
    },
    features: [
      "CE marked and Intertek/ETL certified	",
      "Built to UL 507 and CSA 22.2	",
      "(8) patented aluminum Powerfoil airfoils	",
      "IP43 motor and NitroSeal Drive gearbox	",
      "Onboard VFD with built-in heatsink	",
      "Fire relay and multiple safety redundancies	",
      "Airfoil restraint system (AFRS) standard on 20'-24'	",
    ],
    hb: {
      "Direct Mt": "29",
      "No Tube": "37",
      "1' (Standard)": "44",
      "2'": "56",
      "3'": "68",
      "4'": "80",
      "5'": "92",
      "6'": "104",
      "7'": "116",
      "8'": "128",
      "9'": "140",
      "10'": "152",
      "11'": "164",
      "12'": "176",
      "13'": "188",
      "14'": "200",
      "15'": "212",
    },
    fla: {
      "12 200-240V/1": "8.7",
      "12 200-240V/3": "5.0",
      "12 400-480V/3": "2.5",
      "12 575-600V/3": "1.9",
      "14 200-240V/1": "8.7",
      "14 200-240V/3": "5.0",
      "14 400-480V/3": "2.5",
      "14 575-600V/3": "1.9",
      "16 200-240V/1": "8.7",
      "16 200-240V/3": "5.0",
      "16 400-480V/3": "2.5",
      "16 575-600V/3": "1.9",
      "18 200-240V/1": "10.7",
      "18 200-240V/3": "6.2",
      "18 400-480V/3": "3.0",
      "18 575-600V/3": "2.3",
      "20 200-240V/1": "10.7",
      "20 200-240V/3": "6.2",
      "20 400-480V/3": "3.0",
      "20 575-600V/3": "2.3",
      "24 200-240V/1": "10.7",
      "24 200-240V/3": "6.2",
      "24 400-480V/3": "3.0",
      "24 575-600V/3": "2.3",
    },
    rcs: {
      "12 200-240V/1": "30",
      "12 200-240V/3": "20",
      "12 400-480V/3": "10",
      "12 575-600V/3": "10",
      "14 200-240V/1": "30",
      "14 200-240V/3": "20",
      "14 400-480V/3": "10",
      "14 575-600V/3": "10",
      "16 200-240V/1": "30",
      "16 200-240V/3": "20",
      "16 400-480V/3": "10",
      "16 575-600V/3": "10",
      "18 200-240V/1": "30",
      "18 200-240V/3": "20",
      "18 400-480V/3": "10",
      "18 575-600V/3": "10",
      "20 200-240V/1": "30",
      "20 200-240V/3": "20",
      "20 400-480V/3": "10",
      "20 575-600V/3": "10",
      "24 200-240V/1": "30",
      "24 200-240V/3": "20",
      "24 400-480V/3": "10",
      "24 575-600V/3": "10",
    },
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control up to 8 fans per controller",
        "Multi-level password protection and SmartSense	",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system",
        "Compatible with BACnet MSTP and BACnet over IP",
        "One adapter enables individual control up to 32 fans",
      ],
    },
  },
  "PFX3 Washdown": {
    template: "LargeFanTemplate",
    name: "Powerfoil X 3.0 Washdown",
    sizes: [12, 14, 16, 18],
    sizeUnits: "ft",
    volts: ["200-240V/1", "200-240V/3", "400-480V/3"],
    maxSound: "55",
    weights: { 12: 245, 14: 254, 16: 264, 18: 281 },
    motor: { 12: 1.5, 14: 1.5, 16: 1.5, 18: 2.0 },
    rpm: { 12: 139, 14: 118, 16: 99, 18: 88 },
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Motor",
      "Gear Box",
      "Lower Cover",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
      "AirFence system",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    additions: [
      "AFRS (Airfoil Restraint)",
      '10" L-Bracket',
      '8" L-Bracket',
      "BAFWorks",
      "Big Fan Pkg",
      "Texas Edition",
      "Dewtect",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Off Board VFD",
      "Security Kit for Wall Control",
      "Occupancy Sensor",
      "Standard Camera",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
      "Powerfoil LED",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/common/industrial-overhead-install-guide.pdf",
    },
    features: [
      "CE marked and Intertek/ETL certified",
      "Built to UL 507 and CSA 22.2",
      "(8) patented aluminum Powerfoil airfoils",
      "IP56 motor and NitroSeal Drive gearbox",
      "Remote mounted VFD with built-in heatsink",
      "Fire relay and multiple safety redundancies",
    ],
    hb: {
      "Direct Mt": "28",
      "No Tube": "39",
      "1' (Standard)": "46",
      "2'": "58",
      "3'": "70",
      "4'": "82",
      "5'": "94",
      "6'": "106",
      "7'": "118",
      "8'": "130",
      "9'": "142",
      "10'": "154",
      "11'": "166",
      "12'": "178",
      "13'": "190",
      "14'": "202",
      "15'": "214",
    },
    fla: {
      "12 200-240V/1": "8.7",
      "12 200-240V/3": "5.0",
      "12 400-480V/3": "2.5",
      "12 575-600V/3": "1.9",
      "14 200-240V/1": "8.7",
      "14 200-240V/3": "5.0",
      "14 400-480V/3": "2.5",
      "14 575-600V/3": "1.9",
      "16 200-240V/1": "8.7",
      "16 200-240V/3": "5.0",
      "16 400-480V/3": "2.5",
      "16 575-600V/3": "1.9",
      "18 200-240V/1": "10.7",
      "18 200-240V/3": "6.2",
      "18 400-480V/3": "3.0",
      "18 575-600V/3": "2.3",
    },
    rcs: {
      "12 200-240V/1": "30",
      "12 200-240V/3": "20",
      "12 400-480V/3": "10",
      "12 575-600V/3": "10",
      "14 200-240V/1": "30",
      "14 200-240V/3": "20",
      "14 400-480V/3": "10",
      "14 575-600V/3": "10",
      "16 200-240V/1": "30",
      "16 200-240V/3": "20",
      "16 400-480V/3": "10",
      "16 575-600V/3": "10",
      "18 200-240V/1": "30",
      "18 200-240V/3": "20",
      "18 400-480V/3": "10",
      "18 575-600V/3": "10",
    },
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control up to 8 fans per controller",
        "Multi-level password protection and SmartSense	",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system",
        "Compatible with BACnet MSTP and BACnet over IP",
        "One adapter enables individual control up to 32 fans",
      ],
    },
    warranty: {
      Mechanical: "3 years",
      Electrical: "3 years",
      Labor: "1 year",
    },
  },
  PF8: {
    template: "LargeFanTemplate",
    name: "Powerfoil 8",
    sizes: [8, 10, 12, 14, 16, 18, 20, 24],
    sizeUnits: "ft",
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/common/industrial-overhead-install-guide.pdf",
    },
    variableVolts: {
      8: ["100-125V/1", "200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      10: [
        "100-125V/1",
        "200-240V/1",
        "200-240V/3",
        "400-480V/3",
        "575-600V/3",
      ],
      12: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      14: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      16: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      18: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      20: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
      24: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
    },
    weights: {
      "8": "135",
      "10": "150",
      "12": "200",
      "14": "209",
      "16": "224",
      "18": "233",
      "20": "242",
      "24": "261",
    },
    rpm: {
      "8": "191",
      "10": "148",
      "12": "135",
      "14": "109",
      "16": "98",
      "18": "86",
      "20": "76",
      "24": "60",
    },
    motor: {
      "8": "1.0",
      "10": "1.0",
      "12": "1.5",
      "14": "1.5",
      "16": "2.0",
      "18": "2.0",
      "20": "2.0",
      "24": "2.0",
    },
    rcs: {
      "8 100-125V/1": "15",
      "8 200-240V/1": "15",
      "8 200-240V/3": "10",
      "8 400-480V/3": "10",
      "8 575-600V/3": "10",
      "10 100-125V/1": "15",
      "10 200-240V/1": "15",
      "10 200-240V/3": "10",
      "10 400-480V/3": "10",
      "10 575-600V/3": "10",
      "12 200-240V/1": "25",
      "12 200-240V/3": "15",
      "12 400-480V/3": "10",
      "12 575-600V/3": "10",
      "14 200-240V/1": "25",
      "14 200-240V/3": "15",
      "14 400-480V/3": "10",
      "14 575-600V/3": "10",
      "16 200-240V/1": "25",
      "16 200-240V/3": "15",
      "16 400-480V/3": "10",
      "16 575-600V/3": "10",
      "18 200-240V/1": "25",
      "18 200-240V/3": "15",
      "18 400-480V/3": "10",
      "18 575-600V/3": "10",
      "20 200-240V/1": "25",
      "20 200-240V/3": "15",
      "20 400-480V/3": "10",
      "20 575-600V/3": "10",
      "24 200-240V/1": "25",
      "24 200-240V/3": "15",
      "24 400-480V/3": "10",
      "24 575-600V/3": "10",
    },
    fla: {
      "8 100-125V/1": "9.0",
      "8 200-240V/1": "8.7",
      "8 200-240V/3": "5.0",
      "8 400-480V/3": "2.4",
      "8 575-600V/3": "1.3",
      "10 100-125V/1": "9.0",
      "10 200-240V/1": "8.7",
      "10 200-240V/3": "5.0",
      "10 400-480V/3": "2.4",
      "10 575-600V/3": "1.3",
      "12 200-240V/1": "12.4",
      "12 200-240V/3": "5.0",
      "12 400-480V/3": "3.5",
      "12 575-600V/3": "2.0",
      "14 200-240V/1": "12.4",
      "14 200-240V/3": "5.0",
      "14 400-480V/3": "3.5",
      "14 575-600V/3": "2.0",
      "16 200-240V/1": "15.8",
      "16 200-240V/3": "8.1",
      "16 400-480V/3": "4.4",
      "16 575-600V/3": "2.7",
      "18 200-240V/1": "15.8",
      "18 200-240V/3": "8.1",
      "18 400-480V/3": "4.4",
      "18 575-600V/3": "2.7",
      "20 200-240V/1": "15.8",
      "20 200-240V/3": "8.1",
      "20 400-480V/3": "4.4",
      "20 575-600V/3": "2.7",
      "24 200-240V/1": "15.8",
      "24 200-240V/3": "8.1",
      "24 400-480V/3": "4.4",
      "24 575-600V/3": "2.7",
    },
    hb: {
      "Direct Mt": "29",
      "No Tube": "40",
      "1'": "(Standard)	47",
      "2'": "59",
      "3'": "71",
      "4'": "83",
      "5'": "95",
      "6'": "107",
      "7'": "119",
      "8'": "131",
      "9'": "143",
      "10'": "155",
      "11'": "167",
      "12'": "179",
      "13'": "191",
      "14'": "203",
      "15'": "215",
    },
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Lower Cover",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
      "AirFence system",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    features: [
      "Intertek/ETL certified to UL 507 and CSA 22.2	",
      "(8) patented aluminum Powerfoil airfoils	",
      "Powerfoil winglets eliminate wind noise	",
      "Fire relay and multiple safety redundancies	",
      "Airfoil Restraint System (AFRS) Standard on 20'-24'	",
    ],
    additions: [
      '10" L-Bracket',
      '8" L-Bracket',
      "BACnet Adapter",
      "BAFWorks",
      "Big Fan Pkg",
      "Dewtect",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Onboard VFD",
      "Security Kit for Wall Control",
      "SmartSense",
      "AFRS (Airfoil Restraint)",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
    ],
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control up to 8 fans per controller",
        "Multi-level password protection and SmartSense	",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system",
        "Compatible with BACnet MSTP and BACnet over IP",
        "One adapter enables individual control up to 32 fans",
      ],
    },
  },
  PFX4: {
    template: "LargeFanTemplate",
    name: "Powerfoil X4",
    sizes: [12, 14, 16, 18, 20, 24, 30],
    sizeUnits: "ft",
    volts: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
    maxSound: "55",
    weights: { 12: 245, 14: 254, 16: 264, 18: 281, 20: 290, 24: 347, 30: 374 },
    motor: { 12: 1.9, 14: 1.9, 16: 1.9, 18: 2.5, 20: 2.5, 24: 2.5, 30: 2.5 },
    rpm: { 12: 130, 14: 116, 16: 96, 18: 90, 20: 76, 24: 62, 30: 46 },
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Motor",
      "Gear Box",
      "Lower Cover",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
      "AirFence system",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    additions: [
      "AFRS (Airfoil Restraint)",
      '10" L-Bracket',
      '8" L-Bracket',
      "Powerfoil LED",
      "BAFWorks",
      "Big Fan Pkg",
      "Texas Edition",
      "Dewtect",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Off Board VFD",
      "Security Kit for Wall Control",
      "Occupancy Sensor",
      "Standard Camera",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/powerfoilx/pfx4/powerfoilx4-install-guide.pdf",
    },
    features: [
      "CE marked and Intertek/ETL certified",
      "Built to UL 507 and CSA 22.2",
      "(8) patented aluminum Powerfoil airfoils",
      "IP56 motor and NitroSeal Drive gearbox",
      "Onboard VFD with built-in heatsink",
      "Fire relay and multiple safety redundancies",
      "Airfoil restraint system (AFRS) standard on 20'-30'",
    ],
    hb: {
      "Direct Mt": "29",
      "No Tube": "39",
      "1' (Standard)": "47",
      "2'": "59",
      "3'": "71",
      "4'": "83",
      "5'": "95",
      "6'": "107",
      "7'": "119",
      "8'": "131",
      "9'": "143",
      "10'": "155",
      "11'": "167",
      "12'": "178",
      "13'": "191",
      "14'": "203",
      "15'": "215",
    },
    fla: {
      "12 200-240V/1": "10",
      "12 200-240V/3": "4.8",
      "12 400-480V/3": "3.1",
      "12 575-600V/3": "2.4",
      "14 200-240V/1": "12.4 ",
      "14 200-240V/3": "6.0",
      "14 400-480V/3": "3.9",
      "14 575-600V/3": "2.6",
      "16 200-240V/1": "12",
      "16 200-240V/3": "6.3",
      "16 400-480V/3": "3.8",
      "16 575-600V/3": "2.4",
      "18 200-240V/1": "15.3",
      "18 200-240V/3": "7.8",
      "18 400-480V/3": "4.7",
      "18 575-600V/3": "3.6",
      "20 200-240V/1": "14",
      "20 200-240V/3": "7.2",
      "20 400-480V/3": "4.3",
      "20 575-600V/3": "3.2",
      "24 200-240V/1": "15.3",
      "24 200-240V/3": "7.9",
      "24 400-480V/3": "4.6",
      "24 575-600V/3": "3.5",
      "30 200-240V/1": "15.2",
      "30 200-240V/3": "8.2",
      "30 400-480V/3": "4.6",
      "30 575-600V/3": "3.6",
    },
    rcs: {
      "12 200-240V/1": "15",
      "12 200-240V/3": "10",
      "12 400-480V/3": "10",
      "12 575-600V/3": "10",
      "14 200-240V/1": "20",
      "14 200-240V/3": "10",
      "14 400-480V/3": "10",
      "14 575-600V/3": "10",
      "16 200-240V/1": "15",
      "16 200-240V/3": "10",
      "16 400-480V/3": "10",
      "16 575-600V/3": "10",
      "18 200-240V/1": "20",
      "18 200-240V/3": "10",
      "18 400-480V/3": "10",
      "18 575-600V/3": "10",
      "20 200-240V/1": "20",
      "20 200-240V/3": "10",
      "20 400-480V/3": "10",
      "20 575-600V/3": "10",
      "24 200-240V/1": "20",
      "24 200-240V/3": "10",
      "24 400-480V/3": "10",
      "24 575-600V/3": "10",
      "30 200-240V/1": "20",
      "30 200-240V/3": "15",
      "30 400-480V/3": "10",
      "30 575-600V/3": "10",
    },
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control up to 8 fans per controller",
        "Multi-level password protection and SmartSense	",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system",
        "Compatible with BACnet MSTP and BACnet over IP",
        "One adapter enables individual control up to 32 fans",
      ],
    },
    warranty: {
      Mechanical: "15 years",
      Electrical: "15 years (with factory install), 7 years",
      Labor: "1 year",
    },
  },
  "PFX4 Plus": {
    template: "LargeFanTemplate",
    name: "Powerfoil X4 Plus",
    sizes: [12, 14, 16, 18, 20, 24],
    sizeUnits: "ft",
    volts: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
    maxSound: "55",
    weights: { 12: 245, 14: 254, 16: 264, 18: 281, 20: 290, 24: 347 },
    motor: { 12: 1.9, 14: 1.9, 16: 1.9, 18: 2.5, 20: 2.5, 24: 2.5 },
    rpm: { 12: 130, 14: 117, 16: 97, 18: 91, 20: 76, 24: 62 },
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Motor",
      "Gear Box",
      "Lower Cover",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
      "AirFence system",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    additions: [
      "AFRS (Airfoil Restraint)",
      '10" L-Bracket',
      '8" L-Bracket',
      "Powerfoil LED",
      "BAFWorks",
      "Big Fan Pkg",
      "Texas Edition",
      "Dewtect",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Off Board VFD",
      "Security Kit for Wall Control",
      "Occupancy Sensor",
      "Standard Camera",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/powerfoilx/pfx4/powerfoilx4-install-guide.pdf",
    },
    features: [
      "CE marked and Intertek/ETL certified",
      "Built to UL 507 and CSA 22.2",
      "(8) patented aluminum Powerfoil airfoils",
      "IP56 motor and NitroSeal Drive gearbox",
      "Onboard VFD with built-in heatsink",
      "Fire relay and multiple safety redundancies",
      "Airfoil restraint system (AFRS) standard on 20'-30'",
    ],
    hb: {
      "Direct Mt": "28",
      "No Tube": "39",
      "1' (Standard)": "46",
      "2'": "58",
      "3'": "70",
      "4'": "82",
      "5'": "94",
      "6'": "106",
      "7'": "118",
      "8'": "130",
      "9'": "142",
      "10'": "154",
      "11'": "166",
      "12'": "178",
      "13'": "190",
      "14'": "202",
      "15'": "214",
    },
    fla: {
      "12 200-240V/1": "9.7 ",
      "12 200-240V/3": "4.5 ",
      "12 400-480V/3": "2.9 ",
      "12 575-600V/3": "2.2 ",
      "14 200-240V/1": "11.6",
      "14 200-240V/3": "5.7 ",
      "14 400-480V/3": "3.6 ",
      "14 575-600V/3": "2.4 ",
      "16 200-240V/1": "11.5",
      "16 200-240V/3": "6.0 ",
      "16 400-480V/3": "3.6 ",
      "16 575-600V/3": "2.3 ",
      "18 200-240V/1": "13.9",
      "18 200-240V/3": "7.3 ",
      "18 400-480V/3": "4.3 ",
      "18 575-600V/3": "3.4 ",
      "20 200-240V/1": "13.2",
      "20 200-240V/3": "6.8 ",
      "20 400-480V/3": "3.9 ",
      "20 575-600V/3": "3.0 ",
      "24 200-240V/1": "14.7",
      "24 200-240V/3": "7.7 ",
      "24 400-480V/3": "4.5 ",
      "24 575-600V/3": "3.4 ",
    },
    rcs: {
      "12 200-240V/1": "15",
      "12 200-240V/3": "10",
      "12 400-480V/3": "10",
      "12 575-600V/3": "10",
      "14 200-240V/1": "15",
      "14 200-240V/3": "10",
      "14 400-480V/3": "10",
      "14 575-600V/3": "10",
      "16 200-240V/1": "15",
      "16 200-240V/3": "10",
      "16 400-480V/3": "10",
      "16 575-600V/3": "10",
      "18 200-240V/1": "20",
      "18 200-240V/3": "10",
      "18 400-480V/3": "10",
      "18 575-600V/3": "10",
      "20 200-240V/1": "20",
      "20 200-240V/3": "10",
      "20 400-480V/3": "10",
      "20 575-600V/3": "10",
      "24 200-240V/1": "20",
      "24 200-240V/3": "10",
      "24 400-480V/3": "10",
      "24 575-600V/3": "10",
    },
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control up to 8 fans per controller",
        "Multi-level password protection and SmartSense	",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system",
        "Compatible with BACnet MSTP and BACnet over IP",
        "One adapter enables individual control up to 32 fans",
      ],
    },
    warranty: {
      Mechanical: "15 years",
      Electrical: "15 years (with factory install), 7 years",
      Labor: "1 year",
    },
  },
  "PF8 Plus": {
    template: "LargeFanTemplate",
    name: "Powerfoil 8 Plus",
    sizes: [10, 12, 14, 16, 18, 20, 24],
    sizeUnits: "ft",
    volts: ["200-240V/1", "200-240V/3", "400-480V/3", "575-600V/3"],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/common/industrial-overhead-install-guide.pdf",
    },
    weights: {
      "10": "194",
      "12": "204",
      "14": "219",
      "16": "228",
      "18": "237",
      "20": "247",
      "24": "256",
    },
    rpm: {
      "10": "135",
      "12": "109",
      "14": "98",
      "16": "86",
      "18": "76",
      "20": "68",
      "24": "60",
    },
    motor: {
      "10": "1.5",
      "12": "1.5",
      "14": "2.0",
      "16": "2.0",
      "18": "2.0",
      "20": "2.0",
      "24": "2.0",
    },
    rcs: {
      "10 200-240V/1": "25",
      "10 200-240V/3": "15",
      "10 400-480V/3": "10",
      "10 575-600V/3": "10",
      "12 200-240V/1": "25",
      "12 200-240V/3": "15",
      "12 400-480V/3": "10",
      "12 575-600V/3": "10",
      "14 200-240V/1": "25",
      "14 200-240V/3": "15",
      "14 400-480V/3": "10",
      "14 575-600V/3": "10",
      "16 200-240V/1": "25",
      "16 200-240V/3": "15",
      "16 400-480V/3": "10",
      "16 575-600V/3": "10",
      "18 200-240V/1": "25",
      "18 200-240V/3": "15",
      "18 400-480V/3": "10",
      "18 575-600V/3": "10",
      "20 200-240V/1": "25",
      "20 200-240V/3": "15",
      "20 400-480V/3": "10",
      "20 575-600V/3": "10",
      "24 200-240V/1": "25",
      "24 200-240V/3": "15",
      "24 400-480V/3": "10",
      "24 575-600V/3": "10",
    },
    fla: {
      "10 200-240V/1": "8.8",
      "10 200-240V/3": "5.0",
      "10 400-480V/3": "2.6",
      "10 575-600V/3": "2.0",
      "12 200-240V/1": "12.4",
      "12 200-240V/3": "5.0",
      "12 400-480V/3": "3.5",
      "12 575-600V/3": "2.0",
      "14 200-240V/1": "12.4",
      "14 200-240V/3": "7.2",
      "14 400-480V/3": "3.5",
      "14 575-600V/3": "2.7",
      "16 200-240V/1": "15.8",
      "16 200-240V/3": "7.2",
      "16 400-480V/3": "4.4",
      "16 575-600V/3": "2.7",
      "18 200-240V/1": "15.8",
      "18 200-240V/3": "7.2",
      "18 400-480V/3": "4.4",
      "18 575-600V/3": "2.7",
      "20 200-240V/1": "15.8",
      "20 200-240V/3": "7.2",
      "20 400-480V/3": "4.4",
      "20 575-600V/3": "2.7",
      "24 200-240V/1": "15.8",
      "24 200-240V/3": "7.2",
      "24 400-480V/3": "4.4",
      "24 575-600V/3": "2.7",
    },
    hb: {
      "Direct Mt": "28",
      "No Tube": "38",
      "1'(Standard)": "45",
      "2'": "57",
      "3'": "69",
      "4'": "81",
      "5'": "93",
      "6'": "105",
      "7'": "117",
      "8'": "129",
      "9'": "141",
      "10'": "153",
      "11'": "165",
      "12'": "177",
      "13'": "189",
      "14'": "201",
      "15'": "213",
    },
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    parts: [
      "Standard",
      "Monochrome",
      "Mounting Frame/Post",
      "Lower Cover",
      "Extension Tube",
      "Aluminum Foils",
      "Winglets",
      "AirFence system",
    ],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    features: [
      "Intertek/ETL certified to UL 507 and CSA 22.2	",
      "(8) patented aluminum Powerfoil airfoils	",
      "Powerfoil winglets eliminate wind noise	",
      "Fire relay and multiple safety redundancies	",
      "Airfoil Restraint System (AFRS) Standard on 20'-24'	",
    ],
    additions: [
      '10" L-Bracket',
      '8" L-Bracket',
      "BACnet Adapter",
      "BAFWorks",
      "Big Fan Pkg",
      "Dewtect",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Onboard VFD",
      "Security Kit for Wall Control",
      "SmartSense",
      "AFRS (Airfoil Restraint)",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
    ],
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control up to 8 fans per controller",
        "Multi-level password protection and SmartSense	",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system",
        "Compatible with BACnet MSTP and BACnet over IP",
        "One adapter enables individual control up to 32 fans",
      ],
    },
  },
  Essence: {
    template: "LargeFanTemplate",
    name: "Essence",
    sizes: [8, 10, 12, 14],
    sizeUnits: "ft",
    volts: ["110-125V/1", "200-240V/1"],
    maxSound: "<35",
    maxAirflow: { 8: 34332, 10: 42675, 12: 49345, 14: 54200 },
    weights: { 8: 75, 10: 81, 12: 88, 14: 96 },
    rpm: { 8: 158, 10: 107, 12: 76, 14: 56 },
    mounts: ["STD Upper Mount"],
    standardColorOpts: ["Silver w/ White Trim", "Silver w/ Black Trim"],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/essence/essence-install-guide-en-fr-es.pdf",
    },
    parts: [
      "Standard",
      "Monochrome",
      "Mounts",
      "Extension Tube",
      "Lower Cover",
      "Aluminum Foils",
      "Winglets",
    ],
    appearanceOpts: [
      "Custom Color Option",
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    rcs: {
      "8 110-125V/1": "10",
      "8 200-240V/1": "10",
      "10 110-125V/1": "10",
      "10 200-240V/1": "10",
      "12 110-125V/1": "10",
      "12 200-240V/1": "10",
      "14 110-125V/1": "10",
      "14 200-240V/1": "10",
    },
    fla: {
      "8 110-125V/1": "5.7",
      "8 200-240V/1": "3.6",
      "10 110-125V/1": "5.3",
      "10 200-240V/1": "3.4",
      "12 110-125V/1": "4.3",
      "12 200-240V/1": "2.7",
      "14 110-125V/1": "3.8",
      "14 200-240V/1": "2.4",
    },
    hb: {
      Short: "20",
      "2' (Standard)": "31.8",
      "3'": "43.8",
      "4'": "55.8",
      "5'": "67.8",
      "6'": "79.8",
      "7'": "91.8",
      "8'": "103.8",
      "9'": "115.8",
      "10'": "127.8",
      "11'": "139.8",
      "12'": "151.8",
      "13'": "163.8",
      "14'": "175.8",
      "15'": "187.8",
    },
    additions: [
      "Large I-Beam Install Kit",
      "Small I-Beam Install Kit",
      "Z-Purlin Install Kit",
      "Wood Frame Install Kit",
      "L-Bracket Install Kit",
      "LED Kit (3K Kelvin)",
      "LED Kit (4k Kelvin)",
      "Gripple Set",
      "SmartSense",
      "Escutcheon",
      "Big Fan Package",
    ],
    features: [
      "ETL certified to UL 507 and CSA 22.2 No. 113	",
      "Air performance ratings AMCA certified	",
      "(8) Anodized aluminum Mini-Ellipto airfoils	",
      "Gearless direct drive motor	",
      "Onboard programmed digital inverter controller	",
      "Suitable for indoor or outdoor spaces	",
    ],
    controllerFeatures: {
      "Wired Wall (Standard)": [
        "Standard option for individual fan control	",
        "Variable speed control	",
      ],
      "Wireless Wall": [
        "Remote control up to 175 feet away from the fan",
        "Single remote can control up to 10 fans",
      ],
      BAFCon: [
        "Multi-fan control of up to 8 fans per BAFCon controller	",
        "Multi-level password protection and SmartSense tech	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system	",
        "One adapter enables individual control of up to 32 fans	",
      ],
    },
    warranty: {
      Mechanical: "10 year",
      Electrical: "10 year (with factory install), 5 years",
      Labor: "1 year",
    },
  },
  "Essence with UV-C": {
    template: "LargeFanTemplate",
    name: "Essence with UV-C",
    sizes: [8, 10, 12, 14],
    sizeUnits: "ft",
    volts: ["110-125V/1", "200-240V/1"],
    maxSound: "<35",
    maxAirflow: { 8: 34332, 10: 42675, 12: 49345, 14: 54200 },
    weights: { 8: 75, 10: 81, 12: 88, 14: 96 },
    rpm: { 8: 158, 10: 107, 12: 76, 14: 56 },
    mounts: ["STD Upper Mount"],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/essence/essence-install-guide-en-fr-es.pdf",
      "UV-C Install Guide":
        "https://www.bigassfans.com/docs/clean-air/essence-uv-c-install-guide.pdf",
    },
    parts: [
      "Standard",
      "Monochrome",
      "Mounts",
      "Extension Tube",
      "Lower Cover",
      "Aluminum Foils",
      "Winglets",
    ],
    appearanceOpts: [
      "Silver w/ White Trim",
      "Silver w/ Black Trim",
      "Custom Color Option",
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    standardColorOpts: ["Powerfoil Yellow", "Alpine White"],
    rcs: {
      "8 110-125V/1": "10",
      "8 200-240V/1": "10",
      "10 110-125V/1": "10",
      "10 200-240V/1": "10",
      "12 110-125V/1": "10",
      "12 200-240V/1": "10",
      "14 110-125V/1": "10",
      "14 200-240V/1": "10",
    },
    fla: {
      "8 110-125V/1": "5.7",
      "8 200-240V/1": "3.6",
      "10 110-125V/1": "5.3",
      "10 200-240V/1": "3.4",
      "12 110-125V/1": "4.3",
      "12 200-240V/1": "2.7",
      "14 110-125V/1": "3.8",
      "14 200-240V/1": "2.4",
    },
    hb: {
      Short: "20",
      "2'(Standard)": "31.8",
      "3'": "43.8",
      "4'": "55.8",
      "5'": "67.8",
      "6'": "79.8",
      "7'": "91.8",
      "8'": "103.8",
      "9'": "115.8",
      "10'": "127.8",
      "11'": "139.8",
      "12'": "151.8",
      "13'": "163.8",
      "14'": "175.8",
      "15'": "187.8",
    },
    additions: [
      "Large I-Beam Installation Kit",
      "Small I-Beam Installation Kit",
      "Z-Purlin Installation Kit",
      "Wood Frame Installation Kit",
      "L-Bracket Installation Kit",
      "Essence LED Kit (3000 Kelvin)",
      "Essence LED Kit (4000 Kelvin)",
      "Gripple Set",
      "SmartSense",
      "Escutcheon",
      "Big Fan Package",
    ],
    features: [
      "Custom aluminum heatsink and LED board	",
      "275 nm UV-C diodes	",
      "Optional LED downlight available	",
      "(8) Anodized aluminum Mini-Ellipto airfoils	",
      "Gearless direct drive motor	",
      "Onboard programmed digital inverter controller	",
      "Indoor use only	",
    ],
    controllerFeatures: {
      "Wired Wall (Standard)": [
        "Standard option for individual fan control	",
        "Variable speed control	",
      ],
      "Wireless Wall": [
        "Group control of up to 3 fans per controller	",
        "Variable speed control	",
      ],
      BAFCon: [
        "Multi-fan control of up to 8 fans per BAFCon controller	",
        "Multi-level password protection and SmartSense tech	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system	",
        "One adapter enables individual control of up to 32 fans	",
      ],
    },
    warranty: {
      Mechanical: "10 year",
      Electrical: "10 year (with factory install), 5 years",
      Labor: "1 year",
    },
  },
  PFB: {
    template: "LargeFanTemplate",
    name: "Powerfoil Breeze",
    sizes: [16, 18, 20, 24],
    sizeUnits: "ft",
    volts: ["200-277V/1", "200-277V/3", "380-480V/3"],
    maxSound: "---",
    weights: { 16: 246, 18: 254, 20: 261, 24: 276 },
    motor: { 16: 1.5, 18: 2.0, 20: 2.0, 24: 2.0 },
    rpm: { 16: 81, 18: 74, 20: 64, 24: 48 },
    parts: [
      "Standard",
      "Monochrome",
      "Mounts",
      "Extension Tube",
      "Lower Cover",
      "Side Cover",
      "Aluminum Foils",
      "Winglets",
    ],
    appearanceOpts: [
      "Custom Color Option",
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    standardColorOpts: ["White w/ Silver Trim", "Silver w/ Black Trim"],
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    additions: [
      "AFRS (Airfoil Restraint)",
      '10" L-Bracket',
      '8" L-Bracket',
      "BACnet Adapter",
      "BAFWorks",
      "Dewtect",
      "Occupancy Sensor",
      "Stationary Lower Cover",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/powerfoilb/powerfoilb-install-guide.pdf",
    },
    features: [
      "ETL certified to UL 507 and CSA 22.2 No. 113	",
      "(6) Anodized aluminum airfoils	",
      "Rated IP66	",
      "Neodymium magnet direct-drive motor	",
      "Vented cover for motor and drive	",
      "Suitable for indoor or covered outdoor spaces	",
    ],
    hb: {
      "Direct Mt": "30",
      "No Tube": "40",
      "1' (Standard)": "48",
      "2'": "60",
      "3'": "72",
      "4'": "84",
      "5'": "96",
      "6'": "108",
      "7'": "120",
      "8'": "132",
      "9'": "144",
      "10'": "156",
      "11'": "168",
      "12'": "180",
      "13'": "192",
      "14'": "204",
      "15'": "216",
    },
    rcs: {
      "16 200-277V/1": "15",
      "16 200-277V/3": "15",
      "16 380-480V/3": "10",
      "18 200-277V/1": "15",
      "18 200-277V/3": "15",
      "18 380-480V/3": "10",
      "20 200-277V/1": "15",
      "20 200-277V/3": "15",
      "20 380-480V/3": "10",
      "24 200-277V/1": "15",
      "24 200-277V/3": "15",
      "24 380-480V/3": "10",
    },
    fla: {
      "16 200-277V/1": "7.55",
      "16 200-277V/3": "7.55",
      "16 380-480V/3": "3.39",
      "18 200-277V/1": "8.23",
      "18 200-277V/3": "8.23",
      "18 380-480V/3": "3.76",
      "20 200-277V/1": "8.8",
      "20 200-277V/3": "8.8",
      "20 380-480V/3": "3.71",
      "24 200-277V/1": "7.93",
      "24 200-277V/3": "7.93",
      "24 380-480V/3": "3.39",
    },
    controllerFeatures: {
      "BAFCon (Advanced)": [
        "Individual/group control of up to 8 fans per controller",
        "Multi-level password protection and SmartSense ",
        "Bldg automation/fire suppression system integration",
      ],
      "WSC (Low Cost Alt)": [
        "Individual fan control with password protection	",
        "On/Off and variable speed adjustment	",
        "Durable UV-resistent housing	",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system	",
        "Compatible with BACnet MSTP and BACnet over IP	",
        "One adapter enables individual control of up to 32 fans	",
      ],
    },
    warranty: {
      Mechanical: "10 year",
      Electrical: "10 year (with factory install), 5 years",
      Labor: "1 year",
    },
  },
  // directional fans
  Sidekick: {
    template: "LargeFanTemplate",
    name: "Sidekick",
    maxSound: "73.3",
    width: 55,
    sizes: [4],
    sizeUnits: "ft",
    volts: ["110-125V/1", "200-240V/1"],
    depth: 27,
    height: 59.5,
    weights: { 4: 180 },
    rpm: { 4: 616 },
    maxAirflow: { 4: 15000 },
    maxAmps: {
      "4 110-125V/1": 7.5,
      "4 200-240V/1": 3.15,
    },
    maxWatts: {
      "4 110-125V/1": 615,
      "4 200-240V/1": 615,
    },
    rcs: {
      "4 110-125V/1": 15,
      "4 200-240V/1": 10,
    },
    parts: ["Standard", "Monochrome", "Cage", "Air Foils"],
    standardColorOpts: ["Black and Yellow"],
    appearanceOpts: [
      "Black and Yellow",
      "Powerfoil Yellow",
      "Alpine White",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Valor Red",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    features: [
      "Steel frame with OSHA-compliant HDPE shroud",
      "Four lockable swivel casters",
      "6 blades made from glass-filled nylon",
      "Direct drive permanent-magnet motor",
      "Arrives pre-assembled for immediate use",
      "Standard indoor/outdoor wheels",
      "Variable speed control with adjustable dial",
      "Power cord and plug fits standard power outlets",
      "Indoor and outdoor ready with an IPX5 rating",
    ],
    warranty: {
      "Main Fan Unit": "3 Years",
    },
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/sidekick/sidekick-install-guide.pdf",
    },
  },
  "Pivot 2.0": {
    template: "LargeFanTemplate",
    name: "Pivot 2.0",
    sizes: [76],
    sizeUnits: "in",
    volts: ["100-125V/1", "200-240V/1"],
    motor: { 76: 0.5 },
    cageDiameter: { 76: 76 },
    cageDepth: 14,
    depth: 51,
    weights: { 76: 114 },
    rpm: { 76: 300 },
    mounts: [
      "Large I-Beam Mnt",
      "Small I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
      "Column",
    ],
    additions: [],
    warranty: {
      "Main Fan Unit": "5 Years",
    },
    maxAmps: {
      "76 100-125V/1": 7.8,
      "76 200-240V/1": 5.6,
    },
    maxWatts: {
      "76 100-125V/1": 460,
      "76 200-240V/1": 460,
    },
    features: [
      "Patented airfoil & winglet design",
      "Precision-molded hub for smooth operation",
      "Durable steel cage and industrial-grade parts",
      "Pivot joints allow easy head-angle adjustments",
      "UL 507 and CSA 22.2 No. 113 certified",
    ],
    controllerFeatures: {
      BAFCon: [
        "Multi-fan control of up to 8 fans per BAFCon controller",
        "Multi-level password protection and SmartSense tech",
      ],
      "Wired Wall(Standard)": [
        "Infinitely variable speed control",
        "Mounts to single-gang junction box or to wall",
      ],
      "BACnet Adapter": [
        "Connects fans to a BACnet automation system",
        "One adapter enables individual control of up to 32 fans",
      ],
    },
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/pivot2/pivot2-ceiling-install-guide.pdf",
    },
    appearencePackage: {
      Cage: "Silver",
      Airfoils: "Silver",
      Winglets: "Yellow",
    },
  },
  "Yellow Jacket": {
    // weight, height, and depth are a function of mount
    template: "LargeFanTemplate",
    name: "Yellow Jacket",
    maxSound: 80.2,
    width: 44,
    sizes: [30],
    sizeUnits: "in",
    volts: ["100-125V/1", "200-240V/1"],
    motor: { 30: 0.5 },
    directionalWeight: {
      Standard: "105",
      Portable: "142",
      'Portable, 18" XT': "149",
      'Portable, 36" XT': "156",
      "Wall/Column Mnt": "130",
      "I-Beam Mnt": "105",
      "Chain Mnt": "105",
      "Yoke Mnt": "105",
    },
    directionalHeight: {
      Standard: "42",
      Portable: "45.5",
      'Portable, 18" XT': "63.5",
      'Portable, 36" XT': "81.5",
      "Wall/Column Mnt": "70",
      "I-Beam Mnt": "42",
      "Chain Mnt": "42",
      "Yoke Mnt": "42",
    },
    directionalDepth: {
      Standard: "21",
      Portable: "28",
      'Portable, 18" XT': "28",
      'Portable, 36" XT': "28",
      "Wall/Column Mnt": "45",
      "I-Beam Mnt": "21",
      "Chain Mnt": "21",
      "Yoke Mnt": "21",
    },
    rpm: { 30: 1317 },
    rcs: {
      "30 100-125V/1": 20,
      "30 200-240V/1": 15,
    },
    maxAmps: {
      "30 100-125V/1": 10.5,
      "30 200-240V/1": 6.1,
    },
    maxWatts: {
      "30 100-125V/1": 600,
      "30 200-240V/1": 600,
    },
    directionalMounts: [
      "Standard",
      "Portable",
      'Portable, 18" XT',
      'Portable, 36" XT',
      "Wall/Column Mnt",
      "I-Beam Mnt",
      "Chain Mnt",
      "Yoke Mnt",
    ],
    yokeET: [
      "1'",
      "2'",
      "3'",
      "4'",
      "5'",
      "6'",
      "7'",
      "8'",
      "9'",
      "10'",
      "11'",
      "12'",
      "13'",
      "14'",
      "15'",
    ],
    features: [
      "UL listed, IP45 rated motor",
      "HDPE housing",
      "OSHA-compliant guard",
      "10' power cord",
      "Locking position mechanism",
      "3 polyamide nylon, progressive pitch blades",
    ],
    additions: ["Oscillator"],
    parts: ["Standard", "Monochrome", "Cage", "Mounting Frame/Post"],
    standardColorOpts: ["Powerfoil Yellow"],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Alpine White",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Valor Red",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/yellowjacket/yellow-jacket-install-guide-en.pdf",
    },
    controllerFeatures: {
      BAFCon: [
        "Multi-fan control of up to 8 fans per BAFCon controller",
        "Multi-level password protection and SmartSense tech",
      ],
      "Standard Control": [
        "On/Off and variable speed control",
        "Remotely mounted or onboard",
      ],
    },
  },
  AirGo: {
    template: "LargeFanTemplate",
    name: "AirGo 2.0",
    maxSound: 64,
    height: 100.3,
    width: 96.8,
    depth: 32.3,
    sizes: [8],
    sizeUnits: "ft",
    volts: ["110-125/1", "200-240/1"],
    rpm: { 8: 180 },
    weights: { 8: 425 },
    rcs: {
      "8 110-125/1": 15,
      "8 200-240/1": 10,
    },
    maxAmps: {
      "8 110-125/1": 7.7,
      "8 200-240/1": 5.6,
    },
    maxWatts: {
      "8 110-125/1": 481,
      "8 200-240/1": 469,
    },
    additions: ["All Terrain (110-125V ONLY)"],
    features: [
      "Plugs into standard power supplies",
      "Operates at only 64 dBA at maximum speed",
      "Creates airflow across 1/3 of a football field",
      "Perfect for aisleways & compact work areas",
      "Heavy gauge steel frame and cage",
      "Wet-rated for indoor/outdoor use",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/airgo/airgo2-install-guide.pdf",
    },
    parts: ["Monochrome", "Cage", "AirFoils", "Winglets", "Standard"],
    standardColorOpts: ["Powerfoil Yellow", "Alpine White"],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Alpine White",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Valor Red",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    warranty: {
      "Main Fan Unit": "5 Years",
    },
  },
  AirEye: {
    template: "LargeFanTemplate",
    name: "AirEye",
    maxSound: "69",
    sizes: [20, 24, 30, 36],
    sizeUnits: "in",
    volts: ["100-120V/1", "200-240V/1"],
    variableRpm: {
      "20 100-120V/1": 1220,
      "20 200-240V/1": 1296,
      "24 100-120V/1": 1290,
      "24 200-240V/1": 1317,
      "30 100-120V/1": 1280,
      "30 200-240V/1": 1215,
      "36 100-120V/1": 950,
      "36 200-240V/1": 950,
    },
    motor: { 20: "1/3", 24: "1/3", 30: "1/3", 36: "2/3" },
    weights: { 20: 32, 24: 38, 30: 45, 36: 67 },
    cageDiameter: {
      20: 25.8,
      24: 32,
      30: 38.2,
      36: 44,
    },
    bladeDiameter: {
      20: 20.5,
      24: 24.5,
      30: 30.5,
      36: 36.5,
    },
    maxAmps: {
      "20 100-120V/1": 4.6,
      "20 200-240V/1": 3.1,
      "24 100-120V/1": 4.8,
      "24 200-240V/1": 3.0,
      "30 100-120V/1": 4.9,
      "30 200-240V/1": 2.9,
      "36 100-120V/1": 9.7,
      "36 200-240V/1": 5.5,
    },
    maxWatts: {
      "20 100-120V/1": 326,
      "20 200-240V/1": 389,
      "24 100-120V/1": 334,
      "24 200-240V/1": 393,
      "30 100-120V/1": 345,
      "30 200-240V/1": 379,
      "36 100-120V/1": 711,
      "36 200-240V/1": 669,
    },
    directionalMounts: [
      "I-Beam Mount",
      "C-Channel Mount",
      "Wall Mount",
      "Pedestal Kit",
      "Swivel Mount",
    ],
    features: [
      "OSHA-compliant steel wire cage",
      "Precision-balanced, galvalume steel blades",
      "18 ft cord plugs into standard wall outlet",
      "Modular assembly",
    ],
    additions: [
      "Timer Control",
      "Midnight Black",
      "AEOS (Occupancy Sensor)",
      "3 ft Drop Cord Extension",
      "6 ft Drop Cord Extension",
      "12 ft Drop Cord Extension",
    ],
    parts: ["Monochrome", "Cage", "Motor", "Pedestal/Mount"],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Valor Red",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Alpine White",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    controllerFeatures: {
      BAFCon: [
        "Multi-fan control of up to 8 fans per BAFCon controller",
        "Building automation system integration",
      ],
      "Standard (VSC)": [
        "Infinitely variable speed control ",
        "IP55 rated controller",
      ],
    },
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/aireye/aireye-install-guide.pdf",
    },
    warranty: {
      "Main Fan Unit": "5 Years",
    },
  },
  "Black Jack": {
    template: "LargeFanTemplate",
    name: "Black Jack",
    maxSound: 63.5,
    height: 78,
    width: 84,
    depth: 29,
    sizes: [7],
    sizeUnits: "ft",
    volts: ["110-125/1", "200-240/1"],
    weights: { 7: 310 },
    rpm: { 7: 291 },
    rcs: {
      "7 110-125/1": 15,
      "7 200-240/1": 10,
    },
    maxAmps: {
      "7 110-125/1": 7.8,
      "7 200-240/1": 5.6,
    },
    maxWatts: {
      "7 110-125/1": 473,
      "7 200-240/1": 466,
    },
    features: [
      "6.5-ft frame fits through most interior doors",
      "25-ft cord (10-ft available)",
      "Direct-drive motor only draws 7.5 amps",
      "Creates airflow across 1/3 of a football field",
      "Arrives pre-assembled on special roll-off pallet",
      "Standard indoor/outdoor wheels",
      "Variable speed control",
      "Steel frame and cage",
      "Wet-rated for indoor/outdoor use",
    ],
    parts: ["Monochrome", "Cage", "Airfoils", "Winglets", "Standard"],
    standardColorOpts: ["Stealth Black"],
    appearanceOpts: [
      "Powerfoil Yellow",
      "Alpine White",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Valor Red",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/blackjack/black-jack-install-guide.pdf",
    },
    warranty: {
      "Main Fan Unit": "5 Years",
    },
  },
  ShopFan: {
    template: "LargeFanTemplate",
    name: "ShopFan",
    sizes: [14],
    sizeUnits: "ft",
    volts: ["100-125V/1"],
    maxSound: "55",
    weights: { 14: 145 },
    motor: { 14: 1.0 },
    rpm: { 14: 101 },
    mounts: [
      "Small I-Beam Mnt (Standard)",
      "Large I-Beam Mnt",
      "Angle Iron/Bar Jst",
      "Z-Purlin",
      "Special - HW",
      "Special - Concrete",
    ],
    additions: [
      '10" L-Bracket',
      '8" L-Bracket',
      "Big Fan Pkg",
      "EMI Filter",
      "Line Reactor",
      "Load Reactor",
      "NEMA 4X Enclosure",
      "Security Kit for Wall Control",
      "Wind Clik",
      "Z-Purlin Assembly Kit",
      "Gripple Set",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/common/industrial-overhead-install-guide.pdf",
    },
    features: [
      "Intertek/ETL certified to UL 507 and CSA 22.2	",
      "Machine-cut precision components	",
      "(6) patented aluminum Powerfoil airfoils	",
      "NEMA 4X Variable Speed Drive	",
      "Fire relay and multiple safety redundancies	",
    ],
    hb: {
      "3": 71,
    },
    rcs: {
      "14 100-125V/1": 20,
    },
    fla: {
      "14 100-125V/1": "11.0",
    },
    warranty: {
      Mechanical: "1 year",
      Electrical: "1 year",
    },
  },
  "Sweat Bee": {
    template: "SweatBeeTemplate",
    name: "Sweat Bee",
    sizes: [18, 30],
    sizeUnits: "in",
    volts: {
      "18": ["100-125V/1"],
      "30": ["200-250V/3", "400-480V/3"],
    },
    maxSound: {
      "18": "81",
      "30": "87",
    },
    maxAmps: {
      "100-125V/1": "6",
      "200-250V/3": "3.7",
      "400-480V/3": "1.9",
    },
    rcs: {
      "100-125V/1": "20",
      "200-250V/3": "15",
      "400-480V/3": "20",
    },
    motor: { "18": "1/3", "30": "1" },
    rpm: { "18": 1725, "30": 1160 },
    weights: {
      "18100-125V/1Swivel": "94",
      "18200-250V/1Swivel": "94",
      "18100-125V/1Wall/Column Mnt": "87",
      "18100-125V/1Angle Iron": "78",
      "18100-125V/1Chain Mnt": "78",
      "18100-125V/1Yoke Mnt": "78",
      "18100-125V/1Portable": "109",
      "30200-250V/3Wall/Column Mnt": "140",
      "30200-250V/3Angle Iron": "125",
      "30200-250V/3Chain Mnt": "125",
      "30200-250V/3Yoke Mnt": "125",
      "30400-480V/3Wall/Column Mnt": "140",
      "30400-480V/3Angle Iron": "125",
      "30400-480V/3Chain Mnt": "125",
      "30400-480V/3Yoke Mnt": "125",
    },
    height: {
      "18100-125V/1Swivel": "24.1",
      "18200-250V/1Swivel": "24.1",
      "18100-125V/1Wall/Column Mnt": "39.5",
      "18100-125V/1Angle Iron": "23.5",
      "18100-125V/1Chain Mnt": "23.5",
      "18100-125V/1Yoke Mnt": "23.5",
      "18100-125V/1Portable": "23.5",
      "30200-250V/3Wall/Column Mnt": "46.5",
      "30200-250V/3Angle Iron": "59.3",
      "30200-250V/3Chain Mnt": "37.8",
      "30200-250V/3Yoke Mnt": "37.8",
      "30400-480V/3Wall/Column Mnt": "5",
      "30400-480V/3Angle Iron": "37.8",
      "30400-480V/3Chain Mnt": "37.8",
      "30400-480V/3Yoke Mnt": "37.8",
    },
    width: {
      "18100-125V/1Swivel": "20.3",
      "18200-250V/1Swivel": "20.3",
      "18100-125V/1Wall/Column Mnt": "20.3",
      "18100-125V/1Angle Iron": "20.3",
      "18100-125V/1Chain Mnt": "20.3",
      "18100-125V/1Yoke Mnt": "20.3",
      "18100-125V/1Portable": "32.5",
      "30200-250V/3Wall/Column Mnt": "33.3",
      "30200-250V/3Angle Iron": "33.3",
      "30200-250V/3Chain Mnt": "33.3",
      "30200-250V/3Yoke Mnt": "33.3",
      "30400-480V/3Wall/Column Mnt": "33.3",
      "30400-480V/3Angle Iron": "33.3",
      "30400-480V/3Chain Mnt": "33.3",
      "30400-480V/3Yoke Mnt": "33.3",
    },
    depth: {
      "18100-125V/1Swivel": "45.9",
      "18200-250V/1Swivel": "45.9",
      "18100-125V/1Wall/Column Mnt": "22.2",
      "18100-125V/1Angle Iron": "16",
      "18100-125V/1Chain Mnt": "16",
      "18100-125V/1Yoke Mnt": "16",
      "18100-125V/1Portable": "31.5",
      "30200-250V/3Wall/Column Mnt": "28",
      "30200-250V/3Angle Iron": "16",
      "30200-250V/3Chain Mnt": "16",
      "30200-250V/3Yoke Mnt": "16",
      "30400-480V/3Wall/Column Mnt": "28",
      "30400-480V/3Angle Iron": "16",
      "30400-480V/3Chain Mnt": "16",
      "30400-480V/3Yoke Mnt": "16",
    },
    features: [
      "OSHA-compliant guard",
      "Heavy duty 11 Ga Steel housing",
      "3 Glass reinforced polypropylene blades",
    ],
    additions: ["Big Fan pkg"],
    parts: ["Standard", "Monochrome", "Cage", "Airfoils", "Winglets"],
    directionalMounts: {
      "18": [
        "Swivel",
        "Angle Iron",
        "Portable",
        "Wall/Column Mnt",
        "Chain Mnt",
        "Yoke Mnt",
      ],
      "30": ["Angle Iron", "Wall/Column Mnt", "Chain Mnt", "Yoke Mnt"],
    },
    yokeET: [
      "1'",
      "2'",
      "3'",
      "4'",
      "5'",
      "6'",
      "7'",
      "8'",
      "9'",
      "10'",
      "11'",
      "12'",
      "13'",
      "14'",
      "15'",
    ],
    appearanceOpts: [
      "Yellow",
      "Powerfoil Yellow",
      "Alpine White",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Valor Red",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/sweatbee/sweat-bee-install-guide-en.pdf",
    },
    warranty: {
      "Main Fan Unit": "3 Years",
    },
  },
  "Sweat Bee Swivel": {
    template: "SweatBeeTemplate",
    name: "Sweat Bee Swivel",
    sizes: [18],
    sizeUnits: "in",
    volts: {
      "18": ["100-125V/1", "200-250V/1"],
      "30": ["200-250V/3", "400-480V/3"],
    },
    maxSound: {
      "18": "81",
      "30": "87",
    },
    maxAmps: {
      "100-125V/1": "6",
      "200-250V/3": "3.7",
      "200-250V/1": "3",
      "400-480V/3": "1.9",
    },
    rcs: {
      "100-125V/1": "20",
      "200-250V/3": "15",
      "200-250V/1": "15",
      "400-480V/3": "20",
    },
    motor: { "18": "1/3", "30": "1" },
    rpm: { "18": 1725, "30": 1160 },
    weights: {
      "18100-125V/1Swivel": "94",
      "18200-250V/1Swivel": "94",
    },
    height: {
      "18100-125V/1Swivel": "24.1",
      "18200-250V/1Swivel": "24.1",
    },
    width: {
      "18100-125V/1Swivel": "20.3",
      "18200-250V/1Swivel": "20.3",
    },
    depth: {
      "18100-125V/1Swivel": "45.9",
      "18200-250V/1Swivel": "45.9",
    },
    features: [
      "OSHA-compliant guard",
      "Heavy duty 11 Ga Steel housing",
      "3 Glass reinforced polypropylene blades",
    ],
    additions: ["Big Fan pkg"],
    parts: ["Standard", "Monochrome", "Cage", "Airfoils", "Winglets"],
    directionalMounts: {
      "18": ["Swivel"],
      "30": ["Angle Iron", "Wall/Column Mnt", "Chain Mnt", "Yoke Mnt"],
    },
    yokeET: [
      "1'",
      "2'",
      "3'",
      "4'",
      "5'",
      "6'",
      "7'",
      "8'",
      "9'",
      "10'",
      "11'",
      "12'",
      "13'",
      "14'",
      "15'",
    ],
    appearanceOpts: [
      "Yellow",
      "Powerfoil Yellow",
      "Alpine White",
      "Royal Blue",
      "Oil-Rubbed Bronze",
      "Valor Red",
      "Gunmetal Gray",
      "Stealth Black",
      "Vintage Silver",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/sweatbee/sweat-bee-install-guide-en.pdf",
    },
    warranty: {
      "Main Fan Unit": "3 Years",
    },
  },
  // residential
  Haiku: {
    template: "HaikuTemplate",
    name: "Haiku",
    sizes: [52, 60, 84],
    sizeUnits: "in",
    volts: "100-240V/1",
    ledModule: {
      "Operating Voltage": "12/24 VCC",
      "Operating Current": "1.5 Adc (100% PWM)",
      "Nominal Input Power": "16.3 W",
      "Nominal Lumen Output": "1280 Lm",
      "Dimming Range": "3-100% (16 settings)",
      "Included Lenses": "Smoky and Diffused Clear",
    },
    warranty: {
      residential: {
        indoor: "5 years",
        outdoor: "3 years",
      },
      nonresidential: {
        indoor: "3 years",
        outdoor: "2 years",
      },
    },
    rpm: {
      "52 Universal": 200,
      "52 Standard": 200,
      "52 Low-Profile": 177,
      "60 Universal": 200,
      "60 Standard": 200,
      "60 Low-Profile": 175,
      "84 Universal": 135,
    },
    maxWatts: {
      "52UniversalBamboo": "26.6",
      "52StandardBamboo": "26.6",
      "52Low-ProfileBamboo": "19.2",
      "52UniversalAlum.": "20.7",
      "52StandardAlum.": "20.7",
      "52Low-ProfileAlum.": "15.6",
      "60UniversalBamboo": "21.4",
      "60StandardBamboo": "21.4",
      "60Low-ProfileBamboo": "15.5",
      "60UniversalAlum.": "26.8",
      "60StandardAlum.": "26.8",
      "60Low-ProfileAlum.": "18.4",
      "84UniversalBamboo": "39.2",
      "84UniversalAlum.": "53.7",
    },
    mountDiameter: {
      "52 Universal": 8.7,
      "52 Standard": 6,
      "52 Low-Profile": 6,
      "60 Universal": 8.7,
      "60 Standard": 6,
      "60 Low-Profile": 6,
      "84 Universal": 8.7,
    },
    material: {
      "Caramel Bamboo": "Bamboo",
      "Cocoa Bamboo": "Bamboo",
      "Brushed Alum.": "Alum.",
      "Polished Alum.": "Alum.",
      "Black Alum.": "Alum.",
      "White Alum.": "Alum.",
      "Oil-Rubbed Bronze": "Alum.",
      "Satin Nickel": "Alum.",
      "Brushed Copper": "Alum.",
      Driftwood: "Bamboo",
    },
    maxAirflow: {
      // size + mount + material
      "52UniversalBamboo": "6,597",
      "52StandardBamboo": "6,597",
      "52Low-ProfileBamboo": "5,866",
      "52UniversalAlum.": "6,713",
      "52StandardAlum.": "6,713",
      "52Low-ProfileAlum.": "5,629",
      "60UniversalBamboo": "7,673",
      "60StandardBamboo": "7,673",
      "60Low-ProfileBamboo": "6,165",
      "60UniversalAlum.": "8,629",
      "60StandardAlum.": "8,629",
      "60Low-ProfileAlum.": "6,698",
      "84UniversalBamboo": "15,378",
      "84UniversalAlum.": "19,292",
    },
    weights: {
      "52UniversalBamboo": "13",
      "52StandardBamboo": "14",
      "52Low-ProfileBamboo": "14",
      "52UniversalAlum.": "15.5",
      "52StandardAlum.": "16.5",
      "52Low-ProfileAlum.": "16.5",
      "60UniversalBamboo": "15",
      "60StandardBamboo": "16",
      "60Low-ProfileBamboo": "16",
      "60UniversalAlum.": "17.5",
      "60StandardAlum.": "18.5",
      "60Low-ProfileAlum.": "18.5",
      "84UniversalBamboo": "21",
      "84UniversalAlum.": "26",
    },
    airfoilFinish: [
      "Caramel Bamboo",
      "Cocoa Bamboo",
      "Brushed Alum.",
      "Polished Alum.",
      "Black Alum.",
      "White Alum.",
      "Oil-Rubbed Bronze",
      "Satin Nickel",
      "Brushed Copper",
      "Driftwood",
    ],
    additions: [
      "Remote (1 included w/ fan)",
      "Wall control",
      "Haiku LED Light Kit",
      "Haiku LED Uplight",
      "0-10 V Module",
    ],
    hardwareFinish: {
      "Caramel Bamboo": ["Black", "White", "Oil-Rubbed Bronze", "Satin Nickel"],
      "Cocoa Bamboo": ["Black", "Oil-Rubbed Bronze", "Satin Nickel"],
      "Brushed Alum.": ["White"],
      "Polished Alum.": ["Black", "White", "Oil-Rubbed Bronze", "Satin Nickel"],
      "Black Alum.": ["Black"],
      "White Alum.": ["White"],
      "Oil-Rubbed Bronze": ["Oil-Rubbed Bronze"],
      "Satin Nickel": ["Satin Nickel"],
      "Brushed Copper": ["Black", "White", "Oil-Rubbed Bronze", "Satin Nickel"],
      Driftwood: ["White"],
    },
    features: [
      "Intertek/ETL certified	",
      "Energy Star Most Efficient 2020	",
      "EC Motor with Digital Inverter Drive	",
      "Sleep, Timer, Whoosh, and Fan Eco special operational modes	",
      "100-240 V, 1 ɸ, 50-60 Hz	",
      "LED Fan Mode Indicator (not visible with light kit)	",
      "7 Fan Speeds	",
      "Onboard SenseME Technology	",
      "Remote Control Included	",
    ],
    senseME: [
      "Motion: Passive Infared	",
      "Communication Radio: Embedded WiFi Chip	",
      "Sensors: Ambient & Surface Temperature, Relative Humidity	",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/haiku/haiku/haiku-indoor-install-guide-en-fr-es.pdf",
    },
    mounts: {
      52: ["Low-Profile", "Universal"],
      60: ["Low-Profile", "Universal"],
      84: ["Universal"],
    },
    hb: {
      "Standard ": 17,
      "Low-Profile ": 12.3,
      "Universal 7": 16,
      "Universal 20/32": "29/41",
      "Universal 48/60": "57/69",
    },
    ET: {
      Universal: ["7", "20/32", "48/60"],
    },
  },
  "Haiku Outdoor": {
    template: "HaikuTemplate",
    name: "Haiku Outdoor",
    sizes: [52, 60, 84],
    sizeUnits: "in",
    volts: "100-240V/1",
    ledModule: {
      "Operating Voltage": "12/24 VCC",
      "Operating Current": "1.5 Adc (100% PWM)",
      "Nominal Input Power": "16.3 W",
      "Nominal Lumen Output": "1280 Lm",
      "Dimming Range": "3-100% (16 settings)",
      "Operating Life Expectancy": "50,000 hours",
      "Included Lenses": "Smoky and Clear",
    },
    rpm: {
      "52 Universal": 200,
      "52 Standard": 200,
      "52 Low-Profile": 177,
      "60 Universal": 200,
      "60 Standard": 200,
      "60 Low-Profile": 175,
      "84 Universal": 135,
    },
    maxWatts: {
      "52UniversalAlum.": 21.7,
      "52StandardAlum.": 21.7,
      "52Low-ProfileAlum.": 15.4,
      "60UniversalAlum.": 30.5,
      "60StandardAlum.": 30.5,
      "60Low-ProfileAlum.": 31.4,
      "84UniversalAlum.": 56.1,
      "52UniversalBamboo": 21.7,
      "52StandardBamboo": 21.7,
      "52Low-ProfileBamboo": 15.4,
      "60UniversalBamboo": 30.5,
      "60StandardBamboo": 30.5,
      "60Low-ProfileBamboo": 31.4,
      "84UniversalBamboo": 56.1,
    },
    mountDiameter: {
      "52 Universal": 8.7,
      "52 Standard": 6,
      "52 Low-Profile": 6,
      "60 Universal": 8.7,
      "60 Standard": 6,
      "60 Low-Profile": 6,
      "84 Universal": 8.7,
    },
    material: {
      "Caramel Woodgrain Al": "Alum.",
      "Cocoa Woodgrain Al": "Alum.",
      "Brushed Alum.": "Alum",
      "Black Alum.": "Alum.",
      "White Alum.": "Alum.",
      "Oil-Rubbed Bronze": "Alum.",
      "Satin Nickel": "Alum.",
      "Brushed Alum": "Alum.",
      Driftwood: "Bamboo",
    },
    maxAirflow: {
      // size + mount + material
      "52UniversalBamboo": "6,207",
      "52StandardBamboo": "6,207",
      "52Low-ProfileBamboo": "4,565",
      "52UniversalAlum.": "6,207",
      "52StandardAlum.": "6,207",
      "52Low-ProfileAlum.": "4,565",
      "60UniversalBamboo": "8,534",
      "60StandardBamboo": "8,534",
      "60Low-ProfileBamboo": "7,957",
      "60UniversalAlum.": "8,534",
      "60StandardAlum.": "8,534",
      "60Low-ProfileAlum.": "7,957",
      "84UniversalBamboo": "17,546",
      "84UniversalAlum.": "17,546",
    },
    weights: {
      "52UniversalBamboo": "15.5",
      "52StandardBamboo": "16.5",
      "52Low-ProfileBamboo": "16.5",
      "52UniversalAlum.": "15.5",
      "52StandardAlum.": "16.5",
      "52Low-ProfileAlum.": "16.5",
      "60UniversalBamboo": "17.5",
      "60StandardBamboo": "18.5",
      "60Low-ProfileBamboo": "18.5",
      "60UniversalAlum.": "17.5",
      "60StandardAlum.": "18.5",
      "60Low-ProfileAlum.": "18.5",
      "84UniversalBamboo": "26",
      "84UniversalAlum.": "26",
    },
    airfoilFinish: [
      "Caramel Woodgrain Al",
      "Cocoa Woodgrain Al",
      "Brushed Alum.",
      "Black Alum.",
      "White Alum.",
      "Oil-Rubbed Bronze",
      "Driftwood",
      "Satin Nickel",
    ],
    additions: [
      "Remote (1 included w/ fan)",
      "Haiku LED Light Kit",
      "0-10 V Module",
    ],
    hardwareFinish: {
      "Caramel Bamboo": ["Black", "White", "Oil-Rubbed Bronze", "Satin Nickel"],
      "Cocoa Bamboo": ["Black", "Oil-Rubbed Bronze", "Satin Nickel"],
      "Brushed Alum.": ["White"],
      "Polished Alum.": ["Black", "White", "Oil-Rubbed Bronze", "Satin Nickel"],
      "Black Alum.": ["Black"],
      "White Alum.": ["White"],
      "Oil-Rubbed Bronze": ["Oil-Rubbed Bronze"],
      "Satin Nickel": ["Satin Nickel"],
      "Brushed Copper": ["Black", "White", "Oil-Rubbed Bronze", "Satin Nickel"],
      Driftwood: ["White"],
    },
    features: [
      "Intertek/ETL certified	",
      "Energy Star Most Efficient 2020	",
      "EC Motor with Digital Inverter Drive	",
      "Sleep, Timer, Whoosh, and Fan Eco special operational modes	",
      "100-240 V, 1 ɸ, 50-60 Hz	",
      "LED Fan Mode Indicator (not visible with light kit)	",
      "7 Fan Speeds	",
      "Onboard SenseME Technology	",
      "Remote Control Included	",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/haiku/haiku/haiku-indoor-install-guide-en-fr-es.pdf",
    },
    mounts: {
      52: ["Low-Profile", "Universal"],
      60: ["Low-Profile", "Universal"],
      84: ["Universal"],
    },
    hb: {
      "Standard ": 16.4,
      "Low-Profile ": 11.7,
      "Universal 7": 16,
      "Universal 20/32": "29/41",
      "Universal 48/60": "57/69",
    },
    ET: {
      Universal: ["7", "20/32", "48/60"],
    },
    warranty: {
      residential: {
        indoor: "5 years",
        outdoor: "3 years",
      },
      nonresidential: {
        indoor: "3 years",
        outdoor: "2 years",
      },
    },
  },
  "Haiku with UV-C": {
    template: "HaikuTemplate",
    name: "Haiku with UV-C",
    sizes: [52, 60, 84],
    sizeUnits: "in",
    volts: "100-240V/1",
    UVledModule: {
      "Operating Voltage": "12/24 VCC",
      "Operating Current": "1.9/0.9 A",
      "Input Power": "22.4/21.2",
      "UV Output Power": "0.203 W",
      "Peak Wavelength": "260-270 nm",
      Maintenance: "Calculated L50<12,000 hours",
    },
    warranty: {
      residential: {
        indoor: "5 years",
        CAS: "1 Year",
      },
      nonresidential: {
        indoor: "3 years",
        CAS: "1 Year",
      },
    },
    rpm: {
      "52 Universal": 200,
      "52 Standard": 200,
      "52 Low-Profile": 177,
      "60 Universal": 200,
      "60 Standard": 200,
      "60 Low-Profile": 175,
      "84 Universal": 135,
    },
    hardwareFinish: ["Black", "White", "Oil-Rubbed Bronze", "Satin Nickel"],
    maxWatts: {
      "52UniversalBamboo": "26.6",
      "52StandardBamboo": "26.6",
      "52Low-ProfileBamboo": "19.2",
      "52UniversalAlum.": "20.7",
      "52StandardAlum.": "20.7",
      "52Low-ProfileAlum.": "15.6",
      "60UniversalBamboo": "21.4",
      "60StandardBamboo": "21.4",
      "60Low-ProfileBamboo": "15.5",
      "60UniversalAlum.": "26.8",
      "60StandardAlum.": "26.8",
      "60Low-ProfileAlum.": "18.4",
      "84UniversalBamboo": "39.2",
      "84UniversalAlum.": "53.7",
    },
    mountDiameter: {
      "52 Universal": 8.7,
      "52 Standard": 6,
      "52 Low-Profile": 6,
      "60 Universal": 8.7,
      "60 Standard": 6,
      "60 Low-Profile": 6,
      "84 Universal": 8.7,
    },
    material: {
      "Caramel Bamboo": "Bamboo",
      "Cocoa Bamboo": "Bamboo",
      "Brushed Alum.": "Alum.",
      "Polished Alum.": "Alum.",
      "Black Alum.": "Alum.",
      "White Alum.": "Alum.",
      "Oil-Rubbed Bronze": "Alum.",
      "Satin Nickel": "Alum.",
      "Brushed Copper": "Alum.",
      Driftwood: "Alum.",
    },
    maxAirflow: {
      // size + mount + material
      "52UniversalBamboo": "6,597",
      "52StandardBamboo": "6,597",
      "52Low-ProfileBamboo": "5,866",
      "52UniversalAlum.": "6,713",
      "52StandardAlum.": "6,713",
      "52Low-ProfileAlum.": "5,629",
      "60UniversalBamboo": "7,673",
      "60StandardBamboo": "7,673",
      "60Low-ProfileBamboo": "6,165",
      "60UniversalAlum.": "8,629",
      "60StandardAlum.": "8,629",
      "60Low-ProfileAlum.": "6,698",
      "84UniversalBamboo": "15,378",
      "84UniversalAlum.": "19,292",
    },
    weights: {
      "52UniversalBamboo": "13",
      "52StandardBamboo": "14",
      "52Low-ProfileBamboo": "14",
      "52UniversalAlum.": "15.5",
      "52StandardAlum.": "16.5",
      "52Low-ProfileAlum.": "16.5",
      "60UniversalBamboo": "15",
      "60StandardBamboo": "16",
      "60Low-ProfileBamboo": "16",
      "60UniversalAlum.": "17.5",
      "60StandardAlum.": "18.5",
      "60Low-ProfileAlum.": "18.5",
      "84UniversalBamboo": "21",
      "84UniversalAlum.": "26",
    },
    airfoilFinish: [
      "Caramel Bamboo",
      "Cocoa Bamboo",
      "Brushed Alum.",
      "Polished Alum.",
      "Black Alum.",
      "White Alum.",
      "Oil-Rubbed Bronze",
      "Satin Nickel",
      "Brushed Copper",
      "Driftwood",
    ],
    additions: ["Remote (1 included w/ fan)", "Wall control", "0-10 V Module"],

    features: [
      "Intertek/ETL certified	",
      "Energy Star Most Efficient 2020	",
      "EC Motor with Digital Inverter Drive	",
      "Sleep, Timer, Whoosh, and Fan Eco special operational modes	",
      "100-240 V, 1 ɸ, 50-60 Hz	",
      "LED Fan Mode Indicator (not visible with light kit)	",
      "7 Fan Speeds	",
      "Onboard SenseME Technology	",
      "Remote Control Included	",
    ],
    senseME: [
      "Motion: Passive Infared	",
      "Communication Radio: Embedded WiFi Chip	",
      "Sensors: Ambient & Surface Temperature, Relative Humidity	",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/haiku/haiku/haiku-uplight-install-guide.pdf",
    },
    mounts: {
      52: ["Universal"],
      60: ["Universal"],
      84: ["Universal"],
    },
    hb: {
      "Standard ": 17,
      "Low-Profile ": 12.3,
      "Universal 7": 16,
      "Universal 20/32": "29/41",
      "Universal 48/60": "57/69",
    },
    ET: {
      Universal: ["7", "20/32", "48/60"],
    },
  },
  "Haiku L": {
    template: "HaikuTemplate",
    name: "Haiku L",
    sizes: [44, 52],
    sizeUnits: "in",
    volts: "100-240V/1",
    integratedLight: {
      "Nominal Input Power": "20 W",
      "Nominal Lumen Output": "988 Lm",
      "Dimming Range": "3-100% (16 settings)",
      "Operating Life Expectancy": "50,000 hours",
    },
    warranty: {
      residential: {
        indoor: "5 years",
      },
      nonresidential: {
        indoor: "3 years",
      },
    },
    rpm: {
      "44 ": 262,
      "52 ": 201,
    },
    maxWatts: {
      "44Alum.": 36,
      "52Alum.": 20.3,
    },
    mountDiameter: {
      "44 ": 6,
      "52 ": 6,
    },
    material: {
      Black: "Alum.",
      White: "Alum.",
    },
    maxAirflow: {
      // size + mount + material
      "44Alum.": "4,475",
      "52Alum.": "5204",
    },
    weights: {
      "44Alum.": "11.9",
      "52Alum.": "11.9",
    },
    airfoilFinish: ["Black", "White"],
    additions: [
      "Remote (1 included w/ fan)",
      "Haiku Wall Control",
      "L Series Wired Wall Control",
      "Wi-Fi Module",
      "0-10 V Module",
    ],
    features: [
      "Intertek/ETL certified	",
      "EC Motor with Digital Inverter Drive	",
      "Sleep, Timer, Whoosh, and Fan Eco special operational modes	",
      "100-120 V, 1 ɸ, 50-60 Hz	",
      "LED Fan Mode Indicator 	",
      "7 Fan Speeds	",
      "Integrated LED light	",
      "Remote Control Included	",
    ],
    senseME: [
      "Motion: Passive Infared	",
      "Communication Radio: Embedded WiFi Chip	",
      "Sensors: Ambient & Surface Temperature, Relative Humidity	",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/haiku/haikul/haiku-l2-install-guide.pdf",
    },
    hb: {
      " 5": "11.5",
      " 10": "16.4",
      " 22": "28.4",
      " 34.8": "41.1",
      " 50.8": "57.1",
      " 62.8": "69",
    },
    ET: {
      "": [5, 10, 22, 34.8, 50.8, 62.8],
    },
  },
  // evap coolers these are incredibly static
  "Cool-Space 200": {
    template: "LargeFanTemplate",
    name: "Cool-Space 200",
    maxSound: "75",
    coverageArea: "800 sq ft",
    features: [
      "Suitable for well-ventilated indoor or outdoor spaces	",
      "UV-resistant roto-molded polyethylene housing	",
      "Variable speed control	",
      "Ground fault protected power cord with cord wrap	",
      "Power cord plugs into standard electrical outlet	",
      "4-inch resin-treated cooling media	",
      "Dual 10-inch fan diameter	",
      "Accepts standard garden hose or portable supply tank	",
      "Built-in reservoir for extended use	",
    ],
    noDims: true,
    staticMotor: "0.32",
    staticWeight: "51",
    waterCapacity: "8 gal",
    waterPressure: "120 PSI max",
    staticVoltage: "120 VAC/1",
    staticRCS: "15 A",
    staticMaxAmps: "2.7 A",
    airflow: "900-1800 cfm",
    links: {
      "Operation Manual":
        "https://www.bigassfans.com/docs/coldfront/cold-front-100-200-op-maint.pdf",
    },
    warranty: {
      "Main Fan Unit": "5 Years",
      "All Other Components": "1 Year",
    },
  },
  "Cool-Space 500": {
    template: "CoolSpaceTemplate",
    name: "Cool-Space 500",
    motor: "1.5",
    weight: "370",
    waterCapacity: "64",
    waterPressure: "120",
    airflow: "16500-24000",
    coverageArea: 6500,
    maxSound: 60,
    volts: ["120 VAC/1", "230 VAC/1"],
    rcs: {
      "120 VAC/1": 25,
      "230 VAC/1": 15,
    },
    maxAmps: {
      "120 VAC/1": 16.5,
      "230 VAC/1": 8.6,
    },
    features: [
      "Suitable for well-ventilated indoor or outdoor spaces	",
      "UV-resistant roto-molded polyethylene housing	",
      "Variable speed control	",
      "Onboard LED control panel with occupancy sensor	",
      "Power cord plugs into standard electrical outlet	",
      "8-inch resin-treated cooling media	",
      "50-inch fan diameter	",
      "Accepts standard garden hose or portable supply tank	",
      "Low-water shutoff for pump longevity	",
      "Built-in reservoir for extended use	",
    ],
    warranty: {
      "Main Fan Unit": "5 Years",
      "All Other Components": "3 Years",
    },
    links: {
      "Operation Manual":
        "https://www.bigassfans.com/docs/coldfront/cold-front-300-400-500-op-maint.pdf",
    },
  },
  "Cool-Space 400": {
    template: "CoolSpaceTemplate",
    name: "Cool-Space 400",
    motor: "0.75",
    weight: "220",
    waterCapacity: "46",
    waterPressure: "120",
    airflow: "6400-9700",
    coverageArea: 3600,
    maxSound: 61,
    volts: ["120 VAC/1", "230 VAC/1"],
    rcs: {
      "120 VAC/1": 15,
      "230 VAC/1": 10,
    },
    maxAmps: {
      "120 VAC/1": 8,
      "230 VAC/1": 5,
    },
    features: [
      "Suitable for well-ventilated indoor or outdoor spaces	",
      "UV-resistant roto-molded polyethylene housing	",
      "Variable-speed control	",
      "Ground fault protected power cord with cord wrap	",
      "Power cord plugs into standard electrical outlet	",
      "8-inch resin-treated cooling media	",
      "36-inch fan diameter	",
      "Accepts standard garden hose or portable supply tank	",
      "Low-water shutoff for pump longevity	",
      "Built-in reservoir for extended use	",
    ],
    warranty: {
      "Main Fan Unit": "5 Years",
      "All Other Components": "3 Years",
    },
    links: {
      "Operation Manual":
        "https://www.bigassfans.com/docs/coldfront/cold-front-300-400-500-op-maint.pdf",
    },
  },
  "Cool-Space 300": {
    template: "CoolSpaceTemplate",
    name: "Cool-Space 300",
    motor: "0.50",
    weight: "100",
    waterCapacity: "16",
    waterPressure: "120",
    airflow: "700-2800",
    coverageArea: 1200,
    maxSound: 63,
    volts: ["120 VAC/1", "230 VAC/1"],
    rcs: {
      "120 VAC/1": 15,
      "230 VAC/1": 10,
    },
    maxAmps: {
      "120 VAC/1": 4.1,
      "230 VAC/1": 1.8,
    },
    features: [
      "Suitable for well-ventilated indoor or outdoor spaces	",
      "UV-resistant roto-molded polyethylene housing	",
      "Variable speed control	",
      "Ground fault protected power cord with cord wrap	",
      "Power cord plugs into standard electrical outlet	",
      "8-inch resin-treated cooling media	",
      "18-inch fan diameter	",
      "Accepts standard garden hose	",
      "Low-water shutoff for pump longevity	",
      "Built-in reservoir for extended use	",
    ],
    warranty: {
      "Main Fan Unit": "5 Years",
      "All Other Components": "3 Years",
    },
    links: {
      "Operation Manual":
        "https://www.bigassfans.com/docs/coldfront/cold-front-300-400-500-op-maint.pdf",
    },
  },
  i6: {
    template: "HaikuTemplate",
    name: "i6",
    sizes: [60, 72, 84, 96],
    sizeUnits: "in",
    volts: "100-240V/1",
    ledModule: {
      "Operating Voltage": "24 VDC",
      "Operating Current": "1.1A",
      "Nominal Input Power": "25.5 W",
      "Nominal Lumen Output": "1671 Lm",
      "Dimming Range": "8-100% (129 Lm min.)",
      "Color Shifting Range": "2200 - 6500K",
      "Operating Life Expectancy": "72,000 hours",
    },
    warranty: {
      residential: {
        indoor: "5 years",
        outdoor: "3 years",
      },
      nonresidential: {
        indoor: "3 years",
        outdoor: "2 years",
      },
    },
    rpm: {
      "60 Direct Mnt": 171,
      "60 Standard": 145,
      "72 Standard": 140,
      "84 Standard": 110,
      "96 Standard": 80,
    },
    fla: {
      "60": 35.4,
      "72": 41.6,
      "84": 40.2,
      "96": 36.0,
    },
    mountDiameter: {
      "60 Direct Mnt": 7.6,
      "60 Universal": 6,
      "60 Standard": 6,
      "72 Standard": 6,
      "84 Standard": 6,
      "96 Standard": 6,
    },
    material: {
      Black: "Alumn.",
      White: "Alumn.",
      "Brushed Silver": "Alumn.",
      "Oil-Rubbed Bronze": "Alumn.",
    },
    maxAirflow: {
      // size + mount + material
      "60Direct MntAlumn.": "7,122",
      "60StandardAlumn.": "9,676",
      "72StandardAlumn.": "13,598",
      "84StandardAlumn.": "15,576",
      "96StandardAlumn.": "-",
    },
    weights: {
      "60Direct MntAlumn.": "34",
      "60StandardAlumn.": "34",
      "72StandardAlumn.": "35.7",
      "84StandardAlumn.": "37.3",
      "96StandardAlumn.": "40.3",
    },
    finish: ["Black", "White", "Brushed Silver", "Oil-Rubbed Bronze"],
    additions: ["LED light kit", "Wall Control", "0-10V Module"],
    features: [
      "Aircraft-grade aluminum airfoils with tilted blades	",
      "Energy Star Most Efficient 2020	",
      "Optional LED light kit for indoor/outdoor use 	",
      "Onboard SenseME technology	",
      "Voice control with Google Assistant or Amazon Alexa	",
      "Works with ecobee, Nest,  & home automation systems	",
      "Indoor or outdoor covered use with full functionality	",
      "Bluetooth remote with optional wall control	",
    ],
    senseME: [
      "Motion: Passive Infared	",
      "Communication Radio: Embedded WiFi Chip	",
      "Sensors: Ambient & Surface Temperature, Relative Humidity	",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/i6/i6-install-guide.pdf",
    },
    mounts: {
      60: ["Standard", "Direct Mnt"],
      72: ["Standard"],
      84: ["Standard"],
      96: ["Standard"],
    },
    hb: {
      "Direct Mnt ": 13.5,
      "Standard 6": 22.8,
      "Standard 12": 28.8,
      "Standard 24": 40.8,
      "Standard 36": 52.8,
      "Standard 48": 64.8,
      "Standard 60": 76.8,
    },
    ET: {
      Standard: ["6", "12", "24", "36", "48", "60"],
    },
    variableET: {
      "96Standard": ["12", "24", "36", "48", "60"],
    },
  },
  es6: {
    template: "HaikuTemplate",
    name: "es6",
    sizes: [60, 72, 84],
    sizeUnits: "in",
    volts: "100-277V/1",
    ledModule: {
      "Operating Voltage": "+24 VDC",
      "Operating Current": "1.1A",
      "Nominal Input Power": "25.5 W",
      "Nominal Lumen Output": "1770 Lm",
      "Dimming Range": "1-100% (16 Settings)",
      "Color Shifting Range": "2,700 - 4,000K",
      "Included Lens": "Diffused translucent",
    },
    showDownrod: true,
    warranty: {
      residential: {
        indoor: "3 year",
      },
      nonresidential: {
        indoor: "3 year",
      },
    },
    rpm: {
      "60 Universal": 144,
      "72 Universal": 115,
      "84 Universal": 85,
    },
    fla: {
      "60 Universal": 0.3,
      "72 Universal": 0.32,
      "84 Universal": 0.32,
    },
    mountDiameter: {
      "60 Universal": 6,
      "72 Universal": 6,
      "84 Universal": 6,
    },
    material: {
      Black: "Alumn.",
      White: "Alumn.",
    },
    maxAirflow: {
      // size + mount + material
      "60UniversalAlumn.": "7,880",
      "72UniversalAlumn.": "9,959",
      "84UniversalAlumn.": "10,121",
    },
    weights: {
      "60UniversalAlumn.": "25",
      "72UniversalAlumn.": "27",
      "84UniversalAlumn.": "29",
    },
    finish: ["Black", "White"],
    additions: ["LED light kit", "0-10V Module", "Chromatic Uplight"],
    features: [
      "Aircraft-grade aluminum airfoils with tilted blades	",
      "Optional LED light kit for indoor/outdoor use 	",
      "Onboard Motion sensor	",
      "Voice control with Google Assistant or Amazon Alexa	",
      "Mobile app or handheld remote control	",
      "Indoor or outdoor covered use with full functionality	",
      "Wi-Fi connectivity standard on all models	",
    ],
    senseME: [
      "Motion: Passive Infared	",
      "Communication Radio: Embedded WiFi Chip	",
      "Sensors: Ambient & Surface Temperature, Relative Humidity	",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/es6/es6-install-guide.pdf",
    },
    mounts: {
      60: ["Universal"],
      72: ["Universal"],
      84: ["Universal"],
    },
    ET: {
      Universal: ["7", "20", "32", "48", "60"],
    },
  },
  "es6 with UV-C": {
    template: "HaikuTemplate",
    name: "es6 with UV-C",
    sizes: [60, 72, 84],
    sizeUnits: "in",
    volts: "100-277V/1",
    ledModule: {
      "Operating Voltage": "+24 VDC",
      "Operating Current": "1.1A",
      "Nominal Input Power": "25.5 W",
      "Nominal Lumen Output": "1770 Lm",
      "Dimming Range": "1-100% (16 Settings)",
      "Color Shifting Range": "2,700 - 4,000K",
      "Included Lens": "Diffused translucent",
    },
    showDownrod: true,
    warranty: {
      residential: {
        indoor: "3 years",
      },
      nonresidential: {
        indoor: "3 years",
      },
    },
    rpm: {
      "60 Universal": 144,
      "72 Universal": 115,
      "84 Universal": 85,
    },
    fla: {
      "60 Universal": 0.3,
      "72 Universal": 0.32,
      "84 Universal": 0.32,
    },
    mountDiameter: {
      "60 Universal": 6,
      "72 Universal": 6,
      "84 Universal": 6,
    },
    material: {
      Black: "Alumn.",
      White: "Alumn.",
    },
    maxAirflow: {
      // size + mount + material
      "60UniversalAlumn.": "7,880",
      "72UniversalAlumn.": "9,959",
      "84UniversalAlumn.": "10,121",
    },
    weights: {
      "60UniversalAlumn.": "25",
      "72UniversalAlumn.": "27",
      "84UniversalAlumn.": "29",
    },
    finish: ["Black", "White"],
    additions: ["LED light kit", "0-10V Module", "Chromatic Uplight"],
    features: [
      "Aircraft-grade aluminum airfoils with tilted blades	",
      "Optional LED light kit for indoor/outdoor use 	",
      "Onboard Motion sensor	",
      "Voice control with Google Assistant or Amazon Alexa	",
      "Mobile app or handheld remote control	",
      "Indoor or outdoor covered use with full functionality	",
      "Wi-Fi connectivity standard on all models	",
    ],
    senseME: [
      "Motion: Passive Infared	",
      "Communication Radio: Embedded WiFi Chip	",
      "Sensors: Ambient & Surface Temperature, Relative Humidity	",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/es6/es6-install-guide.pdf",
    },
    mounts: {
      60: ["Universal"],
      72: ["Universal"],
      84: ["Universal"],
    },
    ET: {
      Universal: ["7", "20", "32", "48", "60"],
    },
  },
  BAFCon: {
    template: "BAFConTemplate",
    name: "BAFCon",
    features: [
      "24-bit color LCD display with resistive touchscreen	",
      "Sleek metal and ABS housing	",
      "SmartSense technology	",
      "Multi-level password protection	",
      "Mounts to wall or standard outlet box	",
      "Powered by fan drive with pre-assembled cable	",
      "Compatable with most Big Ass Fans	",
      "Included with every PFX3.0 fan	",
    ],
    links: {
      "Controller Install Guide":
        "https://www.bigassfans.com/docs/bafcon/bafcon-install-guide.pdf",
      "0-10 V Install Guide":
        "https://www.bigassfans.com/docs/bafcon/bafcon-0-10-V-installation.pdf",
      "PFD Multi-Fan Address Setup":
        "https://www.bigassfans.com/docs/bafcon/bafcon-multi-fan-modbus-address.pdf",
    },
  },
  "E Series": {
    template: "ESeriesTemplate",
    name: "E Series",
    sizes: [7, 10, 12],
    volts: ["110-125V/1", "200-240V/1"],
    weights: {
      7: 69,
      10: 76.8,
      12: 82.2,
    },
    rpm: {
      7: 198,
      10: 124,
      12: 85,
    },
    rcs: {
      "7 110-125V/1": "10",
      "7 200-240V/1": "10",
      "10 110-125V/1": "10",
      "10 200-240V/1": "10",
      "12 110-125V/1": "10",
      "12 200-240V/1": "10",
    },
    fla: {
      "7 110-125V/1": "5.5",
      "7 200-240V/1": "3.4",
      "10 110-125V/1": "6.0",
      "10 200-240V/1": "4.0",
      "12 110-125V/1": "4.1",
      "12 200-240V/1": "3.0",
    },
    features: [
      "ETL certified to UL 507 and CSA 22.2 No. 113	",
      "7' diameter, suitable for ceilings as low as 13'	",
      "Gearless direct drive motor	",
      "Permanent magnets reduce power consumpt.	",
      "Onboard electronic microprocessors	",
      "Wired wall controller	",
      "Lightweight and easy to install	",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/eseries/eseries-install-guide-en.pdf",
    },
    hb: {
      Short: "20",
      "2' (Standard)": "32",
      "3'": "44",
      "4'": "56",
      "5'": "68",
      "6'": "80",
      "7'": "92",
      "8'": "104",
      "9'": "116",
      "10'": "128",
      "11'": "140",
      "12'": "152",
      "13'": "164",
      "14'": "176",
      "15'": "188",
    },
    maxSound: "<35 ",
    mounts: ["Universal Mount"],
  },
  "Unit Heater": {
    template: "UHTemplate",
    name: "UH",
    models: ["45", "60", "75", "100", "125", "150", "200", "250", "300", "400"],
    output: {
      "45": 37.35,
      "60": 49.8,
      "75": 62.25,
      "100": 87.15,
      "125": 99.6,
      "150": 124.5,
      "200": 166,
      "250": 207.5,
      "300": 249,
      "400": 332,
    },
    weight: {
      45: 62,
      60: 71,
      75: 76,
      100: 101,
      125: 106,
      150: 178,
      200: 193,
      250: 223,
      300: 277,
      400: 316,
    },
    scs: {
      "45": 15,
      "60": 15,
      "75": 15,
      "100": 15,
      "125": 15,
      "150": 15,
      "200": 15,
      "250": 15,
      "300": 20,
      "400": 20,
    },
    maxAmps: {
      45: 2.4,
      60: 2.4,
      75: 3.7,
      100: 4.3,
      125: 5.6,
      150: 3.8,
      200: 4.6,
      250: 7.5,
      300: 11,
      400: 11,
    },
    fanDiameter: {
      45: 10,
      60: 12,
      75: 12,
      100: 16,
      125: 16,
      150: 18,
      200: 18,
      250: 20,
      300: 24,
      400: 24,
    },
    outletVelocity: {
      45: 656,
      60: 616,
      75: 770,
      100: 668,
      125: 763,
      150: 752,
      200: 1003,
      250: 911,
      300: 802,
      400: 1069,
    },
    gasConnection: {
      45: "½",
      60: "½",
      75: "½",
      100: "½",
      125: "½",
      150: "½",
      200: "½",
      250: "3/4",
      300: "3/4",
      400: "3/4",
    },
    ventConnection: {
      45: `4"`,
      60: `4"`,
      75: `4"`,
      100: `4"`,
      125: `4"`,
      150: `5"`,
      200: `5"`,
      250: `5"`,
      300: `6"`,
      400: `6"`,
    },
    airflow: {
      45: "629",
      60: "769",
      75: "961",
      100: "1345",
      125: "1537",
      150: "1921",
      200: "2562",
      250: "3202",
      300: "3843",
      400: "5123",
    },
    soundLevel: {
      45: "40",
      60: "40",
      75: "49",
      100: "54",
      125: "55",
      150: "51",
      200: "53",
      250: "56",
      300: "59",
      400: "62",
    },
    dimensions: {
      "45": [
        `13-3/4", 27", 10"`,
        `13-13/16", 29-3/4", 25-9/16"`,
        `6", 5-15/16", 3-1/2"`,
        `3-11/32", 17-3/8", 1-9/16"`,
        `4-9/32", 13", 9-9/16"`,
        `3-3/4", 2-15/16"`,
      ],
      "60": [
        `16-3/4", 27", 13"`,
        `13-13/16", 27", 25-9/16"`,
        `8-11/16", 5-15/16", 6"`,
        `3-11/32", 17-3/8", 1-9/16"`,
        `4-9/32", 13", 9-9/16"`,
        `4-1/16", 2-15/16"`,
      ],
      "75": [
        `16-3/4", 27", 13"`,
        `13-13/16", 27-5/8", 25-9/16"`,
        `8-11/16", 5-15/16", 6"`,
        `3-11/32", 17-3/8", 1-9/16"`,
        `4-9/32", 13", 9-9/16"`,
        `4-1/16", 2-15/16"`,
      ],
      "100": [
        `24-3/4", 27", 21"`,
        `13-13/16", 34-9/32", 25-9/16"`,
        `15-5/16", 5-15/16", 8-29/32"`,
        `3-11/32", 17-3/8", 1-9/16"`,
        `4-9/32", 13", 9-9/16"`,
        `5-15/32", 2-15/16"`,
      ],
      "125": [
        `24-3/4", 27", 21"`,
        `13-13/16", 34-9/32", 25-9/16"`,
        `15-5/16", 5-15/16", 8-29/32"`,
        `3-11/32", 17-3/8", 1-9/16"`,
        `4-9/32", 13", 9-9/16"`,
        `5-15/32", 2-15/16"`,
      ],
      "150": [
        `20-1/8", 38-3/16", 16"`,
        `23", 48-7/16", 40"`,
        `9-5/8", 8-5/16", 5-3/8"`,
        `6-1/2", 25-11/16", 1-13/32"`,
        `8-1/8", 22-3/16", 16-3/8"`,
        `5-1/2", 4-1/4"`,
      ],
      "200": [
        `20-1/8", 38-3/16", 16"`,
        `23", 48-7/16", 40"`,
        `9-5/8", 8-5/16", 5-3/8"`,
        `6-1/2", 25-11/16", 1-13/32"`,
        `8-1/8", 22-3/16", 16-3/8"`,
        `5-1/2", 4-1/4"`,
      ],
      "250": [
        `26-1/8", 38-3/16", 22"`,
        `23", 48-7/16", 40"`,
        `13-1/16", 8-5/16", 9"`,
        `6-1/2", 25-11/16", 1-13/32"`,
        `8-1/8", 22-3/16", 16-3/8"`,
        `8-1/16", 4-5/16"`,
      ],
      "300": [
        `34-1/8", 41", 30"`,
        `23", 48-29/32", 40"`,
        `17-1/16", 8-1/2", 11-13/16"`,
        `7-5/16", 27-11/16", 1-13/32"`,
        `8-1/8", 22-3/16", 16-1/4"`,
        `11-9/16", 16-3/16"`,
      ],
      "400": [
        `34-1/8", 41", 30"`,
        `23", 48-29/32", 40"`,
        `17-1/16", 8-1/2", 11-13/16"`,
        `7-5/16", 27-11/16", 1-13/32"`,
        `8-1/8", 22-3/16", 16-1/4"`,
        `11-9/16", 16-3/16"`,
      ],
    },
    features: [
      "ETL-certified for sale in the US and Canada",
      "Indoor environments only",
      "50-60 degree rise range",
      "Natural gas-fired with optional propane conversion kit",
      "One-piece burner assembly with multi-try ignition and timed lockout",
      "2-pt and 4-pt suspension for mounting to building structure",
      "Designed for low noise operation",
    ],
    additions: [
      "Thermostat, 24V",
      "Multi-Unit Thermostat, 24V",
      "Propane Conversion Kit",
    ],
    warranty: {
      "Unit Heater": "1 Year",
    },
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/eseries/eseries-install-guide-en.pdf",
    },
  },
  IRH: {
    template: "IRHTemplate",
    name: "IRH",
    models: [80, 125, 150, 200],
    tubeLength: {
      80: 20,
      125: 30,
      150: 40,
      200: 50,
    },
    fuelType: {
      80: ["Natural Gas", "LPG Propane"],
      125: ["Natural Gas", "LPG Propane"],
      150: ["Natural Gas", "LPG Propane"],
      200: ["Natural Gas"],
    },
    input: {
      80: 20,
      125: 30,
      150: 40,
      200: 50,
    },
    gasConnection: {
      80: "½",
      125: "½",
      150: "¾",
      200: "¾",
    },
    inletPressureMin: {
      "80Natural Gas": 4.6,
      "80LPG Propane": 11,
      "125Natural Gas": 4.6,
      "125LPG Propane": 11,
      "150Natural Gas": 4.6,
      "150LPG Propane": 11,
      "200Natural Gas": 5,
    },
    inletPressureMax: {
      "80Natural Gas": 14,
      "80LPG Propane": 14,
      "125Natural Gas": 14,
      "125LPG Propane": 14,
      "150Natural Gas": 14,
      "150LPG Propane": 14,
      "200Natural Gas": 14,
    },
    criticalA: {
      80: "20",
      125: "30",
      150: "40",
      200: "50",
    },
    criticalB: {
      80: "11'7\"",
      125: "16'7\"",
      150: "21'7\"",
      200: "31'7\"",
    },
    electricalRating: "120 V, 60 Hz, 1 A",
    combustionAirInlet: 4,
    exhaustFlue: 4,
    features: [
      "ANSI Z83.20/CSA 2.34, 2.17, 2.20 certification	",
      "Fully automatic, three-try, 100% shut-off control system	",
      "Approved for indoor (vented or unvented) use	",
      "Straight or U-tube configurations for even heat patterns	",
      "Indoor use only (vented or unvented)	",
      "Directly heats occupants and objects	",
      "Easy serviceability and startup through hinged door design	",
      ".024 Aluminum reflector and end caps	",
    ],
    additions: [
      "Thermostat, 24V",
      "Reflector side extension",
      "U-Tube Package",
    ],
    links: {
      "Install Guide":
        "https://www.bigassfans.com/docs/heaters/uh-install-guide-en.pdf",
    },
  },
};

export default db;
