import React from "react";
import H2 from "../UI-Kit/H2";
import Anchor from "../UI-Kit/Anchor";
import UnitsTable from "../Chapter-1/table-1.4";

function C1S4() {
  return (
    <div className="chapter-container">
      <Anchor id="1-4" />
      <H2 name="1.4 Units Table" />
      <div className="mb40 mt40">
        <UnitsTable />
      </div>
    </div>
  );
}

export default C1S4;
