import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";

function C2S7() {
  return (
    <div className="chapter-container">
      <Anchor id="2-7-1" />
      <H2 name="2.7 Fire Codes" />
      <H4 name="2.7.1 Fan Requirements in NFPA" />
      <p>
        In order to comply with the National Fire Protection Association (NFPA)
        guidelines for sprinkler systems (NFPA 13 - 11.1.7 and 12.1.4.1), all
        HVLS ceiling-mounted fans 6 to 24 ft. (1.8 to 7.3 m) in diameter and
        installed in the United States must incorporate a means of automatic
        shutdown upon detection of waterflow in the sprinkler system. In
        buildings in the United States equipped with sprinkler systems and in
        the scope of NFPA 13, including Early Suppression Fast Response (ESFR)
        sprinklers, 6 to 24 ft. (1.8 to 7.3 m) diameter fans must comply with the
        following guidelines to adhere to the NFPA 13 Standard:
      </p>
      <ul>
        <li>
          Each fan must be approximately centered between four (4) adjacent
          sprinklers.{" "}
        </li>
        <li>
          The vertical distance from the fan to the sprinkler deflector must be
          a minimum of 3 ft. (914 mm).{" "}
        </li>
        <li>
          All fans must be interlocked to shut down immediately upon receiving a
          waterflow signal from the alarm system in accordance with the
          requirements of NFPA 72.
        </li>
      </ul>
      <p>
        For fans less than 60 in. (1.5 m) in diameter and at least 50% open,
        sprinklers can be placed without regard to the location of the fan
        blades (NFPA 13 - 8.6.5.2.1.10, 8.7.5.2.1.6, 8.8.5.2.1.9, and
        8.9.5.2.1.7).{" "}
      </p>
      <p>
        Refer to NFPA 13 and NFPA 72 for additional details on the requirements
        summarized in this guide.
      </p>
    </div>
  );
}

export default C2S7;
