import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C2S3() {
  return (
    <div className="chapter-container">
      <Anchor id="2-3-1" />
      <H2 name="2.3 Ceiling Fan Use in Heating" />
      <H4 name="2.3.1 What Is Stratification?" />
      <p>
        Warm air is less dense than cool air causing it to be more buoyant and
        rise. When warm air in a space rises and cooler air sinks, this creates
        layers of air in the space where each layer is a different temperature.
        This effect is known as stratification.{" "}
      </p>
      <p>
        Air stratification causes a variety of issues in HVAC design. When warm
        air is delivered to a space during heating, and the air stratifies, the
        warm air stays at the top of the space instead of reaching the occupants
        in need of heating. That same supply air is delivering ventilation to
        the space. Again, if the air remains at the top of the space, outdoor
        air is not reaching the building’s occupants. In these ways,
        stratification negatively impacts both thermal comfort and indoor air
        quality. Additionally, stratification can lead to stack effect, which
        occurs when warm air rises and escapes through penetrations in the top
        of the building envelope. This creates lower pressure in the bottom of
        the building, causing infiltration of cold air into the building. As a
        result, the heating system runs more and the building consumes more
        energy.{" "}
      </p>

      <Anchor id="2-3-2" />
      <H4 name="2.3.2 Destratification Using Ceiling Fans" />
      <p>
        Destratification is the process of mixing air in a space to combat
        stratification. It is accomplished primarily through the use of fans,
        which recirculate air to break up the layers that form due to
        temperature differentials. Since hot air rises, ceiling fans are located
        near the stagnant hot air in a stratified room and are therefore able to
        push the warm air to the floor, mixing the air and destratifying the
        space.{" "}
      </p>
      <p>
        Conventional wisdom was that when attempting to destratify a space in
        winter, the ceiling fan’s rotation should be reversed. In this method,
        hot air is moved across the ceiling and down the walls, recirculating it
        throughout the space. This strategy was favored due to concern that
        running a ceiling fan in the forward direction in winter would create a
        draft and cool occupants. However, if fans are run in the forward
        direction at a slower speed, they are better able to destratify a space
        and create a negligible amount of draft. Running fans in reverse,
        conversely, has been shown to be less effective at destratification and
        create a greater draft.{" "}
      </p>
      <p>
        Big Ass Fans conducted a study to test both methodologies, running a fan
        in the forward or reverse directions. The factors evaluated were
        destratification ability, energy use, and draft risk. The study compared
        three different fan scenarios in: a 60 in. (1.5 m) BAF Haiku fan running
        in the forward direction, a 60 in. (1.5 m) residential ceiling (paddle)
        fan from a common manufacturer running in reverse, and the same 60 in.
        (1.5 m) paddle fan running in the forward direction. Fans were tested in
        a 20 ft. by 20 ft. (6.1 m by 6.1 m) space with adjusted ceiling heights of
        9 ft. (2.7 m), 10 ft. (3 m), and 12 ft. (3.7 m). In order to test for
        destratification ability, the room was heated and allowed to stratify
        for 40 minutes. After the stratification period, the fan was turned on
        for 40 minutes. Temperature was logged at various points in the room
        throughout the duration of the test. Temperature was measured at the
        floor (4 in. (102 mm) above finished floor (AFF)), at typical seated
        height (43 in. (1.1 m) AFF), and at typical standing height (67 in. (1.7
        m) AFF) (the three heights recommended by ASHRAE Standard 2015).
        Temperature was also measured 12 in. (305 mm) below the ceiling. For the
        purposes of this study, destratification was defined as less than a
        1.5°F temperature difference between the ceiling and seated occupant
        level (43 in. (1.1 m) AFF). Energy consumption was measured by recording
        power use of each fan. Draft was tested by running the fan for 10
        minutes to allow airflow to reach steady state values, then measure air
        velocities at various distances radially from the center of the fan.{" "}
      </p>
      <p>
        The results of the study found the Haiku fan running in the forward
        direction was able to destratify the space using much slower speeds than
        the paddle fans running in either the reverse or forward direction. As
        such, the Haiku used 1/12th to 1/16th of the power of the paddle fan to
        achieve thermal mixing (depending on ceiling height).{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/2_3_ComparisonFanPower.png"
          width="100%"
          maxHeight="310px"
          alt="Comparison of Fan Power Consumption"
        />
        <Caption text="Figure 2.3.2a - Comparison of Fan Power Consumption from Destratification white paper." />
      </Container>

      <p>
        Additionally, at the air speeds required to destratify the space, the
        areas where a perceptible draft was recorded were much lower for the
        Haiku fan compared to the paddle fan. Specifically, the Haiku fan
        generated a drafty area directly below the fan equivalent to 0.7% of
        room area. Running the paddle fan in reverse resulted in 42% of the room
        area experiencing a perceptible draft. Therefore, the study concluded
        operating a fan slowly, in the forward direction is more effective for
        destratification, consumes less energy, and creates less draft.{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/2_3_AreaOfDraftRisk.png"
          width="100%"
          alt="% Area of 20-ft. x 20-ft. Room in Draft Risk at Minimum Speed"
        />
        <Caption>
          <p>
            Figure 2.3.2b - “% Area of 20 sq. ft.. (6.1 m<sup>2</sup>) Room in
            Draft Risk at Minimum Mixing Speed” from Lesser Paper
          </p>
        </Caption>
      </Container>

      <p>
        An additional negative impact of reversing a ceiling fan in heating is
        increased heat transfer through the roof. When a reversed fan blows high
        speed air across the bottom of the roof, the stagnant layer of air on
        the bottom of the roof is disturbed and convective heat transfer through
        the roof increases. This increased heat loss from the building requires
        the heating system to run more and results in greater energy
        consumption.{" "}
      </p>
      <p>
        As mentioned in the BAF study, different speeds were required for
        different ceiling fans to destratify the test room. The Haiku fan was
        able to destratify the room at a much lower speed than the paddle fan.
        This was due to both the effectiveness of the air path (forward vs.
        reverse direction) and to the volume of air the fan was moving. Fans are
        rated based on the airflow in CFM (L/s) they can provide. CFM (L/s)
        rating is important for destratification because it relates to the air
        turnover rate (ATH). ATH represents the number of times the entire room
        air volume is recirculated per hour by a ceiling fan. Air volume is
        measured in ft3 (L); therefore, in order to determine the number of
        times this air is recirculated per hour, the airflow rate of the fan in
        CFM (L/s) must be known. In order to select a ceiling fan for
        destratification, a designer must know the CFM (L/s) of air it can
        provide, at an operating speed that minimizes draft risk. It is also
        important the fan can reliably deliver this volume of air. Therefore,
        having a stringent fan rating and testing method is key.{" "}
      </p>

      <Anchor id="2-3-3" />
      <H4 name="2.3.3 How Fan CFM Performance Is Measured" />
      <p>
        The Air Movement and Control Association (AMCA) has published Standard
        230 - Laboratory Methods of Testing Air Circulating Fans for Rating and
        Certification. The purpose of this standard is to establish uniform
        methods for laboratory testing of air circulating fans to determine fan
        performance, including airflow rate, power consumption, efficiency,
        thrust, and efficacy. AMCA 230 was first published in 1999 and has
        changed significantly with each subsequent publication. The 2007, 2012,
        and 2015 versions of the standard revised test methods and airflow
        calculations, among other requirements. Figure 2.3.3a below shows how
        maximum-speed performance data for the same fan tested under each
        version of AMCA 230 would vary. In the 2015 edition, fans are rated at
        20%, 40%, 60%, 80%, and 100% of maximum speed (defined by rpm). This
        version also clarifies which values shall be reported at ambient
        conditions (power, efficacy, and total fan efficiency) and which at
        standard conditions (thrust). Accurate thrust value are important
        because this is used to calculate airflow in CFM (L/s). If thrust
        calculations are uniform between manufacturers, more accurate
        comparisons may be made between airflows provided by different fans. In
        the United States, AMCA 230-15 is the basis of the required test
        standard as defined by federal code.{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-3-3-Table.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 2.3.3a - Impact of Test Method on Circulator Fan Maximum-Speed Performance Data" />
      </Container>

      <Anchor id="2-3-4" />
      <H4 name="2.3.4 Improved Ventilation in Heating" />
      <p>
        In addition to destratification, another benefit ceiling fans provide
        while heating a room is improved ventilation efficiency. ASHRAE Standard
        62.1 Ventilation for Acceptable Indoor Air Quality specifies minimum
        ventilation rates for commercial buildings. It specifies rates for
        various space types depending on floor area and occupancy and provides
        methods for calculating total outdoor airflow to a space. One value
        defined by the Standard that contributes to outdoor airflow requirements
        is zone air distribution effectiveness (Ez). Zone air distribution
        effectiveness is dependent upon air distribution configuration and air
        mixing within the room and it quantifies the fraction of air supplied to
        a room that reaches the occupant breathing zone, defined as 3 to 72 in.
        (75 to 1800 mm) above the floor and more than 2 ft. (600 mm) from walls
        or fixed air-conditioning equipment. The table in Figure 2.3.4a lists
        zone air distribution effectiveness for a number of air distribution
        configurations.{" "}
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-3-4-Table.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 2.3.4a - Zone Air Distribution Effectiveness from ASHRAE Standard 55" />
      </Container>

      <p>
        Ceiling supply of warm air, 15°F (8°C) or more above space temperature,
        and ceiling return is one of the most common air distribution
        configurations in heating. As seen in Figure 2.3.4a, this configuration
        results in an Ez of 0.8 due to stratification causing warm air to stay
        at the ceiling rather than drop to the breathing zone. This means that
        ventilation airflow must be increased by 20% to deliver the required
        amount of outdoor air to the room’s occupants The more outdoor air
        required in a system, the greater the energy consumption required to
        heat the outdoor air. However, in a room that has been destratified by a
        ceiling fan, the air is well-mixed and an Ez of 1 can be used in outdoor
        air calculations, as shown by CFD analysis and approved by the authority
        having jurisdiction (AHJ). When an Ez of 1.0 is used in lieu of 0.8, no
        increase in outdoor airflow rate is required.{" "}
      </p>
    </div>
  );
}

export default C2S3;
