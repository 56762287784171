import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import SubmitButton from "../UI-Kit/SubmitButton";
import PropTypes from "prop-types";
import axios from "axios";
import saveAs from "file-saver";

const apiUrl =
  "https://212guf91n1.execute-api.us-east-2.amazonaws.com/latest/packages";

const StyledCard = styled(Card)`
  && {
    background-color: #000;
    width: 100%;
    color: #fff;
    margin-top: 20px;
    border-radius: 5px;
  }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
&& {
  padding 5px 0px 5px 0px;
}
`;

const StyledCheckbox = styled(Checkbox)`
  && {
    margin-left: 20px;
    fill: #fff;
    color: #fff;
  }
`;

export default function CheckboxLabels(props) {
  const [state, setState] = React.useState({
    loading: false,
    options: {
      cutSheet: true,
      installGuide: true,
      bim: true,
      threePartSpec: true,
      elevationPlanViews: true,
    },
  });

  const handleChange = (name) => (event) => {
    setState({
      ...state,
      options: { ...state.options, [name]: event.target.checked },
    });
  };

  const handleSubmit = (name) => {
    //event.preventDefault()

    setState({ ...state, loading: true });
    let productName = "";

    switch (props.data.fanRecommendation.fanType) {
      case "Powerfoil X3.0":
        productName = "powerfoilx3";
        break;
      case "Essence":
        productName = "essence";
        break;
      case "Haiku":
        productName = "haiku";
        break;
      case "Powerfoil 8":
        productName = "powerfoil8";
        break;
      case "Basic 6":
        productName = "basic6";
        break;
      case "i6":
        productName = "i6";
        break;
      case "Powerfoil D":
        productName = "powerfoild";
        break;
      default:
        productName = "powerfoilx3";
    }

    const packages = [];
    Object.keys(state.options).forEach((key) => {
      if (state.options[key]) {
        packages.push(key);
      }
    });
    axios
      .post(apiUrl, {
        product: productName,
        packages,
      })
      .then((result) => {
        setState({ ...state, loading: false });
        // Save using the signed url to the bucket
        if (result.data) {
          saveAs(result.data, new Date() + ".zip");
        }
      })
      .catch(error => {
        setState({ ...state, loading: false });
        alert(error)
        console.log(error)
      });
  };

  return (
    <FormGroup row>
      <StyledCard>
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              checked={state.options.cutSheet}
              onChange={handleChange("cutSheet")}
              value="cutSheet"
              color="primary"
            />
          }
          label="Cut Sheet"
        />
      </StyledCard>
      <StyledCard>
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              checked={state.options.installGuide}
              onChange={handleChange("installGuide")}
              value="installGuide"
              color="primary"
            />
          }
          label="Install Guide"
        />
      </StyledCard>
      <StyledCard>
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              checked={state.options.bim}
              onChange={handleChange("bim")}
              value="bim"
              color="primary"
            />
          }
          label="BIM"
        />
      </StyledCard>
      <StyledCard>
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              checked={state.options.threePartSpec}
              onChange={handleChange("threePartSpec")}
              value="threePartSpec"
              color="primary"
            />
          }
          label="Three-Part Spec"
        />
      </StyledCard>
      <StyledCard>
        <StyledFormControlLabel
          control={
            <StyledCheckbox
              checked={state.options.elevationPlanViews}
              onChange={handleChange("elevationPlanViews")}
              value="elevationPlanViews"
              color="primary"
            />
          }
          label="Elevation Plan Views"
        />
      </StyledCard>

      <SubmitButton
        type="submit"
        loading={state.loading}
        onClick={handleSubmit}
        text="Download Package"
      />
    </FormGroup>
  );
}

CheckboxLabels.propTypes = {
  data: PropTypes.object,
};
