import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const StyledButton = styled(Button)`
  && {
    min-width: 200px;
    font-weight: 900;
    border-radius: 15px;
    margin-top: 50px;
    font-family: industry, sans-serif;
    && :hover {
    }
  }
`;

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  && {
    width: 16px;
    margin-left: 5px;
  }
`;

function SubmitButton(props) {
  return (
    <StyledButton
      color="primary"
      variant="contained"
      href={props.href}
      onClick={props.onClick}
      disabled={props.loading}
    >
      {props.loading ? (
        <CircularProgress color="inherit" size={24} />
      ) : (
        <>
          {props.text} <StyledArrowForwardIcon />{" "}
        </>
      )}
    </StyledButton>
  );
}

SubmitButton.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};
export default SubmitButton;
