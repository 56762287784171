import React from "react";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListHeader from "./UI-Kit/ListHeader";
import ListItem from "./UI-Kit/ListItem";
import BookIcon from "@material-ui/icons/LibraryBooks";
import TuneIcon from "@material-ui/icons/Tune";
import BubbleIcon from "@material-ui/icons/QuestionAnswer";
import Copyright from "./UI-Kit/Copyright";
import MobileRecommendationButton from "./UI-Kit/Mobile-Recommendation";

const isMobile = window.innerWidth <= 500;
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  header: {
    color: "#242424",
    fontSize: "20px",
    fontWeight: "700"
  },
  Column1: {
    padding: theme.spacing(4),
    textAlign: "left",
    color: "#242424"
  },
  Column2: {
    padding: theme.spacing(4),
    textAlign: "left",
    color: "#242424"
  },
  Column3: {
    padding: theme.spacing(4),
    textAlign: "left",
    color: "#242424"
  },
  mt30: {
    marginTop: "30px"
  },
  icon: {
    float: "left",
    marginRight: "5px",
    fill: "#fcc425",
    color: "#fcc425"
  }
}));

function Home() {
  const classes = useStyles();

  return (
    <div>
      <div className="main-content">
        <Card className="card">
          <CardMedia
            image={`${window.location.origin}/main-header.jpg`}
            className="main-header"
          />
          <div className="overlay">
            <h1 className="overlayText">Your Guide to Comfort</h1>
          </div>
        </Card>

        <div className={classes.root}>
          <Grid container spacing={2} className={classes.mt30}>
            <Grid item lg>
              <BookIcon className={classes.icon} />
              <ListHeader className={classes.header} name="Resources" />
              <List>
                <a
                  href="https://www.bigassfans.com/cow-production-stress-calculator/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Cow Production Stress Calculator" />
                </a>
                <a
                  href="https://www.bigassfans.com/productivity-calculator/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Productivity Calculator" />
                </a>
                <a
                  href="https://www.bigassfans.com/heat-destratification/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Heat Destratification" />
                </a>
                <a
                  href="https://www.bigassfans.com/summer-cooling/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Summer Cooling" />
                </a>
                <a href="/bullitt-center">
                  <ListItem name="Case Studies" />
                </a>
                <a href="/vertical-marketing">
                  <ListItem name="Vertical Market Considerations" />
                </a> 
                <a href="/submittals">
                  <ListItem name="Submittals" />
                </a>
              </List>
            </Grid>
            <Grid item lg>
              <TuneIcon className={classes.icon} />
              <ListHeader
                className={classes.header}
                name="Architects &amp; Engineers"
              />
              <List>
                <a
                  href="https://www.bigassfans.com/aedownloads/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Technical Downloads" />
                </a>
                <a
                  href="https://www.bigassfans.com/building-credits/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Building Credits" />
                </a>
                <a
                  href="https://www.bigassfans.com/continuing-education/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Continuing Education" />
                </a>
              </List>
            </Grid>
            <Grid item lg>
              <BubbleIcon className={classes.icon} />
              <ListHeader
                className={classes.header}
                name="Support &amp; Services"
              />
              <List>
                <a
                  href="https://www.bigassfans.com/support/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Product Support" />
                </a>
                <a
                  href="https://www.bigassfans.com/order-status/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Order Status" />
                </a>
                <a
                  href="https://www.bigassfans.com/support/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Aftermarket Service" />
                </a>
                <a
                  href="https://www.bigassfans.com/returns/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Returns and Order Cancellation Policy" />
                </a>
                <a
                  href="https://www.bigassfans.com/factory-certified-installation/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Factory Installation" />
                </a>
                <a
                  href="https://www.bigassfans.com/certified-installer-program/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Certified Installer Program" />
                </a>
                <a
                  href="https://www.bigassfans.com/haiku-affiliate-program/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ListItem name="Haiku Affiliate" />
                </a>
              </List>
            </Grid>
          </Grid>
        </div>
        {isMobile ? <MobileRecommendationButton /> : null}
        <Copyright
          text="Copyright 2019 Delta T, LLC. All rights reserved"
          className="mt75"
        />
      </div>
    </div>
  );
}

export default Home;
