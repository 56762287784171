import React, {useState} from 'react';
import ProjectDetails from './ProjectDetails';
import Notes from './Notes';
import Logo from './Logo'

function makeLinks(obj){
    return Object.keys(obj).map(e => {
        return <a href={obj[e]}>{e}</a>
    });
}

function makeOpts(list){
    return list.map(e => {
        return(<option key={e} value={e}>{e}</option>);
    });
}

export default function ESeriesTemplate(props){

    let fan = props.fan;
    const [size, setSize] = useState("");
    const [volts, setVolts] = useState("");
    const [tube, setTube] = useState("");

    return(
        <div>
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
                <div>
                <ProjectDetails />
                <table>
                    <thead><th colSpan="2">Fan Details: </th></thead>
                    <tbody>
                        <tr><td>Quantity: </td><td><input type="number" min="1" required></input></td></tr>
                        {fan.sizes && <tr><td>Size (ft): </td><td><select required onChange={e => {setSize(e.target.value)}}><option></option>{makeOpts(fan.sizes)}</select></td></tr>}                        
                        <tr><td>Voltage/Phase: </td><td><select required onChange={e => {setVolts(e.target.value)}}>
                            <option></option>
                            {fan.volts && fan.volts.map(e => (<option>{e}</option>))}
                        </select></td></tr>
                        {fan.weights && <tr><td>Standard Weight: </td>{fan.weights[size]} lbs</tr>}
                        {fan.rpm[size] && <tr><td>Max RPM: </td>{fan.rpm[size]} RPM</tr>}
                        {fan.rcs && <tr><td>Supply Circuit Size: </td><td>{fan.weights && fan.rcs[size + " " + volts]} A</td></tr>}
                        {fan.fla && <tr><td>Full Load Amperage: </td><td>{fan.weights && fan.fla[size + " " + volts]} A</td></tr>}
                        {fan.maxSound && <tr><td>Max Sound Level: </td><td>{fan.maxSound} dBA</td></tr>}
                    </tbody>
                </table>
                {fan.hb && <table>
                    <thead><th colSpan="2">Mounting Hardware: </th></thead>
                    <tbody>
                        <tr><td>Extension Tube: </td><td><select onChange={e => setTube(e.target.value)} required><option></option>{makeOpts(Object.keys(fan.hb))}</select></td></tr>
                        {fan.mounts && <tr><td>Type </td><td><select required><option></option>{makeOpts(fan.mounts)}</select></td></tr>}
                    </tbody>
                </table>}
                <table>
                    <thead><th colSpan="2">Critical Dimensions: </th></thead>
                    <tbody>
                        <tr><td>Foil to Mount (Hb): </td><td>{fan.hb[tube]} in</td></tr>
                        <tr><td>Width (W)</td><td>{size*12} in</td></tr>
                    </tbody>
                </table>
                </div>
                <div>
                    {fan.name && <Logo name={"E"+size} />}
                    {fan.features && 
                        <table><thead><th colSpan="2">Features</th></thead>
                            <tbody>{fan.features.map(e => (<tr><td>{e}</td></tr>))}</tbody>
                        </table>}
                    <Notes />
                    {fan.links && makeLinks(fan.links)}
                </div>
            </div>
            <div style={{width:"50%", margin:"auto", display:"grid", height:"100", alignContent:"center" }}><img style={{maxHeight:150, maxWidth:600}} alt={`${fan.name} dwg`} src={`${window.location.origin}/technicalDrawings/${fan.name}.png`} /></div>
        </div>
    );
}