import React from "react";
import H2 from "../UI-Kit/H2";
import Anchor from "../UI-Kit/Anchor";

function C1S3() {
  return (
    <div className="chapter-container">
      <Anchor id="1-3" />
      <H2 name="1.3 How to Use the Guide" />
      <p>
        This guide has been divided into four chapters: 1) Introduction, 2)
        Background &amp; Rationale, 3) Applications and Examples, and 4) Case
        Studies. Chapter 1 introduces the benefits of ceiling fans and the
        intention of the guide. Chapter 2 will provide background information on
        the science behind thermal comfort and how to use fans to improve an
        HVAC design. Chapter 3 contains several examples illustrating the
        principles described in Chapter 2, as well as more detailed information
        on specific applications of fans. Chapter 4 features case studies that
        delve deeper into real-world applications of ceiling fans. The guide has
        been written in such an order that a user could read it from start to
        finish and ideally gain a good understanding of ceiling fan systems and
        applications. The authors also recognize users may wish to jump between
        sections based on prior knowledge and/or specific project needs. As
        such, the guide attempts to group and label information in a logical and
        clear manner, such that users may easily identify areas of interest and
        read the relevant sections.{" "}
      </p>
      <p>
        As this guide is anticipated to be used by a variety of readers, so too
        is the information in the guide varied. Owners will find information on
        operating cost reduction strategies and which building types are
        well-suited to incorporate fans. Architects will see examples of fan
        layouts in various spaces and learn about specification options that
        allow fans to be selected that are well-integrated into a space.
        Engineers will understand the design decisions that must be made to
        incorporate ceiling fans into an HVAC design and the impacts fans have
        on thermal comfort. Overall, the guide aims to paint a full picture of
        the methods and benefits of ceiling fans to a building design.{" "}
      </p>
    </div>
  );
}

export default C1S3;
