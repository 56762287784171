import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";

function C2S8() {
  return (
    <div className="chapter-container">
      <Anchor id="2-8-1" />
      <H2 name="2.8 Acoustics" />
      <H4 name="2.8.1 Test Procedures and Acoustical Data" />
      <p>
        At this time, there is no standard method of test that is used across
        the ceiling fan industry. Sound pressure values are measured in a
        variety of different procedures and test environments. Standards such as
        ANSI/AMCA Standard 300-14, Reverberant Room Method for Sound Testing of
        Fans, require extremely large test chambers not readily available to
        allow for certified sound testing. AMCA is in the process of forming a
        committee to investigate potential changes to existing sound standards
        to allow for the creation of accurate, reliable, third-party verified
        sound data. Until that test method is created, it is recommended any
        published sound data should be considered to be proprietary and should
        only be used on a comparative basis between different fan models from
        the manufacturer. Additionally, fan components, such as motors, may be
        selected to accommodate acoustic requirements. See Specification section
        for further information.{" "}
      </p>
    </div>
  );
}

export default C2S8;
