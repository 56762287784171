import React from "react";
import H2 from "../UI-Kit/H2";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C3S8() {
  return (
    <div className="chapter-container">
      <Anchor id="3-8" />
      <H2 name="3.8 Financial" />
      <p>
        From a financial standpoint, large diameter ceiling fans have the
        potential to reduce both first cost and utility cost of a building.
        However, first cost savings is variable and will not be the same for
        every project. For instance, the gym example in Section 3.5 resulted in
        a net first cost reduction. Therefore, excluding a net increase in
        utility costs, the inclusion of ceiling fans is financially justified on
        day one. When factoring the utility savings that will be achieved with
        the use of a ceiling fan, the savings will continue to be realized after
        construction is complete. Justification of a ceiling fan in situations
        such as this is obvious.
      </p>
      <p>
        In projects where the first cost of the project with ceiling fans is
        higher than the alternative without fans, there is a potential for the
        utility cost savings to quickly justify any additional cost associated
        with the addition of the ceiling fans. Using the Atrium Example from
        above, there is an annual utility savings of $600. Because there is
        little HVAC scope reduction for this example, the net first cost is an
        increase on the base project cost as the cost of adding a fan will
        exceed the cost saved by reducing air distribution scope. Assuming the
        first cost cost net increase is approximately $4,500, the simple payback
        for the ceiling fan is 7.5 years. In practice, simple payback periods
        less than 7 years are generally viewed as a good investment.
      </p>
      <p>
        First cost and energy conservation are not the only ways a ceiling fan
        reduces operating expenses. When employees are hot, productivity
        suffers. Overheated employees require more frequent trips to directional
        fans and water coolers. Stress levels are increased and motivation
        falls. The addition of ceiling fans improves working conditions by
        cooling employees. The Big Ass Fans website includes a tool for
        estimating the cost of lost productivity due to uncomfortable employees
        (detailed in Section 2.2.3). The tool uses weather data by state, the
        number of employees, the hourly salary per employee, and the number of
        hours worked per employee per week. It calculates “Money Lost Due to
        Lowered Productivity”, the amount of money a Big Ass Fan could save per
        year, “The Annual Loss Due to Heat”, and the percent a Big Ass Fan could
        reduce lost productivity. See Figure 3.8a for a sample calculation.
      </p>
      <p>
        More detailed calculations on the impact of thermal comfort on
        productivity can be found in the ASHRAE Journal article “Simulated
        Impact of Energy Codes: Thermal Comfort in Heated-and-Ventilated-Only
        Warehouses,” by Christian Taber and Donald Colliver, Ph.D., P.E.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-8a.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.8a - Above are sample results from the BAF Productivity Calculator for the state of Missouri." />
      </Container>

      <p>
        A combined financial calculation that includes numerous factors such as
        first cost, fan operating costs, replacement of inefficient fans,
        destratification energy savings, employee productivity, and
        environmental impacts provide a more holistic view of the potential
        impacts of ceiling fans on a project. See Figure 3.8b for a sample
        calculation of a heated manufacturing facility that is replacing fifteen
        column fans with five twenty foot diameter ceiling fans.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-8b.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.8b - Above are sample results for a manufacturing retrofit project." />
      </Container>

      <p>
        Detailed financial calculations that incorporate the time value of money
        reflect the total cost of ownership may be required to justify a fan
        project as illustrated by the length of the simple payback in the atrium
        example above. See Figure 3.8c for the detailed financial calculations
        for the manufacturing project over a five year and ten year time period.
        Internal rate of return (IRR) is essentially the interest rate at which
        the net present value of all the cash flows from the project equals
        zero. Internal rate of return is a commonly used metric to determine the
        merit of a financial decision. In this example, the 10-year IRR is 33%
        which would be compared to other potential projects and typically, the
        highest IRR project(s) would be selected. Net present value (NPV) is
        used to determine the current value of all future cash flows generated
        by the project. NPV is used to analyze the profitability of a projected
        investment in today’s dollars. In this example, the 5-year NPV is a
        positive $12,713 which indicates that the project has paid for itself in
        less than 5 years and generated an additional $12,713 in savings in
        today’s dollars.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-8c.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.8c - Above are sample results from the BAF Productivity Calculator for the state of Missouri." />
      </Container>
    </div>
  );
}

export default C3S8;
