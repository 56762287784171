import React from 'react';
import Logo from './Logo';
import ProjectDetails from './ProjectDetails';
import Notes from './Notes';

function makeLinks(obj){
    return Object.keys(obj).map(e => {
        return <div><a href={obj[e]}>{e}</a></div>
    });
}

const makeTable = (head, list) => {
    let out = [];

    if(Array.isArray(list)){
        list.forEach((e)=>{
            out.push(<tr><td>{e}</td></tr>);
        });
        return(
            <table>
                <tr><th colSpan="2">{head}: </th></tr>
                <tbody>
                    {out}
                </tbody>
            </table>
        );
    }
}

export default function BAFConTemplate(props){
    let fan = props.fan;

    return(
        <div>
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
                <div>
                <ProjectDetails />
                <table>
                    <thead><th colSpan="2">Fan Details: </th></thead>
                    <tbody>
                        <tr><td>Single Control Quantity: </td><td><input type="number" min="1" required></input></td></tr>
                        <tr><td>Multi Control Quantity: </td><td><input type="number" min="1" required></input></td></tr>
                    </tbody>
                </table>
                <Notes />
                </div>
                <div>
                <div>
                    {fan === undefined && <Logo name={"Generic Name"} />}
                    {fan !== undefined && <Logo name={fan.name} />}
                    {fan.features && makeTable("Features", fan.features)}
                </div>
                    {fan.links && makeLinks(fan.links)}
                </div>
            </div>
            <div style={{width:"50%", margin:"auto", display:"grid", height:"100", alignContent:"center" }}><img style={{maxHeight:300, maxWidth:600}} alt={`${fan.name + 0} dwg`} src={`${window.location.origin}/technicalDrawings/${fan.name  + 0}.png`} /></div>
            <div style={{width:"50%", margin:"auto", display:"grid", height:"100", alignContent:"center" }}><img style={{maxHeight:300, maxWidth:600}} alt={`${fan.name + 1} dwg`} src={`${window.location.origin}/technicalDrawings/${fan.name  + 1}.png`} /></div>
        </div>
    );
}