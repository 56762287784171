import React from 'react';
import RecommendationButton from './RecommendationButton';


function MobileRecommendationButton(props)  {
    return (
        <div className="mt40 mb40 center">
        <RecommendationButton
          href="/recommendation"
          text="Get Recommendation"
        />
      </div>
    );
}

export default MobileRecommendationButton;