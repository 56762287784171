import React, {useState} from 'react';
import ProjectDetails from './ProjectDetails';
import Notes from './Notes';
import Logo from './Logo'

function makeLinks(obj){
    return Object.keys(obj).map(e => {
        return <a href={obj[e]}>{e}</a>
    });
}

function makeOpts(list){
    return list.map(e => {
        return(<option key={e} value={e}>{e}</option>);
    });
}

export default function IRHTemplate(props){

    let fan = props.fan;
    const [model, setModel] = useState("");
    const [fuelType, setFuelType] = useState("");

    return(
        <div>
            <div style={{display:"grid", gridTemplateColumns:"1fr 1fr"}}>
            <div>
                <ProjectDetails />
                <table>
                    <thead><th colSpan="2">Fan Details: </th></thead>
                    <tbody>
                        <tr><td>Quantity: </td><td><input type="number" min="1" required></input></td></tr>
                        {fan.models && <tr><td>Model: </td><td><select required onChange={e => {setModel(e.target.value)}}><option></option>{makeOpts(fan.models)}</select></td></tr>}
                        {model && fan.fuelType && <tr><td>Fuel Type: </td><td><select required onChange={e => {setFuelType(e.target.value)}}><option></option>{model && makeOpts(fan.fuelType[model])}</select></td></tr>}
                        {fan.tubeLength && <tr><td>Alum. Steel Tubing Length:</td><td>{fan.tubeLength[model]}'</td></tr> }
                        {fan.models && <tr><td>Input [Btu/h]:</td><td>{model} MBH</td></tr>}
                        {fan.inletPressureMin && <tr><td>Inlet Pressure Min. [in wc]:</td><td>{fan.inletPressureMin[model + fuelType]}</td></tr>}
                        {fan.inletPressureMax && <tr><td>Inlet Pressure Max. [in wc]:</td><td>{fan.inletPressureMax[model + fuelType]}</td></tr>}
                        {fan.gasConnection && <tr><td>Gas Connection:</td><td>{fan.gasConnection[model]}" NPT</td></tr>}
                        {fan.combustionAirInlet && <tr><td>Combustion Air Inlet:</td><td>{fan.combustionAirInlet}" diameter</td></tr>}
                        {fan.exhaustFlue && <tr><td>Exhaust Flue:</td><td>{fan.exhaustFlue}" diameter</td></tr>}
                        {fan.electricalRating && <tr><td>Electrical Rating: </td><td>{fan.electricalRating}</td></tr>}
                    </tbody>
                </table>
                <table>
                    <thead><th colSpan="2">Critical Dimensions: </th></thead>
                    <tbody>
                        {fan.criticalA && <tr><td>Min Length of Tube (A):</td><td>{fan.criticalA[model]}'</td></tr> }
                        {fan.criticalB && <tr><td>Min Length of U-Tube (B):</td><td>{fan.criticalB[model]}'</td></tr> }
                    </tbody>
                </table>
                <Notes />
            </div>
            <div>
                <Logo name={model ? `IRH-${model}` : 'IRH'} />
                {fan.additions && <table>
                    <thead><th colSpan="2">Optional Accessories:	</th></thead>
                    <tbody>
                        {fan.additions.map(e => (<tr><td><select><option></option>{makeOpts(fan.additions)}</select></td></tr>))}
                    </tbody>
                </table>}
                {fan.features && 
                        <table><thead><th colSpan="2">Features</th></thead>
                            <tbody>{fan.features.map(e => (<tr><td>{e}</td></tr>))}</tbody>
                        </table>}
                <table >
                    <thead><tr><th colSpan="2">Technical Notes </th></tr></thead>                    
                    <tbody>
                        <tr><td >Before installation and operation of heating equipment, read and understand the current installation, operation, and service manual.</td></tr>
                        <tr><td>Hang heater with materials with a minimum working load of 75 lbs (33 kg).</td></tr>
                        <tr><td>See install manual for more information regarding required clearances to combustables. Maintain clearance to combustables at all times for safety.</td></tr>
                        <tr><td>All data is subject to change.</td></tr>
                        <tr><td>Data indicated in this document were correct at the time of printing with the exception of clerical and/or printing errors.</td></tr>
                    </tbody>
                </table>
                {fan.links && makeLinks(fan.links)}
            </div>
            </div>
            <div style={{width:"50%", margin:"auto", display:"grid", height:"100", alignContent:"center" }}><img style={{maxHeight:250, maxWidth:650}} alt={`${fan.name} dwg`} src={`${window.location.origin}/technicalDrawings/${fan.name}.png`} /></div>
        </div>
    );
}