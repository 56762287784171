import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { NavHashLink as NavLink } from "react-router-hash-link";
import ListItem from "@material-ui/core/ListItem";
import RecommendationNavItem from "../../UI-Kit/RecommendationNavItem";

function MobileRecommendationLink(props) {
  return (
    <Fragment>
      <ListItem button>
        <NavLink to="/recommendation" onClick={props.onClick}>
          <RecommendationNavItem name="Get Recommendation" />
        </NavLink>
      </ListItem>
    </Fragment>
  );
}
MobileRecommendationLink.propTypes = {
  onClick: PropTypes.func
};
export default MobileRecommendationLink;
