import React from "react";
import styled from "styled-components";

const CustomTable = styled.table`
  &&& {
    text-align: left;
    width: 100%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  }
  th {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 20px 20px;
  }
  td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 10px 20px;
  }
`;

function UnitsTable() {
  return (
    <CustomTable>
      <tbody>
        <tr>
          <th>Description</th>
          <th>IP Units</th>
          <th>SI Units</th>
        </tr>
        <tr>
          <td>Air Volume</td>
          <td>
            Cubic feet - ft.<sup>3</sup>
          </td>
          <td>Liters - L</td>
        </tr>
        <tr>
          <td>Airflow</td>
          <td>Cubic feet per minute - CFM</td>
          <td>Liters per second - L/s</td>
        </tr>
        <tr>
          <td>Airspeed</td>
          <td>Feet per minute - fpm</td>
          <td>Meters per second - m/s</td>
        </tr>
        <tr>
          <td>Area</td>
          <td>
            Square feet - ft.<sup>2</sup>
          </td>
          <td>
            Square meters - m<sup>2</sup>
          </td>
        </tr>
        <tr>
          <td>Clothing insulation - clo</td>
          <td>
            1 clo = 0.88 ft.<sup>2</sup>*h*<sup>°</sup>F/Btu
          </td>
          <td>
            1 clo = 0.155 m<sup>2</sup>*<sup>°</sup>C/W
          </td>
        </tr>
        <tr>
          <td>Cost</td>
          <td>$ (US Dollars)</td>
          <td>-</td>
        </tr>
        <tr>
          <td>Diameter - ⌀</td>
          <td>Feet or inches - ft. or in.</td>
          <td>Meters or millimeters - m or mm</td>
        </tr>
        <tr>
          <td>Distance</td>
          <td>Feet or inches - ft. or in.</td>
          <td>Meters or millimeters - m or mm</td>
        </tr>
        <tr>
          <td>Energy</td>
          <td>Joules - J</td>
          <td>Watts - W</td>
        </tr>
        <tr>
          <td>Fan Power</td>
          <td>Brake Horsepower - BHP</td>
          <td>Kilowatt - kW</td>
        </tr>
        <tr>
          <td>Metabolic rate - met</td>
          <td>
            1 met = 18.4 Btu/(h*ft.<sup>2</sup>)
          </td>
          <td>
            1 met = 58.2 W/m<sup>2</sup>
          </td>
        </tr>
        <tr>
          <td>Power</td>
          <td>British thermal units per hour - Btuh</td>
          <td>Watts - W</td>
        </tr>
        <tr>
          <td>Static Pressure</td>
          <td>Inches water column - in.w.c.</td>
          <td>Pascals - Pa</td>
        </tr>
        <tr>
          <td>Temperature</td>
          <td>°F</td>
          <td>°C</td>
        </tr>
        <tr>
          <td>Thermal Transmittance</td>
          <td>
            Btu/(h*ft.<sup>2</sup>*°F)
          </td>
          <td>
            W/(m<sup>2</sup>*K)
          </td>
        </tr>
        <tr>
          <td>Thrust</td>
          <td>Pounds force - lbf</td>
          <td>Newtons - N</td>
        </tr>
        <tr>
          <td>Time</td>
          <td>Hours, Minutes, Seconds - h, m, s</td>
          <td>Hours, Minutes, Seconds - h, m, s</td>
        </tr>
        <tr>
          <td>Weight</td>
          <td>Pounds - lbs</td>
          <td>Kilograms - kg</td>
        </tr>
      </tbody>
    </CustomTable>
  );
}

export default UnitsTable;
