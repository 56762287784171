import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";

function C1S2() {
  return (
    <div className="chapter-container">
      <Anchor id="1-2-1" />
      <H2 name="1.2 Goals of the Guide" />
      <H4 name="1.2.1 Educate" />
      <p>
        This guide is intended to educate owners, architects, and engineers
        about why and how to incorporate ceiling fans into building designs.
        After reading this guide, users should have a good understanding of the
        science behind thermal comfort, air distribution, and energy
        consumption. Readers will also understand how ceiling fans can
        contribute to thermal comfort, improve air distribution, and reduce
        energy consumption. Furthermore, they will understand how incorporating
        ceiling fans as a core component of an HVAC system provides more
        holistic benefits than inclusion as an optional “add-on.”
      </p>
      <p>
        Users of the guide will learn about the various fan types available and
        which fan best suits a specific application. Readers will understand how
        to specify a fan to meet project requirements, and how to evaluate a fan
        submittal to determine if it truly meets those requirements.{" "}
      </p>
      <Anchor id="1-2-2" />
      <H4 name="1.2.2 Provide Tools" />
      <p>
        The guide will provide readers with the tools necessary to effectively
        incorporate ceiling fans into building designs in a safe, code
        compliant, and cost-effective manner. These will include guidelines for
        safe installation, summaries of applicable codes and standards, and
        resources for assessing the financial implications of a design.
        Additionally, the guide highlights tools available from Big Ass Fans
        such as computational fluid dynamics (CFD) analysis and various online
        calculators.
      </p>
      <Anchor id="1-2-3" />
      <H4 name="1.2.3 Give Examples" />
      <p>
        Examples in the guide will show the reader how to apply their new
        knowledge and utilize the tools described in the guide in real life
        applications. Examples will include thermal comfort calculations, fan
        layouts, system designs, financial justifications, and more. This
        section will highlight the differences between designing a “standard”
        HVAC system and an HVAC system with ceiling fans, and compare factors
        such as layout complexity, energy consumption, and first costs between
        the two system types.{" "}
      </p>
    </div>
  );
}

export default C1S2;
