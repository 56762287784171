import React, { Component, Fragment } from "react";
import C2S1 from "./Chapter-2/2.1";
import C2S2 from "./Chapter-2/2.2";
import C2S3 from "./Chapter-2/2.3";
import C2S4 from "./Chapter-2/2.4";
import C2S5 from "./Chapter-2/2.5";
import C2S6 from "./Chapter-2/2.6";
import C2S7 from "./Chapter-2/2.7";
import C2S8 from "./Chapter-2/2.8";
import C2S9 from "./Chapter-2/2.9";
import C2S10 from "./Chapter-2/2.10";
import C2S11 from "./Chapter-2/2.11";
import C2S12 from "./Chapter-2/2.12";
import Copyright from "../components/UI-Kit/Copyright";
import MobileRecommendationButton from "./UI-Kit/Mobile-Recommendation";

const isMobile = window.innerWidth <= 500;
class ScrollToTopOnMount extends Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

function Chapter2() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className="main-content">
        <C2S1 />
        <C2S2 />
        <C2S3 />
        <C2S4 />
        <C2S5 />
        <C2S6 />
        <C2S7 />
        <C2S8 />
        <C2S9 />
        <C2S10 />
        <C2S11 />
        <C2S12 />
        {isMobile ? <MobileRecommendationButton /> : null}
        <Copyright text="Copyright 2019 Delta T, LLC. All rights reserved"  className="mt75"/>
      </div>
    </Fragment>
  );
}

export default Chapter2;
