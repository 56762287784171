import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';


const StyledH2 = styled.h2 `
    color: #242424;
    line-height: 1;
    text-transform: uppercase;  
    font-size:2.25em;  
`

function H2(props)  {
    return (
        <StyledH2>{props.name}</StyledH2>
    );
}

H2.propTypes = {
    name: PropTypes.string
  };

export default H2;