import React, { Fragment } from "react";
import { HashLink } from "react-router-hash-link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

function DropdownC4S3() {
  return (
    <Fragment>
      <ListItem button>
        <HashLink
          scroll={el =>
            el.scrollIntoView({ behavior: "smooth", block: "start" })
          }
          to="/coastal-biology-building"
        >
          <ListItemText primary="4.3 Coastal Biology Building" className="white" />
        </HashLink>
      </ListItem>
    </Fragment>
  );
}

export default DropdownC4S3;
