import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C3S3() {
  return (
    <div className="chapter-container">
      <Anchor id="3-3-1" />
      <H2 name="3.3 CFD &amp; Layouts" />
      <H4 name="3.3.1 Example Layouts and CFD Analyses" />
      <p>
        As target air speeds and space characteristics change from project to
        project, it is key to build a design around a specific space. Engineers
        should verify that fan selections and layouts provide proper air speeds
        at the target velocity for desired areas in a room by requesting an
        analysis be performed using a CFD tool, such as SpecLab. Below are
        example layouts and CFD analysis results from SpecLab for a variety of
        space types. These examples demonstrate some successful techniques for
        creating a space-specific fan system design.
      </p>
      <div className="chapter-content">
        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          3.3.1.1 Example: School Gym
        </h5>
        <p>
          An engineer has been asked to provide a circulator fan design for a
          school gymnasium. The gym is 116 ft. x 94 ft. (35 m x 29 m) with a
          ceiling height of 29 ft. (9 m). Summer indoor conditions are 80°F
          (27°C) and 50% RH. Taking into account metabolic rate and clo, what
          would be an appropriate fan selection and layout for this space?
        </p>
        <p>
          One proposed solution is shown in Figure 3.3.1.1b below. The gym is a
          large room with very few obstructions, as such, a single, large
          diameter fan has been selected and placed in the center of the gym.
          For this particular application, an 18 ft. (5.5 m) diameter fan has
          been selected and mounted with a blade height of 22 ft. (6.7 m). This
          design will then be evaluated using CFD analysis in SpecLab to
          determine air speeds and airflow profile. Higher air speeds are
          desired in this space due to occupants generally having high activity
          levels. According to the CBE Comfort Tool, typical metabolic rates for
          individuals doing calisthenics is 3.5 met. Playing a sport, such as
          basketball, typically results in a rate of 6.3 met. Clothing level
          corresponding to shorts and a t-shirt is 0.36 clo.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1a.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.1a - School Gym Example - Gym Floor Plan" />
        </Container>

        <p>
          The following images are outputs from the CFD analysis conducted in
          SpecLab. Figures 3.3.1.1c through 3.3.1.1i, show air speeds at
          different points and heights throughout the room. CFD analysis results
          are summarized in Figure 3.3.1.1j.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1b.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.1b - School Gym Example - Fan Layout and Schedule" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1c-1d.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 3.3.1.1c and 3.3.1.1d - School Gym CFD Air Speed Example - Room section cut through the center of the ceiling fan at the the x-z and y-z planes." />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1d-1e.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 3.3.1.1e and 3.3.1.1f - School Gym CFD Air Speed Example - Plan view of room cut at 24 in. (0.6 m) on the left and 04 in. (0.1 m) on the right." />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1f-1g.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.1g and 3.3.1.1h - School Gym CFD Air Speed Example - Plan view of room cut at 43 in. (1.1 m) on the left and 67 in. (1.7 m) on the right." />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1h.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 3.3.1.1i - School Gym Air Speed Example - The above image traces the path the air follows as it travels through the room. The color of each path indicates the air speed at that point along the path." />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-1i.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.1j - School Gym Example - Performance Summary" />
        </Container>

        <p>
          Figures 3.3.1.1e through 3.3.1.1h show the air speed profile at four
          different heights: 4 in. (0.1 m) AFF, 24 in. (0.6 m) AFF, 43 in. (1.1
          m) AFF, and 67 in. (1.7 m) AFF. These are the typical test heights for
          seated and standing occupants from ASHRAE Standard 55 thermal comfort
          calculations. As illustrated in the images, the highest air speeds are
          felt closest to the floor. Figure 3.3.1.1j lists the minimum, maximum,
          and average air speeds, as well as the corresponding cooling effects
          for a standing occupant. It also calculates cooling coverage, which is
          defined by ASHRAE Standard 216 as the portion of the test area where
          Average Air Speed exceeds 85 fpm (0.43 m/s). Other outputs include
          PMV, PPD, and SET, as defined by ASHRAE Standard 55, which allow the
          user to evaluate thermal comfort in the space.
        </p>
        <p>
          As indicated in Figure 3.3.1.1j, the fan provides an average air speed
          of 224 fpm (1.1 m/s), resulting in an average cooling effect of 27°F
          (15.0°C). The cooling coverage percent is very high, indicating that
          the vast majority of the space is experiencing a cooling effect. While
          the PMV and PPD are still relatively high, these values are also based
          on the previously stated metabolic rate, which will be very high for
          active occupants in a gym, and a lower clo value suitable for playing
          basketball or participating in gym class. As such, these occupants may
          never achieve “thermal comfort” while they are exercising, but the
          large cooling effect will result in much greater comfort than
          occupants would experience otherwise due to the effect of air speed on
          perceived temperature and thermoregulation.
        </p>
        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          3.3.1.2 Example: Warehouse
        </h5>
        <p>
          An unconditioned warehouse has typical indoor summer conditions of
          89°F (32°C) and 55% RH. In order to make the warehouse more
          comfortable for workers, the owner has requested HVLS fans be added to
          the loading dock area where the most strenuous activities take place.
          The warehouse is approximately 699 ft. x 378 ft. (213 m x 115 m) with a
          ceiling height of 40 ft. (12 m). The loading dock area is identified in
          Figure 3.3.1.2a below. What would be an effective design for cooling
          this zone?
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2a.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.2a - Warehouse Example - Loading Dock Cooling Zone" />
        </Container>

        <p>
          In this example, as is typical for warehouses, the added variable
          exists of having several large obstructions. Obstructions, such as
          storage racks or pallets in this case, must be taken into account when
          laying out fans such that they do not block airflow to large portions
          of the space. A proposed layout is shown in Figure 3.3.1.2b below. The
          layout for the whole facility has been included for reference.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2b.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.2b - Warehouse Example - Fan Layout and Schedule" />
        </Container>

        <p>
          To avoid the large obstructions in the space, fans are placed in
          “aisles” and open spaces, where occupants are more likely to be
          located. Different fans with a range of diameters have been selected
          based on the size of the area they are serving. Whenever there are
          multiple fans and large obstructions, fan clearance requirements are
          especially important to consider.{" "}
        </p>
        <p>
          For the loading dock area, four (4) 24 ft. (7.3 m) diameter fans were
          selected to cool the zone. They are mounted at a height of 36 ft. (11
          m) AFF, with a spinning height of 32 ft. 4 in.{" "}
        </p>
        <p>
          Figures 3.3.1.2c through 3.3.1.2g below show the air speed profiles
          and other results from the CFD analysis performed in SpecLab.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2c.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 3.3.1.2c - Warehouse Example - CFD Analysis Section - Fans along southern wall" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2d.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 3.3.1.2d - Warehouse Example - CFD Analysis Section - Fans along western wall" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2e.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.2e - Warehouse Example - CFD Analysis at 04 in. (0.1 m)" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2f.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.2f - Warehouse Example - CFD Analysis Air Speeds " />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-2g.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.2g - Warehouse Example - Performance Summary" />
        </Container>

        <p>
          As shown in the CFD images, the highest and most uniform air speeds
          are found in the open areas where the fans have been placed and where
          occupants are expected to be. The fans are spaced such that the
          cooling coverage of these areas is fairly comprehensive.{" "}
        </p>

        <p>
          The Performance Metrics in Figure 3.3.1.2g summarize analysis results
          for the loading dock area. Average air speed is approximately 217 fpm
          (1.1 m/s), resulting in an average cooling effect of more than 10°F
          (5.6°C). Cooling coverage is in excess of 99% in the comfort zone
          highlighted in Figure 3.3.1.2a. While the average PPD is 43%, this is
          a significant improvement from still air conditions, where a PPD in
          excess of 90% would be expected. In spaces where mechanical cooling is
          not typical, ceiling fans can significantly decrease discomfort due to
          heat and as a result, increase occupant productivity. For more
          information on the financial benefits of increased occupant
          productivity, refer to Section 3.8, Financial.{" "}
        </p>
        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          3.3.1.3 Example: Office Space
        </h5>
        <p>
          An engineer designing an office space wants to decrease air handling
          equipment size by using a higher temperature setpoint in the space-
          approximately 80°F (27°C) and 50% RH. Ceiling fans will be used to
          provide a cooling effect that results in a comfortable perceived
          temperature for occupants. At its widest point, the office space is
          approximately 76 ft. long and 51 ft. wide (23 m long and 16 m wide).
          Ceiling height is 9 ft. 4 in. (2.8 m). What fan selection and layout
          would produce the desired cooling effect?
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3a.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.3a - Office Example - Floor plan" />
        </Container>

        <p>
          Offices have several unique design considerations, including several
          small obstructions (e.g., desks, copiers, etc.) and the need to
          provide lower air speeds to minimize paper movement. This example
          features an open office plans with several long desks and chairs.
          Figure 3.3.1.3b illustrates a proposed fan design for this example.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3b.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.3b - Office Example - Fan Layout and Schedule" />
        </Container>

        <p>
          Multiple fans have been placed above desks where occupants are
          located. Smaller fans (60 in. diameter (1.5 m)) are used here, as
          compared to in previous examples, as this space and the targeted
          regions to be cooled are also smaller. Fans are mounted at the ceiling
          (9 ft. 4 in. (3 m)), with a spinning height of 8 ft. 1 in. (2.5 m).{" "}
        </p>
        <p>
          CFD analysis outputs are shown in Figures 3.3.1.3c through 3.3.1.3m
          below.
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3c-3d.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 3.3.1.3c and 3.3.1.3d - Office Example - CFD Analysis Sections" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3e-3f.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 3.3.1.3e and 3.3.1.3f - Office Example - CFD Analysis Sections" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3f-3g.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.3g and 3.3.1.3h- Office Example - CFD Analysis Section and CFD Analysis at 04 in. (0.1 m)" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3h-3i.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 3.3.1.3i and 3.3.1.3j - Office Example - CFD Analysis at 24 in. (0.6 m) and 43 in. (1.1 m)" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3j-3k.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.3k and 3.3.1.3l - Office Example - CFD Analysis at 67 in. (1.7 m) and Airflow Diagram." />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-1-3-PerformanceMetrics.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.3.1.3m - Office Example - Performance Summary" />
        </Container>

        <p>
          As shown in the CFD analysis images, highest air speeds are
          concentrated over desks and chairs, such that occupants will benefit
          from the cooling effect. Desks largely block the airflow, preventing
          much of it from reaching the floor. The highest air speeds are seen at
          seated and standing levels, which is ideal for an office scenario as
          these are the levels where occupants spend the majority of their time.
          Average air speed is 87 fpm (0.44 m/s), which provides a 4°F (2.2°C)
          cooling effect and is in the target range for an office. Maximum air
          speed does not exceed 200 fpm (1 m/s) at desk level, as this could
          lead to paper movement. The resulting PMV is -0.02, which indicates a
          high level of thermal comfort according to ASHRAE Standard 55 metrics.{" "}
        </p>
      </div>
      <Anchor id="3-3-2" />
      <H4 name="3.3.2 Fan Diameter Design" />
      <p>
        Another key component of designing with ceiling fans is fan diameter.
        Typically, there are a range of fan diameters that will be able to
        provide the target average air speed desired in a space. However, within
        a product family, larger diameter fans will be able to provide the
        target air speed at a fraction of the maximum speed. This will result in
        more uniform airflow in the space, which can be a critical design factor
        in conditioned applications. The example below compares a 20 ft. (6 m)
        diameter fan operating at 70% of maximum speed and a 12 ft. (3.7 m)
        diameter fan operating at 100% of maximum speed, both in a 100 ft. x 100
        ft. x 25 ft. (30.5 m x 30.5 m x 7.6 m) space. The average air speed in
        both scenarios is nearly identical, but the 12 ft. (3.7 m) fan shows much
        greater variance between the minimum and maximum values.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-2a.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.3.2a - CFD results from a 20 ft. (6 m) diameter fan at 70% maximum RPM" />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-2b.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.3.2b - CFD results from a 12 ft. (3.7 m) diameter fan at 100% maximum RPM" />
      </Container>

      <p>
        In addition to increased uniformity in the horizontal plane, larger fans
        also provide greater vertical uniformity. The “boundary layer,” or wall
        of air, moving along the floor is taller with a larger fan, resulting in
        a less severe drop off in air speed closer to the floor. Therefore, air
        speeds felt by seated and standing occupants is more constant.
      </p>

      <Anchor id="3-3-3" />
      <H4 name="3.3.3 Blade Height Design" />
      <p>
        One key component of ideal fan layout is mounting height. Often,
        designers are unsure what mounting height to specify and how blade
        height impacts performance. Blade height impacts both air speed and air
        profile uniformity, therefore it is important to specify a blade height
        that meets the requirements of a space.{" "}
      </p>
      <p>
        A good rule of thumb for large diameter fans is to keep the fan
        approximately 1 to 2 diameters above the floor, when possible. For
        example, mounting a 24 ft. (7.3 m) fan such that the blades are
        approximately 24 ft. (7.3 m) to 48 ft. (14.6 m) AFF. If the fan blades are
        too close to the floor, less than approximately 0.75 diameter above the
        floor, the airflow from the fan can become impeded which will result in
        reduced average air speed in the space.{" "}
      </p>
      <p>
        To illustrate the impact of blade height, a comparison for a 24 ft. (7.3
        m) fan in a 150 ft. x 150 ft. (46 m x 46 m) space was performed.
        Simulations were run for blade heights of 10 ft. (3.0 m), 15 ft. (4.6 m),
        20 ft. (6.1 m) AFF, 25 ft. (7.6 m) AFF, 30 ft. (9.1 m) AFF, and 40 ft. (12.2
        m) AFF. The ceiling deck was raised to 52 ft. (16 m) to allow for more
        variation in blade height. As can be seen in Figure 3.3.3a below, the
        jet of air exits the fan and moves down to the floor level. As the jet
        interacts with the floor, the airflow transitions from vertical to
        horizontal and the air spreads out across the space.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-3a.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.3.3a - CFD sections of fan mounted at 10 ft. (3 m) AFF, 15 ft. (4.6 m) AFF, 20 ft. (6 m) AFF, 25 ft. (7.6 m) AFF, 30 ft. (9 m) AFF, and 40 ft. (12.2 m) AFF" />
      </Container>

      <p>
        A summary of the resulting air speeds, cooling coverage fractions,
        cooling effect, and standard effective temperature are shown in Figure
        3.3.3b.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-3-3b.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.3.3b - CFD performance summary for fan mounted at 10 ft. (3 m) AFF, 15 ft. (4.6 m) AFF, 20 ft. (6 m) AFF, 25 ft. (7.6 m) AFF, 30 ft. (9 m) AFF, and 40 ft. (12.2 m) AFF" />
      </Container>

      <p>
        The results of the study show blade height impacts air speeds at
        occupant levels, cooling coverage, and cooling effect. Fans mounted
        higher in the space produce lower average air speeds, but tend to
        increase uniformity of the air speed, as evidenced by the difference
        between average standing and seated height air speeds and the difference
        between maximum and average standing air speeds. It should be noted
        these results are for an appropriately sized fan in an empty space; an
        undersized fan would see larger differences in average air speed and
        cooling effect as blade height increases.
      </p>
    </div>
  );
}

export default C3S3;
