import React from "react";
import DropdownC3S1 from "./Navigation/dropdown-3-1";
import DropdownC3S2 from "./Navigation/dropdown-3-2";
import DropdownC3S3 from "./Navigation/dropdown-3-3";
import DropdownC3S4 from "./Navigation/dropdown-3-4";
import DropdownC3S5 from "./Navigation/dropdown-3-5";
import DropdownC3S6 from "./Navigation/dropdown-3-6";
import DropdownC3S7 from "./Navigation/dropdown-3-7";
import DropdownC3S8 from "./Navigation/dropdown-3-8";

function Chapter3SubNav(props) {
  return (
    <div>
      <DropdownC3S1 />

      <DropdownC3S2 />

      <DropdownC3S3 />

      <DropdownC3S4 />

      <DropdownC3S5 />

      <DropdownC3S6 />

      <DropdownC3S7 />

      <DropdownC3S8 />
    </div>
  );
}

export default Chapter3SubNav;
