import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "./home";
import Chapter1 from "./chapter-1";
import Chapter2 from "./chapter-2";
import Chapter3 from "./chapter-3";
import Recommendation from "./recommendation";
import { BullittCenter, FrancoCenter, CoastalBiologyCenter } from "./case-studies";
import VertictalMarketing from "./vertical-marketing";
import Submittals from './Submittals/submittals';

function Routes() {

  return (
    <Switch>
      <Route path="/chapter-1" component={Chapter1} />
      <Route path="/chapter-2" component={Chapter2} />
      <Route path="/chapter-3" component={Chapter3} />
      <Route path="/recommendation" component={Recommendation} />
      <Route path="/vertical-marketing" component={VertictalMarketing} /> 
      <Route path="/submittals" component={Submittals} />  
      <Route path="/bullitt-center" component={BullittCenter} />
      <Route path="/franco-center" component={FrancoCenter} />
      <Route path="/coastal-biology-building" component={CoastalBiologyCenter} />     
      <Route path="/" component={Home} />
    </Switch>
  );
}

export default Routes;
