import React from "react";
import DropdownC2S1 from "./Navigation/dropdown-2-1";
import DropdownC2S2 from "./Navigation/dropdown-2-2";
import DropdownC2S3 from "./Navigation/dropdown-2-3";
import DropdownC2S4 from "./Navigation/dropdown-2-4";
import DropdownC2S5 from "./Navigation/dropdown-2-5";
import DropdownC2S6 from "./Navigation/dropdown-2-6";
import DropdownC2S7 from "./Navigation/dropdown-2-7";
import DropdownC2S8 from "./Navigation/dropdown-2-8";
import DropdownC2S9 from "./Navigation/dropdown-2-9";
import DropdownC2S10 from "./Navigation/dropdown-2-10";
import DropdownC2S11 from "./Navigation/dropdown-2-11";
import DropdownC2S12 from "./Navigation/dropdown-2-12";

function Chapter2SubNav(props) {
  return (
    <div>
      <DropdownC2S1 />

      <DropdownC2S2 />

      <DropdownC2S3 />

      <DropdownC2S4 />

      <DropdownC2S5 />

      <DropdownC2S6 />

      <DropdownC2S7 />

      <DropdownC2S8 />

      <DropdownC2S9 />

      <DropdownC2S10 />

      <DropdownC2S11 />

      <DropdownC2S12 />
    </div>
  );
}

export default Chapter2SubNav;
