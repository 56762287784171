import React, { Component, Fragment } from "react";
import C3S1 from "./Chapter-3/3.1";
import C3S2 from "./Chapter-3/3.2";
import C3S3 from "./Chapter-3/3.3";
import C3S4 from "./Chapter-3/3.4";
import C3S5 from "./Chapter-3/3.5";
import C3S6 from "./Chapter-3/3.6";
import C3S7 from "./Chapter-3/3.7";
import C3S8 from "./Chapter-3/3.8";
import Copyright from "../components/UI-Kit/Copyright";
import MobileRecommendationButton from "./UI-Kit/Mobile-Recommendation";

const isMobile = window.innerWidth <= 500;
class ScrollToTopOnMount extends Component {
  componentDidMount(prevProps) {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

function Chapter3() {
  return (
    <Fragment>
      <ScrollToTopOnMount />
      <div className="main-content">
        <C3S1 />
        <C3S2 />
        <C3S3 />
        <C3S4 />
        <C3S5 />
        <C3S6 />
        <C3S7 />
        <C3S8 />
        {isMobile ? <MobileRecommendationButton /> : null}
        <Copyright text="Copyright 2019 Delta T, LLC. All rights reserved"  className="mt75"/>
      </div>
    </Fragment>
  );
}

export default Chapter3;
