import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C2S12() {
  return (
    <div className="chapter-container">
      <Anchor id="2-12-1" />
      <H2 name="2.12 Specifications- Getting the Fan You Want" />
      <p>
        Previous sections of this guide have discussed important criteria for
        designing a ceiling fan system, such as airflow, controls, and code
        compliance. In addition to these requirements, numerous other
        specification options exist to ensure the selected fan is high quality
        and well suited to the application. Below is an overview of some key
        components of a fan selection.{" "}
      </p>

      <H4 name="2.12.1 Key Specification Criteria" />
      <div className="chapter-content">
        <h5 className="mb10 mt40 light-grey uppercase font-medium">2.12.1.1 Type of Fan</h5>
        <p>
          Overhead fans come in a wide variety of sizes and styles, and the
          correct model of fan must be selected based on the specific
          requirements of each application. Fans are typically broken into two
          main categories: residential and commercial/industrial. Residential
          fans are usually between 52 in. and 84 in. (1.3 m and 2.1 m) in
          diameter. Commercial/industrial fans usually range from 8 ft. to 24 ft.
          (2.4 m to 7.3 m) in diameter. Different features are typically
          required in commercial and industrial fans, as opposed to residential
          fans, due to differences in size and in project priorities. Several of
          these features are described below, in addition to important criteria
          to specify for any fan selection.{" "}
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.12.1.2 Layout, Sizing, and Performance
        </h5>
        <p>
          Fan layout in a space should be determined based on a number of
          variables including: space type, desired thermal conditions, room size
          and geometry, and any obstructions, if present. Circulator fan sizing,
          placement, and performance are also essential to achieving thermal
          comfort.{" "}
        </p>
        <p>
          In general, circulator fans should be placed in areas where the
          greatest cooling effect is desired- such as where occupants will be
          located or, in a process scenario, where components need to be cooled.
          Typically, fan diameter should be as large as can be coordinated
          within the space. Larger fans provide more uniform airflow and operate
          at a lower percentage of maximum speed. (For more information on
          diameter, see Section 3.3.2.) Blade height should ideally be one to
          two fan diameters above the finished floor in order to maximize
          cooling effect and fan coverage area. (For more information on blade
          height, see Section 3.3.3.){" "}
        </p>
        <p>
          In order to verify fan layout and selection, a computational fluid
          dynamics (CFD) analysis should be run of the proposed design. Through
          analysis, programs such as BAF’s SpecLab can evaluate whether the
          design meets performance requirements of the space. At a minimum, the
          input data for the CFD analysis should include the ceiling fan(s),
          significant obstructions to airflow at the floor level, and the actual
          space dimensions. As verification of performance, the submittal shall
          include results of the CFD analysis including the following
          performance metrics determined in accordance with ASHRAE Standard 55:
          average air speed, minimum, maximum and average cooling effect from
          elevated airspeed, Predicted Mean Vote, and Predicted Percentage
          Dissatisfied for seated and standing occupants in each occupied zone.{" "}
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.12.1.3 Test Standard Compliance
        </h5>
        <p>
          As discussed previously, a number of testing standards apply to
          ceiling fans. It is important to verify the selected fan complies with
          these standards to ensure code compliance and desired performance. The
          2018 IMC requires ceiling fans larger than 7 ft. (2.1 m) in diameter to
          be tested and labeled in accordance with AMCA 230-15, listed and
          labeled in accordance with UL 507, and installed in accordance with
          the manufacturer’s instructions. AMCA 230 establishes uniform methods
          for laboratory testing of air circulating fans to determine fan
          performance. Selecting an AMCA 230 rated fan ensures any published
          performance data has been determined by standardized, rigorous third
          party testing. UL 507 is a minimum safety testing standard for fans
          that is used to evaluate the safety of electrical components and
          connections and establish safe mounting heights for ceiling fans. As
          ceiling fans are installed directly above occupants, it is imperative
          that methods of attaching fans to structure have been tested and
          verified and minimum clearance requirements to nearby objects have
          been met.{" "}
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.12.1.4 Fan Construction
        </h5>
        <p>
          Fan construction specifications are also key to selecting safe, robust
          fans. The basic components of an HVLS fan are shown in Figure
          2.12.1.4a below.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-4a-CeilingFanIllustration.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 2.12.1.4a - Ceiling fan illustration labeling major components of HVLS fan" />
        </Container>

        <p>
          Fan hubs should be large diameter (19 in. or 480 mm) and constructed
          of precision cut aluminum for high strength and light weight. The hub
          should consist of two laser cut aluminum plates, several extruded
          aluminum spars (quantity dependent on number of airfoils), and one
          aluminum spacer fastened with a pin and collar rivet system. This
          design provides a flexible assembly that distributes force loads
          experienced by the hub assembly over a large area, thus reducing the
          fatigue experienced at the fan blade attachment point and increasing
          the life of the hub and airfoils. This is especially critical in
          applications with larger diameter fans (greater than 16 ft. or 4.9 m),
          applications where fans may be exposed to wind, and industrial
          environments.{" "}
        </p>
        <p>
          Airfoils should be precision extruded aluminum alloy, connected to the
          hub by two high-strength (Grade 8) locking bolts per airfoil. The
          airfoils are connected to the hub and interlocked with zinc-plated
          steel retainers (see Figure 2.12.1.4b). The combination of the two
          bolts and the airfoil retainers creates redundancy and provides an
          extra level of safety.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-4b-ZincPlatedRetainers.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 2.12.1.4b - Zinc-plated steel airfoil retainers on Big Ass fan" />
        </Container>

        <p>
          Mounting bolts to attach the fan to structure should be SAE Grade 8.
          The torque applied by off-the-shelf impact drivers can overtorque a
          Grade 5 bolt, which may lead to eventual failure of that bolt. Grade 8
          hardware is more robust and can withstand installations where torque
          specifications are not followed during the installation process.{" "}
        </p>
        <p>
          In the case of harsh or critical environments, fan construction
          materials should be selected to meet the requirements of the specific
          application. In environments with corrosion risks, such as moisture or
          cleaning agents, fans should be built of all aluminum and stainless
          steel construction. Fans should use nonporous, nonabsorbent materials
          and utilize a non-pooling design to prevent water from collecting and
          supporting bacteria and mold growth. If required, stainless steel
          motors are able to handle more frequent, intense cleaning. In
          sensitive applications, food-grade oil should be used to mitigate
          concerns in the unlikely case of a leak. Food-duty epoxy coating is
          available for gearboxes to provide additional protection. BAF provides
          an option on Powerfoil fans called ‘Washdown,’ which includes all
          aforementioned features and is designed primarily for the food
          manufacturing industry.{" "}
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.12.1.5 Lighting and Other System Integration
        </h5>
        <p>
          Most direct drive, and some gear driven, HVLS fans are capable of
          supporting onboard LED lighting. If this option is desired, LED light
          kits of various outputs, dimming capabilities, and lens and light
          distribution options may be specified. Alternately, fans may be
          installed in a building with an independent lighting system. In this
          case, special attention should be paid to preventing obstruction of
          lights by fan blades, as this causes light flashing each time a blade
          passes a light fixture. Attaching a light fixture to the fan and
          selecting a proper lens angle mitigates this issue.{" "}
        </p>
        <p>
          Additionally, larger fan diameters may be used, as fan blades will not
          be at risk of conflicting with lights.{" "}
        </p>
        <p>
          Spatial coordination of multiple systems in a building can prove
          challenging depending on quantity of components and space allowances.
          To assist in coordinating fans with lighting and other systems, HVLS
          fans may be specified with a hollow shaft threaded to accept a ¾ in.
          NPT fitting in which wiring, piping, etc. can be routed below the fan.
          A standard junction box can also be affixed to this hollow shaft to
          allow for installing optional features such as lights, cameras, or
          other accessories.{" "}
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">2.12.1.6 Fan Motors </h5>
        <p>
          In sound-critical environments, fans with direct drive motors generate
          less noise than gear driven fans. Direct drive fans also typically
          offer a more refined appearance and reduced operating cost; although,
          they deliver less airflow and motor replacement can be more
          complicated. Direct drive fans are well suited to commercial or
          residential environments where sound and aesthetics are greater
          concerns and the spaces typically have air conditioning and lower
          ceiling heights, thus requiring less airflow.{" "}
        </p>
        <p>
          Gear driven fans are generally used in applications where maximum
          airflow is the first priority, and acoustics and refined appearance
          are secondary considerations. Higher motor power allows the fans to
          provide increased airflow, making them well suited to industrial
          environments with high ceilings and often no air conditioning. The
          C-Face motors on gear driven fans allow for simpler field replacement.
          Additionally, gear drives typically carry higher IP Ratings, which
          support operation in harsh environments. There are direct drive fans
          designed for operation in harsh environments, if direct drive is
          preferred for a given application. IP should be verified for all fan
          components (motor, drive, and controller) when selecting fans for
          industrial or harsh environments.
        </p>
        <p>
          IP Ratings are defined by IEC Standard 60529 and describe the capacity
          of an electrical enclosure to prevent the ingress of solids and water.
          Each IP rating is written as IPXY, where X and Y are numerical values.
          The first number, X, describes the resistance to solids, such as
          objects and dust, and ranges from 0 to 6. A value of 1 indicates the
          lowest level of resistance - protects against objects greater than 2
          in. (50 mm) (e.g., a hand)- and 6 indicates the highest level- no
          ingress of dust for eight (8) hours. The second number, Y, represents
          water resistance and ranges from 0 to 9. Again, 1 is the lowest level
          of resistance - protects against vertically falling water drops with
          limited ingress for 10 minutes - and 9 is the highest level - no
          ingress from powerful, high-temperature water jets. Additional digits
          may be added to provide further information on protection. In critical
          and harsh environments, an acceptable level of dust and water ingress
          should be determined, and a fan motor with a sufficient IP rating
          selected.{" "}
        </p>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          2.12.1.7 Redundant Safety Features
        </h5>
        <p>
          HVLS fans are typically in excess of 200 lbs (90 kg) and mounted
          directly above occupants. To ensure secure fan operation and safety
          for personnel and equipment, overhead HVLS fans should be provided
          with various redundant safety features. In addition to high quality
          fan construction and attachments, as mentioned previously, the
          following failsafes should be provided in conjunction to achieve an
          acceptable level of redundancy and safety.{" "}
        </p>
        <p>
          All 20 ft. to 24 ft. (6 m to 7.3 m) diameter overhead HVLS fans should
          be equipped with an Airfoil Restraint System (ARS) that prevents
          separation of the airfoil from the fan assembly in the event of
          critical impact damage. The ARS uses durable nylon straps that extend
          through the entire airfoil’s interior and attach at the fan hub and
          winglets. Should a failure occur at any point along the airfoil, this
          redundant security measure will ensure the airfoil remains connected
          to the fan assembly. This safety feature should be available upon
          request for smaller diameter HVLS models at no additional charge. This
          is especially critical in applications with larger diameter fans
          (greater than 16 feet (4.9 m)) where fans may be exposed to wind.
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-7a-7b.png"
            width="100%"
            alt=""
          />
          <Caption text="Figures 2.12.1.7a and 2.12.1.7b: Airfoil Restraint System" />
        </Container>

        <p>
          HVLS fans should be equipped with a safety cable system to ensure the
          fan will not fall in an unlikely disaster scenario. This system is
          comprised of an upper safety cable that secures the mounting system to
          the building structure, in addition to two lower safety cables that
          secure the hub assembly to the mounting system. Each cable should be
          made from ⌀⅜ in. (⌀9.5 mm) aircraft-grade galvanized steel cable and
          factory attached to the motor frame and secured to extension tube with
          bolts. The upper safety cable, pictured in Figure 2.12.1.7c,
          encompasses the entire upper mount and provides complete redundancy
          from extension tube to structure. The lower safety cable, shown in
          Figure 2.12.1.7d, encompasses the entire motor/gearbox assembly,
          connects to the extension tube, and provides complete redundancy from
          extension tube to hub.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-7c.png"
            alt=""
          />
          <Caption text="Figure 2.12.1.7c: Upper safety cable" />
        </Container>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-12-1-7d-LowerSafetyCable.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 2.12.1.7d: Lower safety cable" />
        </Container>

        <p>
          Guy wires are recommended to provide additional stability for the fan.
          Wires should meet the specifications described in the Safety section
          and include required diameter and connection method.
        </p>
        <p>
          Variable frequency drives (VFD) are provided on fans for additional
          control and safety. VFDs may be provided with IP66 enclosures for use
          in harsh environments and up to operating temperatures of 131°F
          (55°C). As a safety feature, VFDs should be provided with a built-in
          accelerometer and sensor to shut down the fan when an impact or
          obstruction is detected.{" "}
        </p>
      </div>
    </div>
  );
}

export default C2S12;
