import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";

function C2S6() {
  return (
    <div className="chapter-container">
      <Anchor id="2-6-1" />
      <H2 name="2.6 Mechanical Codes" />
      <H4 name="2.6.1 Fan Requirements in Mechanical Codes" />
      <p>
        The 2018 version of the International Mechanical Code (IMC) contains
        requirements for High-Volume Large Diameter Fans in Section 929.1. The
        requirements for ceiling fans larger than seven feet in diameter are to
        be tested and labeled in accordance with AMCA 230-15, listed and labeled
        in accordance with UL 507, and installed in accordance with the
        manufacturer’s instructions.
      </p>
      <p>
        The first requirement can be met through the use of fans that comply
        with AMCA Publication 211 and are certified to bear the AMCA Certified
        Ratings Program seal through the AMCA Certified Ratings Program (CRP).
        The AMCA (CRP) is a third party program which allows the manufacturers
        of air movement products to obtain an AMCA Seal when their equipment has
        been tested and rated in accordance with recognized test standards. This
        provides assurance that the specified products will provide the
        performance published by the manufacturer.
      </p>
      <p>
        The second requirement can be met through the use of a fan that has been
        tested by a nationally recognized testing lab for a minimum level of
        safety. Underwriters Laboratories 507, Standard for Electric Fans is a
        testing standard that is used to evaluate the safety of electrical
        components and connections and establish safe mounting heights for
        ceiling fans. Third party certification of safety is provided by a
        Nationally Recognized Testing Lab (NRTL) as defined by OSHA per 29 CFR
        1910.7. Products that meet or exceed the requirements of UL 507 are
        listed and labeled by the NRTL that performed the testing.
      </p>
      <p>
        The last requirement provides increased safety as it tries to require
        the fan to be used in a manner consistent with the manufacturer&apos;s
        recommendations, to avoid untested methods of attaching the fans to
        structure, and to follow the minimum clearance requirements to nearby
        objects.
      </p>
    </div>
  );
}

export default C2S6;
