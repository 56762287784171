import React from "react";
import H1 from "../UI-Kit/H1";
import H2 from "../UI-Kit/H2";
import Anchor from "../UI-Kit/Anchor";

function C3S1() {
  return (
    <div className="chapter-container main" id="main">
      <Anchor id="3-1" />
      <H1 name="Applications &amp; Examples" />
      <H2 name="3.1 Introduction" />
      <p>
        This chapter will focus on providing additional detail for specific
        ceiling fan applications and example problems to demonstrate the
        application in a design scenario.
      </p>
    </div>
  );
}

export default C3S1;
