import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const StyledCopyright = styled.p `
    color: #d4d4d4;
    line-height: 1;
    font-size: 12px;
    text-align:center;
`

function Copyright(props)  {
    return (
        <StyledCopyright className={props.className}>{props.text}</StyledCopyright>
    );
}

Copyright.propTypes = {
    text: PropTypes.string,
    className: PropTypes.string
  };

export default Copyright;