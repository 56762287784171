import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from'./App.js';
import './fonts/ProximaNova-Reg.otf';
import './fonts/ProximaNova-RegIt.otf';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
