import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import CheckboxLabels from "./product-package-form";
import PropTypes from "prop-types";
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const StyledModal = styled(Modal)`
  && {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const StyledButton = styled(Button)`
  && {
    background-color: #ffc425;
    font-weight: 900;
    border-radius: 15px;
    padding: 10px 15px;
    font-size: 0.875rem;
    font-family: industry, sans-serif;
    && :hover {
    }
  }
`;

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  && {
    width: 16px;
    margin-left: 5px;
  }
`;

const useStyles = makeStyles({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "none"
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, .9)",
    border: "none",
    boxShadow: "shadows[5]",
    padding: "40px"
  }
});

function ProductPackage(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <StyledButton type="button" onClick={handleOpen}>
        VIEW PRODUCT PACKAGE <StyledArrowForwardIcon />
      </StyledButton>
      <StyledModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Download your product package</h2>
            <CheckboxLabels data={props.data} />
          </div>
        </Fade>
      </StyledModal>
    </div>
  );
}

ProductPackage.propTypes = {
  data: PropTypes.object
};

export default ProductPackage;
