import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C2S9() {
  return (
    <div className="chapter-container">
      <Anchor id="2-9-1" />
      <H2 name="2.9 Safety Design Considerations" />
      <H4 name="2.9.1 Clearance Requirements" />
      <p>
        Fans must be installed such that required minimum clearances are
        maintained to ensure both safety and performance. General clearance
        guidelines are as follows:{" "}
      </p>
      <ul>
        <li>
          The fan installation area must be free of obstructions such as lights,
          cables, sprinklers, or other building structure components.{" "}
        </li>
        <li>
          The minimum clearance from obstructions should be no less than 2 ft.
          (610 mm) in all directions. Ceiling and wall clearance require
          specific consideration based on model and size of fan. See the
          following tables for minimum ceiling and wall clearance requirements.{" "}
        </li>
        <li>
          The fan must be installed so the airfoils are at least 10 ft. (3 m)
          above the finished floor (unless otherwise noted).{" "}
        </li>
        <li>
          The fan must be installed so that it is plumb to the ground (unless
          column-mounted).
        </li>
        <li>
          Space the fans at a center-to-center distance that is at least 2.5x
          the fan diameter.
        </li>
        <li>
          In order for a ceiling fan to operate properly, it must be able to
          draw air freely into the top of the fan. If the fan is placed too
          close to a large solid surface such as a wall or a roof, then the
          performance of the fan will be reduced. Dimensions A and B should
          match tabulated values in Figure 2.9.1b below.{" "}
        </li>
      </ul>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-9-1-MinimumClearance.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 2.9.1a - Minimum clearance requirements diagram" />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/2-9-1-CeilingClearance.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 2.9.1b - Tables indicating minimum clearance requirements between fan and ceiling and fan and wall." />
      </Container>

      <Anchor id="2-9-2" />
      <H4 name="2.9.2 Wind Exposure" />
      <p>
        Guy wires are recommended to restrain the fan’s lateral movement in any
        installation that is exposed to wind. The guy wires should be of at
        least ¼ in. (6.35 mm) diameter, utilize turnbuckles, and not include
        quick connection devices such as gripple kits.{" "}
      </p>
      <p>
        For most applications, it is recommended the fan be sheltered from
        direct wind by placing the fan higher in the structure. For example, in
        hangar applications, the fan blade height should be no lower than the
        top of the hangar door and fans should not be placed directly inside the
        hangar door.{" "}
      </p>
      <p>
        Long term exposure to wind can cause flexing of the airfoil and hub
        system, which may eventually lead to fatigue. The inclusion of a
        secondary airfoil restraint system (safety strap) is recommended in all
        applications where the fan may be exposed to wind. The secondary airfoil
        restraint system should provide a redundant connection from the outer
        tip of the airfoil to the hub.{" "}
      </p>

      <Anchor id="2-9-3" />
      <H4 name="2.9.3 Seismic Considerations" />
      <p>
        A seismic restraint system is recommended to restrain against lateral
        forces experienced in a seismic event. The guy wires should be at least
        ¼ in. (6.35 mm) diameter, utilize turnbuckles, and not include quick
        connection devices such as gripple kits.{" "}
      </p>
      <p>
        Any mechanical component identified as non-exempt from Seismic
        Requirements by the International Building Code must have further
        seismic support. The seismic support system must be approved to resist
        the calculated required Seismic Force.{" "}
      </p>
    </div>
  );
}

export default C2S9;
