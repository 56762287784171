import React from "react";
import DropdownC1S1 from "./Navigation/dropdown-1-1";
import DropdownC1S2 from "./Navigation/dropdown-1-2";
import DropdownC1S3 from "./Navigation/dropdown-1-3";
import DropdownC1S4 from "./Navigation/dropdown-1-4";

function Chapter1SubNav(props) {
  return (
    <div>
      <DropdownC1S1 />
      <DropdownC1S2 />
      <DropdownC1S3 />
      <DropdownC1S4 />
    </div>
  );
}

export default Chapter1SubNav;
