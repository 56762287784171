import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Form from "@material-ui/core/FormControl";
import FormLabel from "../components/UI-Kit/FormLabel";
import SubmitButton from "./UI-Kit/SubmitButton";
import { Radio } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import axios from "axios";
import ProductPackage from "./Product-Package/product-package";
import AlternativeOptionsCard from "./UI-Kit/AlternativeOptionsCard";
import RecommendationButton from "./UI-Kit/RecommendationButton";
import get from "lodash/get";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

const isMobile = window.innerWidth <= 500;
const url =
  "https://212guf91n1.execute-api.us-east-2.amazonaws.com/latest/recommendation";

const StyledForm = styled.form`
  color: #242424;
  line-height: 1;
  text-align: left;
  background-color: #f8f8f8;
`;

const StyledContainer = styled.div`
  && {
    padding: 15px 30px;
  }
`;

const StyledOverlay = styled(Container)`
  && {
    padding: 0px;
    height: 100%;
    background-color: #000;
  }
`;

const StyledImage = styled.img`
  && {
    background-size: cover;
    width: 100%;
    height: auto;
    box-shadow: 0px 4px 5px 2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 2px 0px rgba(0, 0, 0, 0.12);
  }
`;

const StyledBackground = styled.div`
  && {
    padding: 15px 30px;
    background-image: url("${(props) => props.url}");
    background-size: cover;
    background-position: top center;
    opacity: 0.7;
    height: 100%;
  }
`;

const StyledBackgroundMobile = styled.div`
  && {
    background-image: url("${(props) => props.url}");
    background-size: cover;
    background-position: top center;
    opacity: 0.7;
    height: 300px;
  }
`;

const FanBackgroundContainer = styled.div`
  && {
    background-image: url("${(props) => props.url}");
    background-size: cover;
    width: 100%;
    background-position: 30% 26%;
  }
`;

const StyledSelect = styled.select`
  && {
  }
`;
const StyledOption = styled.option`
  && {
  }
`;

const StyledGridItem = styled(Grid)`
  && {
    height: calc(100vh - 239px);
  }
`;
const StyledH2 = styled.h2`
  && {
    font-weight: 400;
    font-family: "Proxima Nova W15";
    font-size: 1.25em;
    @media (max-width: 768px) {
      font-size: 1.2em;
    }
  }
`;
const StyledH1 = styled.h1`
  && {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 2em;
    @media (max-width: 768px) {
      font-size: 1.5em;
    }
  }
`;
const StyledCaption = styled.p`
  && {
    color: #a6a6a6;
    font-size: 10px;
    font-style: italic;
    margin: 10px 0px 10px 0px;
    text-align: left;
  }
`;
const StyledInput = styled.input`
  && {
    width: 60px;
    float: left;
    vertical-align: bottom;
  }
`;
const StyledText = styled.span`
  && {
    float: left;
    width: 20px;
    margin-left: 3px;
    margin-right: 3px;
    vertical-align: bottom;
  }
`;

const StyledToggleContainer = styled.div`
  && {
    margin-top: 5px;
  }
`;

const StyledToggleButtons = styled(ToggleButton)`
  && {
    padding: 0px 20px 0px 20px;
    font-size: 14px;
    height: 36px;
  }
`;

class Recommendation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      isLoading: false,
      units: "IMPERIAL",
      primaryUseError: "",
      goalError: "",
      facilityLengthError: "",
      facilityWidthError: "",
      ceilingHeightError: "",
      formControls: {
        primaryUse: {
          value: "",
        },
        goal: {
          value: "",
        },
        airConditioned: {
          value: "",
        },
        outdoorSpace: {
          value: "",
        },
        length_ft: {
          value: "",
          placeholder: "0",
        },
        length_in: {
          value: "",
          placeholder: "0",
        },
        width_ft: {
          value: "",
          placeholder: "0",
        },
        width_in: {
          value: "",
          placeholder: "0",
        },
        ceiling_ft: {
          value: "",
          placeholder: "0",
        },
        ceiling_in: {
          value: "",
          placeholder: "0",
        },
      },
    };
  }

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      formControls: {
        ...this.state.formControls,
        [name]: {
          ...this.state.formControls[name],
          value,
        },
      },
    });
  };

  handleChangeUnits = (event, newUnits) => {
    if (newUnits !== null) {
      this.setState((prevState) => {
        let convertedLengthFt = "";
        let convertedWidthFt = "";
        let convertedCeilingFt = "";
        let convertedLengthIn = "";
        let convertedWidthIn = "";
        let convertedCeilingIn = "";

        if (newUnits === "IMPERIAL") {
          convertedLengthFt = (
            prevState.formControls.length_ft.value * 3.281
          ).toFixed(0);
          convertedWidthFt = (
            prevState.formControls.width_ft.value * 3.281
          ).toFixed(0);
          convertedCeilingFt = (
            prevState.formControls.ceiling_ft.value * 3.281
          ).toFixed(0);
          convertedLengthIn = (
            prevState.formControls.length_in.value / 2.54
          ).toFixed(0);
          convertedWidthIn = (
            prevState.formControls.width_in.value / 2.54
          ).toFixed(0);
          convertedCeilingIn = (
            prevState.formControls.ceiling_in.value / 2.54
          ).toFixed(0);
        } else {
          convertedLengthFt = (
            prevState.formControls.length_ft.value / 3.281
          ).toFixed(2);
          convertedWidthFt = (
            prevState.formControls.width_ft.value / 3.281
          ).toFixed(2);
          convertedCeilingFt = (
            prevState.formControls.ceiling_ft.value / 3.281
          ).toFixed(2);
          convertedLengthIn = (
            prevState.formControls.length_in.value * 2.54
          ).toFixed(2);
          convertedWidthIn = (
            prevState.formControls.width_in.value * 2.54
          ).toFixed(2);
          convertedCeilingIn = (
            prevState.formControls.ceiling_in.value * 2.54
          ).toFixed(2);
        }
        return {
          ...prevState,
          units: newUnits,
          formControls: {
            ...prevState.formControls,
            length_ft: { value: convertedLengthFt },
            width_ft: { value: convertedWidthFt },
            ceiling_ft: { value: convertedCeilingFt },
            length_in: { value: convertedLengthIn },
            width_in: { value: convertedWidthIn },
            ceiling_in: { value: convertedCeilingIn },
          },
        };
      });
    }
  };

  handleairConditionedChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        formControls: {
          ...this.state.formControls,
          [name]: {
            ...this.state.formControls[name],
            value,
          },
        },
      },
      () => this.airConditionedValidate()
    );
  };
  handleoutdoorSpaceChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        formControls: {
          ...this.state.formControls,
          [name]: {
            ...this.state.formControls[name],
            value,
          },
        },
      },
      () => this.outdoorSpaceValidate()
    );
  };
  handlePrimaryUseChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        formControls: {
          ...this.state.formControls,
          [name]: {
            ...this.state.formControls[name],
            value,
          },
        },
      },
      () => this.primaryUseValidate()
    );
  };

  handleGoalChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        formControls: {
          ...this.state.formControls,
          [name]: {
            ...this.state.formControls[name],
            value,
          },
        },
      },
      () => this.goalValidate()
    );
  };
  handleFacilityLengthChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        formControls: {
          ...this.state.formControls,
          [name]: {
            ...this.state.formControls[name],
            value,
          },
        },
      },
      () => this.facilityLengthValidate()
    );
  };
  handleFacilityWidthChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        formControls: {
          ...this.state.formControls,
          [name]: {
            ...this.state.formControls[name],
            value,
          },
        },
      },
      () => this.facilityWidthValidate()
    );
  };
  handleCeilingHeightChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.setState(
      {
        formControls: {
          ...this.state.formControls,
          [name]: {
            ...this.state.formControls[name],
            value,
          },
        },
      },
      () => this.ceilingHeightValidate()
    );
  };

  primaryUseValidate = () => {
    let primaryUseError = "";

    if (!this.state.formControls.primaryUse.value) {
      primaryUseError = "Please select a primary use for your space.";
    }

    this.setState({ primaryUseError });

    if (primaryUseError) {
      return false;
    }

    return true;
  };

  goalValidate = () => {
    let goalError = "";

    if (!this.state.formControls.goal.value) {
      goalError = "Please select a goal for your space.";
    }

    this.setState({ goalError });

    if (goalError) {
      return false;
    }

    return true;
  };
  facilityLengthValidate = () => {
    let facilityLengthError = "";

    if (!this.state.formControls.length_ft.value) {
      facilityLengthError = "Please enter the facility length in feet.";
    }

    this.setState({ facilityLengthError });

    if (facilityLengthError) {
      return false;
    }

    return true;
  };
  facilityWidthValidate = () => {
    let facilityWidthError = "";

    if (!this.state.formControls.width_ft.value) {
      facilityWidthError = "Please enter the facility width in feet.";
    }

    this.setState({ facilityWidthError });

    if (facilityWidthError) {
      return false;
    }

    return true;
  };
  ceilingHeightValidate = () => {
    let ceilingHeightError = "";

    if (!this.state.formControls.ceiling_ft.value) {
      ceilingHeightError = "Please enter the ceiling height in feet.";
    }

    this.setState({ ceilingHeightError });

    if (ceilingHeightError) {
      return false;
    }

    return true;
  };
  airConditionedValidate = () => {
    let airConditionedError = "";

    if (!this.state.formControls.airConditioned.value) {
      airConditionedError = "Field is required.";
    }

    this.setState({ airConditionedError });

    if (airConditionedError) {
      return false;
    }

    return true;
  };
  outdoorSpaceValidate = () => {
    let outdoorSpaceError = "";

    if (!this.state.formControls.outdoorSpace.value) {
      outdoorSpaceError = "Field is required.";
    }

    this.setState({ outdoorSpaceError });

    if (outdoorSpaceError) {
      return false;
    }

    return true;
  };

  handleSubmit = (event, isMetric) => {
    event.preventDefault();

    var length_feet = this.state.formControls.length_ft.value || 0;
    var length_inches = this.state.formControls.length_in.value || 0;
    var width_feet = this.state.formControls.width_ft.value || 0;
    var width_inches = this.state.formControls.width_in.value || 0;
    var ceiling_feet = this.state.formControls.ceiling_ft.value || 0;
    var ceiling_inches = this.state.formControls.ceiling_in.value || 0;
    var facilityLength;
    var facilityWidth;
    var ceilingHeight;

    if (this.state.units === "IMPERIAL") {
      facilityLength = Math.abs(
        parseInt(length_feet) * 12 + parseInt(length_inches)
      );
      facilityWidth = Math.abs(
        parseInt(width_feet) * 12 + parseInt(width_inches)
      );
      ceilingHeight = Math.abs(
        parseInt(ceiling_feet) * 12 + parseInt(ceiling_inches)
      );
    } else {
      facilityLength = Math.abs(
        parseFloat(length_feet) * 39.37 + parseFloat(length_inches) / 2.54
      );
      facilityWidth = Math.abs(
        parseFloat(width_feet) * 39.37 + parseFloat(width_inches) / 2.54
      );
      ceilingHeight = Math.abs(
        parseFloat(ceiling_feet) * 39.37 + parseFloat(ceiling_inches) / 2.54
      );
    }

    let primaryUse = "";

    switch (this.state.formControls.primaryUse.value) {
      case "Agriculture":
      case "Automotive_Service_Center":
      case "Hangar":
      case "Manufacturing":
      case "Picking_Sorting":
      case "Shipping_Recieving":
      case "Storage":
      case "Food_Production":
      case "Warehouse":
      case "Other":
        primaryUse = "INDUSTRIAL";
        break;
      case "Automotive_Showroom":
      case "Cafeteria":
      case "Cardio_Equipment":
      case "Gymnasium":
      case "Strength_Training":
        primaryUse = "COMMERCIAL_LARGE";
        break;
      case "Group_Exercise_Room":
      case "Library":
      case "Office":
        primaryUse = "COMMERCIAL_SMALL";
        break;
      case "Residential":
        primaryUse = "RESIDENTIAL";
        break;
      default:
        primaryUse = "INDUSTRIAL";
    }

    const isValidPrimaryUse = this.primaryUseValidate();
    const isValidGoal = this.goalValidate();
    const isValidFacilityLength = this.facilityLengthValidate();
    const isValidFacilityWidth = this.facilityWidthValidate();
    const isValidCeilingHeight = this.ceilingHeightValidate();
    const isValidAirConditioned = this.airConditionedValidate();
    const isValidOutdoorSpace = this.outdoorSpaceValidate();

    if (
      !isValidPrimaryUse ||
      !isValidGoal ||
      !isValidFacilityLength ||
      !isValidFacilityWidth ||
      !isValidCeilingHeight ||
      !isValidAirConditioned ||
      !isValidOutdoorSpace
    ) {
      return;
    }

    this.setState({ isLoading: true }, () => {
      axios
        .post(url, {
          facilityLength,
          facilityWidth,
          ceilingHeight,
          primaryUse,
          goal: this.state.formControls.goal.value,
          airConditioned: this.state.formControls.airConditioned.value,
          outdoorSpace: this.state.formControls.outdoorSpace.value,
        })
        .then((response) => {
          this.setState({
            isLoading: false,
            data: response.data,
          });
        });
    });
  };

  render() {
    const { isLoading, data } = this.state;
    const imageUrl =
      get(this, "state.formControls.primaryUse.value", "Agriculture") ||
      "Agriculture";

    let isMetric = false;
    if (this.state.units === "METRIC") {
      isMetric = true;
    } else {
      isMetric = false;
    }

    return (
      <Fragment>
        <Box className="recommendation-main top-shadow">
          <StyledGridItem container spacing={0}>
            <Grid item xs={12} sm={5} className="form overflowHidden">
              <div>
                <StyledForm>
                  <StyledH2 className="mt50">
                    Tell us about <em>your</em> space.
                  </StyledH2>
                  <Form>
                    <FormLabel text="What is its primary use? *" />
                    <StyledSelect
                      type="select"
                      name="primaryUse"
                      onChange={this.handlePrimaryUseChange}
                      required
                    >
                      <StyledOption
                        label="Select..."
                        aria-label="Select..."
                        value=""
                      >
                        Select...
                      </StyledOption>
                      <StyledOption
                        label="Agriculture"
                        aria-label="Agriculture"
                        value="Agriculture"
                      >
                        Agriculture{" "}
                      </StyledOption>
                      <StyledOption
                        aria-label="Automotive Service Center"
                        label="Automotive Service Center"
                        value="Automotive_Service_Center"
                      >
                        Automotive Service Center{" "}
                      </StyledOption>
                      <StyledOption
                        aria-label="Automotive Showroom"
                        label="Automotive Showroom"
                        value="Automotive_Showroom"
                      >
                        Automotive Showroom{" "}
                      </StyledOption>
                      <StyledOption
                        label="Cafeteria"
                        aria-label="Cafeteria"
                        value="Cafeteria"
                      >
                        Cafeteria{" "}
                      </StyledOption>
                      <StyledOption
                        aria-label="Cardio Equipment"
                        label="Cardio Equipment"
                        value="Cardio_Equipment"
                      >
                        Cardio Equipment{" "}
                      </StyledOption>
                      <StyledOption
                        aria-label="Group Exercise Room"
                        label="Group Exercise Room"
                        value="Group_Exercise_Room"
                      >
                        Group Exercise Room
                      </StyledOption>
                      <StyledOption
                        label="Gymnasium"
                        aria-label="Gymnasium"
                        value="Gymnasium"
                      >
                        Gymnasium
                      </StyledOption>
                      <StyledOption
                        label="Hangar"
                        aria-label="Hangar"
                        value="Hangar"
                      >
                        Hangar
                      </StyledOption>
                      <StyledOption
                        label="Library"
                        aria-label="Library"
                        value="Library"
                      >
                        Library
                      </StyledOption>
                      <StyledOption
                        aria-label="Manufacturing"
                        label="Manufacturing"
                        value="Manufacturing"
                      >
                        Manufacturing
                      </StyledOption>
                      <StyledOption
                        label="Office"
                        aria-label="Office"
                        value="Office"
                      >
                        Office
                      </StyledOption>
                      <StyledOption
                        aria-label="Picking/Sorting"
                        label="Picking/Sorting"
                        value="Picking_Sorting"
                      >
                        Picking/Sorting
                      </StyledOption>
                      <StyledOption
                        label="Residential"
                        aria-label="Residential"
                        value="Residential"
                      >
                        Residential
                      </StyledOption>
                      <StyledOption
                        aria-label="Shipping/Receiving"
                        label="Shipping/Receiving"
                        value="Shipping_Receiving"
                      >
                        Shipping/Receiving
                      </StyledOption>
                      <StyledOption
                        label="Storage (racking)"
                        aria-label="Storage (racking)"
                        value="Storage"
                      >
                        Storage (racking)
                      </StyledOption>
                      <StyledOption
                        label="Strength Training"
                        aria-label="Strength Training"
                        value="Strength_Training"
                      >
                        Strength Training
                      </StyledOption>
                      <StyledOption
                        label="Warehouse"
                        aria-label="Warehouse"
                        value="Warehouse"
                      >
                        Warehouse
                      </StyledOption>
                      <StyledOption
                        label="Other"
                        aria-label="Other"
                        value="Other"
                      >
                        Other
                      </StyledOption>
                      <StyledOption
                        label="Food Production"
                        aria-label="Food Production"
                        value="Food_Production"
                      >
                        Food Production
                      </StyledOption>
                    </StyledSelect>
                    <div className="error">{this.state.primaryUseError}</div>
                    <FormLabel text="What is the goal of your space? *" />
                    <StyledSelect
                      type="select"
                      name="goal"
                      value={this.state.formControls.goal.value}
                      onChange={this.handleGoalChange}
                      required
                    >
                      <StyledOption value="" label="Select..." />
                      <StyledOption value={"COOLING"} label="Cooling">
                        Cooling
                      </StyledOption>
                      <StyledOption value={"DESTRAT"} label="Destrat">
                        Destrat
                      </StyledOption>
                    </StyledSelect>
                    <div className="error">{this.state.goalError}</div>
                    <FormLabel text="Is the space air conditioned? *" />
                    <RadioGroup
                      style={{ display: "flex", flexDirection: "row" }}
                      type="radio"
                      name="airConditioned"
                      value={this.state.formControls.airConditioned.value}
                      onChange={this.handleairConditionedChange}
                      required
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Yes"
                        labelPlacement="end"
                        style={{ display: "inline-block" }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="No"
                        labelPlacement="end"
                        style={{ display: "inline-block" }}
                      />
                    </RadioGroup>
                    <div className="error">
                      {this.state.airConditionedError}
                    </div>
                    <FormLabel text="Is the space outdoors? *" />
                    <RadioGroup
                      style={{ display: "flex", flexDirection: "row" }}
                      type="radio"
                      name="outdoorSpace"
                      value={this.state.formControls.outdoorSpace.value}
                      onChange={this.handleoutdoorSpaceChange}
                      required
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio color="primary" />}
                        label="Yes"
                        labelPlacement="end"
                        style={{ display: "inline-block" }}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio color="primary" />}
                        label="No"
                        labelPlacement="end"
                        style={{ display: "inline-block" }}
                      />
                    </RadioGroup>
                    <div className="error">{this.state.outdoorSpaceError}</div>

                    <StyledH2 className="mt30">
                      Enter the size of <em>your</em> space.
                    </StyledH2>

                    <StyledToggleContainer>
                      <ToggleButtonGroup
                        value={this.state.units}
                        exclusive
                        onChange={this.handleChangeUnits}
                        aria-label="measurement"
                      >
                        <StyledToggleButtons
                          value="IMPERIAL"
                          aria-label="centered"
                        >
                          Imperial
                        </StyledToggleButtons>
                        <StyledToggleButtons
                          value="METRIC"
                          aria-label="centered"
                        >
                          Metric
                        </StyledToggleButtons>
                      </ToggleButtonGroup>
                    </StyledToggleContainer>

                    <FormLabel text="What is the length? *" />
                    <div>
                      <span>
                        <StyledInput
                          type="number"
                          name="length_ft"
                          placeholder={
                            this.state.formControls.length_ft.placeholder
                          }
                          value={this.state.formControls.length_ft.value}
                          onChange={this.handleFacilityLengthChange}
                          min="0"
                          required
                        />{" "}
                        <div>
                          {isMetric ? (
                            <StyledText>m.</StyledText>
                          ) : (
                            <StyledText>ft.</StyledText>
                          )}
                        </div>
                      </span>{" "}
                      <span>
                        <StyledInput
                          type="number"
                          name="length_in"
                          placeholder={
                            this.state.formControls.length_in.placeholder
                          }
                          value={this.state.formControls.length_in.value}
                          onChange={this.handleChange}
                          min="0"
                          required
                        />{" "}
                        <div>
                          {isMetric ? (
                            <StyledText>cm.</StyledText>
                          ) : (
                            <StyledText>in.</StyledText>
                          )}
                        </div>
                      </span>
                    </div>
                    <div className="error">
                      {this.state.facilityLengthError}
                    </div>
                    <FormLabel text="What is the width? *" />
                    <div>
                      <span>
                        <StyledInput
                          type="number"
                          name="width_ft"
                          placeholder={
                            this.state.formControls.width_ft.placeholder
                          }
                          value={this.state.formControls.width_ft.value}
                          onChange={this.handleFacilityWidthChange}
                          min="0"
                          required
                        />{" "}
                        <div>
                          {isMetric ? (
                            <StyledText>m.</StyledText>
                          ) : (
                            <StyledText>ft.</StyledText>
                          )}
                        </div>
                      </span>{" "}
                      <span>
                        <StyledInput
                          type="number"
                          name="width_in"
                          placeholder={
                            this.state.formControls.width_in.placeholder
                          }
                          value={this.state.formControls.width_in.value}
                          onChange={this.handleChange}
                          min="0"
                          required
                        />{" "}
                        <div>
                          {isMetric ? (
                            <StyledText>cm.</StyledText>
                          ) : (
                            <StyledText>in.</StyledText>
                          )}
                        </div>
                      </span>
                    </div>
                    <div className="error">{this.state.facilityWidthError}</div>
                    <FormLabel text="What is the ceiling height? *" />
                    <div>
                      <span>
                        <StyledInput
                          type="number"
                          name="ceiling_ft"
                          placeholder={
                            this.state.formControls.ceiling_ft.placeholder
                          }
                          value={this.state.formControls.ceiling_ft.value}
                          onChange={this.handleCeilingHeightChange}
                          min="0"
                          required
                        />{" "}
                        <div>
                          {isMetric ? (
                            <StyledText>m.</StyledText>
                          ) : (
                            <StyledText>ft.</StyledText>
                          )}
                        </div>
                      </span>{" "}
                      <span>
                        <StyledInput
                          type="number"
                          name="ceiling_in"
                          placeholder={
                            this.state.formControls.ceiling_in.placeholder
                          }
                          value={this.state.formControls.ceiling_in.value}
                          onChange={this.handleChange}
                          min="0"
                          required
                        />{" "}
                        <div>
                          {isMetric ? (
                            <StyledText>cm.</StyledText>
                          ) : (
                            <StyledText>in.</StyledText>
                          )}
                        </div>
                      </span>
                    </div>
                    <div className="error">{this.state.ceilingHeightError}</div>
                    <SubmitButton
                      loading={isLoading}
                      text="Get Recommendation"
                      onClick={this.handleSubmit}
                    />
                  </Form>
                </StyledForm>
              </div>
            </Grid>
            <Grid item xs={12} sm={7} className="overflowHidden">
              {isLoading ? (
                <StyledContainer className="loaderMessage mt40">
                  <StyledH2>Generating your recommendation...</StyledH2>
                </StyledContainer>
              ) : (
                Object.entries(data).length === 0 && (
                  <StyledContainer className="loaderMessage mt40">
                    <StyledH2>We take care of the rest.</StyledH2>
                  </StyledContainer>
                )
              )}
              {data.fanRecommendation ? (
                <RecommendationReceived data={data} isMetric={isMetric} />
              ) : (
                <StyledOverlay>
                  {isMobile ? (
                    <StyledBackgroundMobile
                      url={`${window.location.origin}/primary-use/${imageUrl}.jpg`}
                    ></StyledBackgroundMobile>
                  ) : (
                    <StyledBackground
                      url={`${window.location.origin}/primary-use/${imageUrl}.jpg`}
                    ></StyledBackground>
                  )}
                </StyledOverlay>
              )}
            </Grid>
          </StyledGridItem>
        </Box>
      </Fragment>
    );
  }
}

function RecommendationReceived({ ...props }) {
  const fanName = get(props, "data.fanRecommendation.fanType", "default")
    .replace(/\s/g, "")
    .toLowerCase();
  const fanTotal = get(props, "data.fanRecommendation.totalFans");
  const formattedFanName = parseFloat(
    props.data.fanRecommendation.fanSize / 39.37
  ).toFixed(1);
  const fanSizeInFt = parseInt(props.data.fanRecommendation.fanSize) / 12;
  const fanSizeInInches = parseInt(props.data.fanRecommendation.fanSize);
  const formattedDropTube = props.data.fanRecommendation.dropTube
    ? ` w/ ${props.data.fanRecommendation.dropTube.dropTubeLabel} Extension Tube`
    : "";

  return (
    <FanBackgroundContainer url={`${window.location.origin}/${fanName}-bg.jpg`}>
      <StyledContainer>
        {fanName === "default" || fanTotal === 0 ? (
          <StyledH2 className="mt75">
            We do not have a recommendation for your space.
          </StyledH2>
        ) : (
          <div>
            <StyledH2 className="mt75">
              We recommend {props.data.fanRecommendation.totalFans}...
            </StyledH2>
            {props.isMetric ? (
              <StyledH1>
                {formattedFanName}m {props.data.fanRecommendation.fanType}
              </StyledH1>
            ) : (
              <StyledH1>
                {props.data.fanRecommendation.fanType === "Haiku" ? (
                  <p>
                    {fanSizeInInches}&quot;{" "}
                    {props.data.fanRecommendation.fanType}
                  </p>
                ) : (
                  <p>
                    {fanSizeInFt}&apos; {props.data.fanRecommendation.fanType}
                    {formattedDropTube}
                  </p>
                )}
              </StyledH1>
            )}
            <ProductPackage data={props.data} />
            <StyledCaption>
              Cut sheets, install guide, submittals, etc.
            </StyledCaption>
          </div>
        )}

        <div className="mt40 center">
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <StyledImage
                src={`${window.location.origin}/${fanName}/${fanName}-1.png`}
              />
            </Grid>
            <Grid item xs={3}>
              <StyledImage
                src={`${window.location.origin}/${fanName}/${fanName}-2.png`}
              />
            </Grid>
            <Grid item xs={3}>
              <StyledImage
                src={`${window.location.origin}/${fanName}/${fanName}-3.png`}
              />
            </Grid>
            <Grid item xs={3}>
              <StyledImage
                src={`${window.location.origin}/${fanName}/${fanName}-4.png`}
              />
            </Grid>
          </Grid>
        </div>
        <div className="mt40 mb40 center">
          <h2>Want to talk to an expert?</h2>
          <RecommendationButton
            href="https://www.bigassfans.com/contact-us/"
            target="_blank"
            text="Contact Us"
          />
        </div>
        <div className="mt50 mb40 center">
          <h1 className="header-1 border-line">
            <span>Some alternative options to explore</span>
          </h1>
        </div>
        <Grid className="test" container spacing={3}>
          {props.data.alternateFans.map((fan) => (
            <AlternativeOptionsCard fan={fan} key={fan.name} />
          ))}
        </Grid>
      </StyledContainer>
    </FanBackgroundContainer>
  );
}

RecommendationReceived.propTypes = {
  data: PropTypes.object,
  isMetric: PropTypes.bool,
};

export default Recommendation;
