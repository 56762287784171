import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import get from 'lodash/get';


const useStyles = makeStyles({
  card: {
    display:"flex",
    minHeight: "130px"
  },
  cardContent: {
    flex:"1",
    padding:"0px",
    paddingBottom: "0px !important",
    alignItems: "center"
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 16,
    color: "#242424",
    fontWeight: 700
  },
  description: {
    fontSize: 11,
    color: "#242424"
  },
  pos: {
    marginBottom: 5
  },
  heightCard: {
  height: "100%;"
  }
});

const StyledGrid = styled(Grid)`
&& {
  padding:10px;
}
`;

const StyledOverlayGrid = styled(Grid)`
&& {
  padding:0px;
  background-color:#ffc425;
}
`;

const ThumbnailImage = styled.img`
&& {
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  opacity:.5;
}
`;

function AlternativeOptionsCard(props) {
  const classes = useStyles();
  const fanName = (get(props, "fan.name", "default")).replace(/\s/g, '').toLowerCase();

  return (
    <Grid item lg={6} md={6} sm={12} xs={12}>
      <a href={props.fan.url} target="_blank" rel="noopener noreferrer">
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid className={classes.card} container>
              <StyledOverlayGrid item sm={3} xs={3}>
              <ThumbnailImage src={`${window.location.origin}/thumbnails/${fanName}.png`} />
              </StyledOverlayGrid>
              <StyledGrid item sm={9} xs={9}>
                <Typography
                  className={classes.title}
                  color="textPrimary"
                  gutterBottom
                >
                  {props.fan.name}
                </Typography>
                <Typography
                  className={classes.description}
                  color="textSecondary"
                >
                  {props.fan.description}
                </Typography>
              </StyledGrid>
            </Grid>
          </CardContent>
        </Card>
      </a>
    </Grid>
  );
}

AlternativeOptionsCard.propTypes = {
  fan: PropTypes.object
};

export default AlternativeOptionsCard;
