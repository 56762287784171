import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";

function C2S4() {
  return (
    <div className="chapter-container">
      <Anchor id="2-4-1" />
      <H2 name="2.4 Process Improvement " />
      <H4 name="2.4.1 Product Cooling" />
      <p>
        Manufacturing processes often have substantial cooling needs. Parts may
        be heated during one step of a process, but need to cool before
        beginning the next step. Traditionally components are allowed to cool by
        exposing them to room temperature air between steps of the process.
        Depending on initial temperature and desired final temperature this may
        be a lengthy process; resulting in time and efficiency losses. One
        method for decreasing cooling time would be to lower the ambient
        temperature, thus increasing the temperature differential between the
        process component and the surrounding air, thus expediting heat
        transfer. However, since manufacturing processes typically take place in
        large industrial spaces. this would necessitate cooling large volumes of
        air. A more targeted and lower cost approach would be to use overhead or
        directional fans.{" "}
      </p>
      <p>
        When the traditional cooling approach of allowing components to slowly
        come to room temperature is used, heat from the object dissipates and
        warms the air around it. The warm air creates a stagnant boundary layer
        around the object, insulating it and decreasing the temperature
        difference between the object and the air immediately surrounding it.
        This reduces the convective heat transfer from the object and results in
        a slower cooling process.{" "}
      </p>
      <p>
        By adding fans, elevated air speed will blow the stagnant boundary layer
        away from the object, allowing cooler air to reach the surface and
        additional heat transfer to occur. The optimal average air speed can be
        determined by BAF’s applications engineers based on the object size and
        surface area, object material composition, typical ambient temperature,
        desired final temperature, and desired total cooling time. The fan air
        velocity profile (AVP) is also key in determining fan design, as it
        specifies air speed delivered at different points within the fan
        coverage area. Therefore, AVP is vital to determining fan layout,
        including aiming angle and mounting locations, for a cooling process. By
        considering process variables and designing an appropriate layout, fans
        can be incorporated into a process to provide efficient, cost effective
        cooling. The performance of the layout can then be validated with a CFD
        analysis using SpecLab.
      </p>

      <Anchor id="2-4-2" />
      <H4 name="2.4.2 Condensation Abatement" />
      <p>
        Another common issue encountered in process and industrial applications
        is condensation. Condensation is the collection of water droplets from
        the air onto a surface and it occurs when surface temperature equals or
        drops below the dew point. Condensation can lead to a host of issues,
        including corrosion, mold, sweating slab, and structural collapse.
        Several factors affect condensation in an indoor environment including
        structural and architectural factors (insulation, infiltration, thermal
        mass, orientation, floor slab, fenestration), mechanical factors
        (exhaust vents, intake louvers, HVAC system, building automation),
        environmental factors (dry bulb, dew point, wind speed), and air
        movement factors (air speed, air-to-surface temperature differential).
        Since condensation occurs when surface temperature equals or drops below
        the dew point, the two main methods for condensation abatement are to
        increase surface temperature or decrease dew point. Increasing surface
        temperature requires providing heat strategically to the space.
        Decreasing dew point is accomplished through dehumidification. Both of
        these options work best when a building envelope is well-sealed, which
        may require additional time and/or materials, especially in retrofit
        applications.{" "}
      </p>
      <p>
        As stated previously, air movement can also have an impact on
        condensation. Elevated air speed enhances convection, dries
        condensation, and circulates warm air. Designed properly, a fan systems
        has the ability to reduce condensation at a lower initial cost and
        significantly lower operating cost than several other methods of
        condensation abatement. Fans may also be used in conjunction with other
        methods, such as dehumidification and radiant or forced air heaters, to
        enhance abatement. To optimize operation, all condensation abatement
        equipment should be integrated through the use of a control system, such
        as BAF’s Dewtect. Dewtect is an autonomous control system for HVLS fans,
        exhaust fans, indirect-fired heaters, and dehumidifiers which uses
        sensors and advanced algorithms to balance temperature, humidity, and
        airflow to reduce the risk of condensation and resulting side effects.
        By monitoring environmental conditions and recording historical data
        trends, Dewtect proactively mitigates condensation before it occurs. It
        also optimizes runtimes of all equipment to reduce overall energy
        consumption.{" "}
      </p>
    </div>
  );
}

export default C2S4;
