import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Link from "@material-ui/core/Link";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const StyledLink = styled(Link)`
    color: #ffc425;
    font-weight: 600;
    font-size: 1em;
    text-decoration: underline;
    font-family: industry, sans-serif;
    text-transform:uppercase;
    @media (max-width: 768px) {
      font-size: .725em;
    }
`;

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  && {
    width: 16px;
    margin-left: 5px;
    vertical-align: text-bottom;
  }
`;

function CaseStudyLink(props) {
  return (
    <StyledLink href={props.href} target={props.target}>
      {props.text} <StyledArrowForwardIcon />
    </StyledLink>
  );
}

CaseStudyLink.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string
};

export default CaseStudyLink;
