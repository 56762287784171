import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C3S4() {
  return (
    <div className="chapter-container">
      <Anchor id="3-4-1" />
      <H2 name="3.4 Lighting Integration" />
      <H4 name="3.4.1 Fan location in lighting layout" />
      <p>
        When luminaires are located at an elevation higher than the ceiling fan
        blade height, a risk of stroboscopic effect or shadowing exists.
        stroboscopic effect is a result of the fan blades creating a shadow
        under the luminaire each time the blade passes between the luminaire and
        an occupant below. This is illustrated in Figure 3.4.1b. stroboscopic
        effect can be disruptive and distracting to occupants. In extreme
        situations, such as industrial applications, stroboscopic effect has the
        potential to create a potentially dangerous environment.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1a.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.4.1a - The above graphic is a typical candela plot indicating beam angle that would be provided by a luminaire manufacturer as part of the product design data." />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1b.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.4.1b - Luminaires are placed in a location that will create a stroboscopic effect. The fan blades interfere with the luminaire light pattern." />
      </Container>

      <p>
        To reduce the potential for stroboscopic effect, coordination between
        the luminaire and ceiling fan placement is required. In general,
        mitigation of stroboscopic effect is achieved by shifting the luminaires
        vertically or horizontally such that the lighting throw pattern of the
        luminaire is not interrupted by the fan blades. Figure 3.4.1c provides
        an example of shifting the luminaires horizontally to reduce
        stroboscopic effect and Figure 3.4.1d provides an example of shifting
        the luminaires vertically. Where luminaires are shifted horizontally, it
        may be necessary to improve the lighting beneath the fan to maintain
        uniform illumination at the work surface. To achieve this, ceiling fans
        can be specified with optional LED luminaires that mount to the hub of
        the fan and illuminate the area directly below the fan without
        generating a stroboscopic effect or shadowing. An example of a hub
        mounted luminaire is pictured in Figure 3.4.1e. The hub mounted
        luminaire can be selected to match the color temperature of the other
        luminaires in the space and the lighting output can be adjusted to
        maintain uniform illumination at the work surface.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1c.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.4.1c - Luminaires are mounted at the same height as in the previous figure but have been shifted horizontally to reduce the stroboscopic effect by reducing the fan blade interference with the light pattern." />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1d.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.4.1d - Luminaire horizontal spacing is maintained but the mounting height is lowered to reduce the fan blade interference with the light pattern." />
      </Container>

      <p>
        Luminaires are generally provided with data that documents the lighting
        throw patterns of the luminaire. This data is available in two
        dimensional graphical representations as illustrated in the Figure
        3.4.1a. These two dimensional throw patterns are helpful for
        approximating appropriate spacing but do not offer the precision and
        accuracy of computer modeling. Throw patterns for luminaires are also
        typically available as electronic files for use in lighting modeling
        software. This software can be used to optimize luminaire locations
        within a space. Ideally, the luminaires and the ceiling fan can both be
        input into the lighting modeling software to best understand the effects
        of the fan on the light distribution. To effectively model the fan in
        the software, the fan can be modeled as a simple shape such as a flat
        disc representing the rotating blades or a cylinder of the fan diameter
        that extends down from the ceiling to the blade height. If the fan
        representation in the model generates an area of significantly lower
        lighting level as illustrated in Figure 3.4.1f, a stroboscopic effect
        will result within that area.
      </p>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1e.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.4.1e - Where ceiling fan installations create a gap in lighting, an LED luminaire matching the color temperature and lumen output can be added to the fan to compensate and maintain lighting uniformity." />
      </Container>

      <Container maxWidth="sm" className="center mt40 mb40">
        <Image
          src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-4-1f.png"
          width="100%"
          alt=""
        />
        <Caption text="Figure 3.4.1f - The above illustrates the inclusion of a ceiling fan in lighting modeling software. This result demonstrates the approximate result of the layout illustrated in Figure 3.4.1b." />
      </Container>
    </div>
  );
}

export default C3S4;
