import React, { Fragment } from "react";
import styled from "styled-components";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Copyright from "./UI-Kit/Copyright";
import CaseStudyLink from "./UI-Kit/CaseStudyLink";
import Next from "@material-ui/icons/NavigateNext";
import Prev from "@material-ui/icons/NavigateBefore";
import { Grid, Link } from "@material-ui/core";
import MobileRecommendationButton from "./UI-Kit/Mobile-Recommendation";

const isMobile = window.innerWidth <= 500;
const NavText = styled.p`
    color: #bbb;
    text-transform: uppercase;
    font-family: 'Proxima Nova W15';
    font-weight: 500;
    font-size: 1.05em;
    @media (max-width: 768px) {
      font-size: .825em;
    }
`;
const NextIcon = styled(Next)`
    color: #bbb;
    vertical-align: text-bottom;
    @media (max-width: 768px) {
      font-size: 1.15em !important;
    }
`;
const PrevIcon = styled(Prev)`
    color: #bbb;
    vertical-align: text-bottom;
    @media (max-width: 768px) {
      font-size: 1.15em !important;
    }
`;
const NextContainer = styled(Grid)`
    text-align: right;
    margin-top: 20px;
`;
const PrevContainer = styled(Grid)`
    text-align: left;
    margin-top: 20px;
`;
const StyledLink = styled(Link)`
  && {
    color: #bbb;
  }
`;

const styles = {
  media: {
    height: 0,
    paddingTop: "45%",
    opacity: ".5"
  },
  card: {
    position: "relative",
    backgroundColor: "#000"
  }
};

export function BullittCenter() {
  return (
    <Fragment>
      <div className="main-content">
        <h5 className="mt10 mb0 light-grey uppercase"> Case Study </h5>
        <h1 className="header-2 border-line right mt5">
          <span>The Bullitt Center</span>
        </h1>
        <Card style={styles.card}>
          <CardMedia
            image={`${window.location.origin}/Bullitt-Center.jpg`}
            style={styles.media}
          />
          <div className="overlayCaseStudy">
            <h1 className="overlayCaseStudyText">
              Ceiling fans are a key part of the strategy in achieving
              world-class commercial building performance and delivering a
              comfortable indoor environment for office workers.
            </h1>
            <CaseStudyLink
              text="View Case Study"
              href={`${window.location.origin}/US_CBE_CaseStudy_BullittCenter.pdf`}
              target="_blank"
            />
          </div>
        </Card>

        <Grid container spacing={0}>
          <NextContainer item xs={12} sm={12}>
          <NavText>
            <StyledLink href="/franco-center">
              Franco Center
              <NextIcon />
            </StyledLink>
          </NavText>
          </NextContainer>
        </Grid>
      </div>
      {isMobile ? <MobileRecommendationButton /> : null}
      <Copyright
        text="Copyright 2019 Delta T, LLC. All rights reserved"
        className="mt0"
      />
    </Fragment>
  );
}

export function FrancoCenter() {
  return (
    <Fragment>
      <div className="main-content">
        <h5 className="mt0 mb0 light-grey uppercase"> Case Study </h5>
        <h1 className="header-2 border-line right mt5">
          <span>Franco Center</span>
        </h1>
        <Card style={styles.card}>
          <CardMedia
            image={`${window.location.origin}/franco-center.jpg`}
            style={styles.media}
          />
          <div  className="overlayCaseStudy">
            <h1  className="overlayCaseStudyText">
              Energy retrofits of a senior living facility shows how ceiling
              fans integrated with air conditioning can deliver thermal comfort
              improvements and energy savings for community housing.
            </h1>
            <CaseStudyLink
              text="View Case Study"
              href={`${window.location.origin}/US_CBE_CaseStudy_FrancoCenter.pdf`}
              target="_blank"
            />
          </div>
        </Card>
        <Grid container spacing={0}>
          <PrevContainer item xs={6} sm={6}>
            <NavText>
              <PrevIcon />
              <StyledLink href="/bullitt-center">
                The Bullitt Center
              </StyledLink>
            </NavText>
          </PrevContainer>
          <NextContainer item xs={6} sm={6}>
            <NavText>
              <StyledLink href="/coastal-biology-building">
                Coastal Biology Building
              </StyledLink>
              <NextIcon />
            </NavText>
          </NextContainer>
        </Grid>
      </div>
      {isMobile ? <MobileRecommendationButton /> : null}
      <Copyright
        text="Copyright 2019 Delta T, LLC. All rights reserved"
        className="mt0"
      />
    </Fragment>
  );
}

export function CoastalBiologyCenter() {
  return (
    <Fragment>
      <div className="main-content">
        <h5 className="mt0 mb0 light-grey uppercase"> Case Study </h5>
        <h1 className="header-2 border-line right mt5">
          <span>Coastal Biology Building</span>
        </h1>
        <Card style={styles.card}>
          <CardMedia
            image={`${window.location.origin}/coastal_biology_building.jpg`}
            style={styles.media}
          />
          <div  className="overlayCaseStudy">
            <h1  className="overlayCaseStudyText">
              Thoughtful design of a world-class research facility highlights
              the benefits of ceiling fans in naturally ventilated spaces in
              ensuring occupant thermal comfort in educational settings.
            </h1>
            <CaseStudyLink
              text="View Case Study"
              href={`${window.location.origin}/US_CBE_CaseStudy_CoastalBiologyBuilding.pdf`}
              target="_blank"
            />
          </div>
        </Card>
        <Grid container spacing={0}>
          <PrevContainer item xs={12} sm={12}>
            <NavText>
              <PrevIcon />
              <StyledLink href="/franco-center">
                Franco Center
              </StyledLink>
            </NavText>
          </PrevContainer>
        </Grid>
      </div>
      {isMobile ? <MobileRecommendationButton /> : null}
      <Copyright
        text="Copyright 2019 Delta T, LLC. All rights reserved"
        className="mt0"
      />
    </Fragment>
  );
}
