import React, {useState} from 'react';
import {TableRow, TableCell, Table, TableBody, TableHead } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const data = {
    "Manufacturing":{
        "goals":"Occupant Cooling, Process Cooling, Destratification, Condensation Mitigation",
        "selection":"Overhead industrial fans, preferably PFX 3.0, should be recommended in open areas or areas with primarily floor-level obstructions. Directional spot cooling fans can be recommended where the overhead fans may not direct air where needed. AirEye is the most economical directional option for smaller areas, work cells, etc. where airflow from overhead fans is not attainable or practical. AirEye produces minimal sound levels relative to similar products in the industry. The AirEye Occupancy Sensor (AEOS) should be added in areas that are frequently unoccupied to maximize overall energy savings. Yellow Jacket should be recommended for similar applications in heavy duty environments. Pivot 2.0 should be recommended in larger areas where air movement from overhead fans is not attainable or practical. Pivot 2.0 must maintain a minimum cage height of 10 feet AFF.",
        "areas":{
            "Dock Areas":"Fans should be as close as possible to the doors to offer the most cooling to the dock workers while meeting all safety and performance clearances. Typically, we try to use larger industrial fans in these types of areas since they usually contain fewer larger obstructions.			",
            "Work Stations":"It is important to know where occupants will be located to make an accurate recommendation. If this is not given, general assumptions can be made, and a preliminary layout provided. However, a discussion with the customer must be had prior to final purchase to ensure that the design will meet customer expectations. Fewer, larger diameter fans are often not the best recommendation for these types of areas. Large obstructions will restrict air flow at floor level, therefore more instances of airflow are required. This is achieved by placing a greater quantity of smaller diameter fans over specific target areas.			",
            "Racking Areas":"Unless specifically requested, we typically do not provide a recommendation for these areas. This is due to the transient nature of occupants in these spaces as well as most occupants riding in moving vehicles generating their own airflow. If fans are needed in these areas to meet a specific customer goal, spot cooling fans are usually recommended. When required, fan selection in these areas varies and is largely dependent on the aisle width, racking height, and clear height above product."
        },
        "considerations":{
            "Process Heat":"If the goal of a project is cooling, it is best to recommend overhead fans in areas that are away from machines that reject significant heat into the ceiling above. If fans are needed in areas that contain heat sources, it is recommended that floor level spot cooling fans be used. This will allow cooler air to impact occupants rather than moving the hottest air that is at the roof deck back to floor level.			",
            "Cranes":"Please consult an applications engineer or additional crane-specific BAF resources prior to recommending fans above cranes. We attempt to first use industrial fans in these areas, but commercial fans may be a secondary option dependent on the ceiling height and customer goals. If there is insufficient clearance for overhead fans, spot cooling fans can be recommended.			",
            "Welding":"If welding is taking place in the facility, please consult an applications engineer for additional welding specific resources. PFX 3.0s with the harsh environment package should be recommended as an optimal preference for these types of areas."
        }
    },
    "Distribution/Logistics":{
        "goals":"Occupant Cooling, Destratification, Condensation Mitigation",
        "selection":"Overhead industrial fans, preferably PFX 3.0, should be recommended in open areas or areas with primarily floor-level obstructions. Directional spot cooling fans can be recommended where the overhead fans may not direct air where needed. AirEye is the most economical directional option for smaller areas, work cells, etc. where airflow from overhead fans is not attainable or practical. AirEye produces minimal sound levels relative to similar products in the industry. The AirEye Occupancy Sensor (AEOS) should be added in areas that are frequently unoccupied to maximize overall energy savings. Yellow Jacket should be recommended for similar applications in heavy duty environments. Pivot 2.0 should be recommended in larger areas where air movement from overhead fans is not attainable or practical. Pivot 2.0 must maintain a minimum cage height of 10 feet AFF.",
        "areas":{
            "Dock Areas":"Fans should be as close as possible to the doors to offer the most cooling to the dock workers while meeting all safety and performance clearances. Typically, we try to use larger industrial fans in these types of areas since they usually contain fewer larger obstructions.			",
            "Packing lines" : "It is important to know where occupants will be located to make an accurate recommendation. If this is not given, general assumptions can be made and a preliminary layout provided. However, a discussion with the customer must be had prior to final purchase to ensure that design will meet customer expectations.  Fewer, larger diameter fans are often not the best recommendation for these types of areas. Large obstructions will restrict air flow at floor level, therefore more instances of airflow are required. This is achieved by placing a greater quantity of smaller diameter fans over specific target areas. Directional fans are also a viable option due to their flexible mounting methods and focused airflow.			",
            "Racking Areas":"Unless specifically requested, we typically do not provide a recommendation for these areas. This is due to the transient nature of occupants in these spaces as well as most occupants riding in moving vehicles generating their own airflow. If fans are needed in these areas to meet a specific customer goal, spot cooling fans are usually recommended. When required, fan selection in these areas varies and is largely dependent on the aisle width, racking height, and clear height above product."
        },
        "considerations":{
            "Dock Door Footprint":"Our typical recommendation is to recommend fans in dock areas as close to the walls as possible to offer the highest air velocities to dock workers. Dock areas have bay doors that often extend into the areas. We must know if the dock doors are coiled, on overhead horizontal tracks, or travel vertically on a wall track to ensure fans meet proper safety and performance clearances.",
            "Elevated Conveyors":	"These often run near floor-level processes and are elevated to allow for additional process space at floor level. While typically not staffed and therefore not an area of concern, possible interference with elevated conveyor structure must be considered when recommending HVLS fans.",			
            "Product Quality Issues":	"Facility conditions such as temperature and humidity sometimes present potential product quality concerns. Due to the past success of HVLS fans in condensation mitigation, customers often assume that simply providing air movement in a facility will keep condensation from developing on product regardless of product storage and packaging methods. Furthermore, many assume that placing fans in one portion of a facility will provide full destratification in other areas as well. Neither of these assumptions is correct, and it is highly recommended to consult an applications engineer before making a recommendation for product quality applications.			"
        }
    },
    "Automotive Service":{
        "goals":"Occupant Cooling, Destratification, Condensation Mitigation",
        "selection":"Overhead industrial fans, preferably PFX 3.0, should be recommended in open areas or areas with primarily floor-level obstructions. Directional spot cooling fans can be recommended where the overhead fans may not direct air where needed. AirEye is the most economical directional option for smaller areas, work cells, etc. where airflow from overhead fans is not attainable or practical. AirEye produces minimal sound levels relative to similar products in the industry. The AirEye Occupancy Sensor (AEOS) should be added in areas that are frequently unoccupied to maximize overall energy savings. Yellow Jacket should be recommended for similar applications in heavy duty environments. Pivot 2.0 should be recommended in larger areas where air movement from overhead fans is not attainable or practical. Pivot 2.0 must maintain a minimum cage height of 10 feet AFF.",
        "areas":{
            "Drive Lanes":	"Overhead fans are typically recommended in these areas. Fans are spaced to provide sufficient air movement into each of the vehicle service bays adjacent to the drive lane. Sizing is heavily dependent on the locations of car lifts, exhaust and line drops, and radiant heaters. ",			
            "Vehicle Service Bays":	"These areas are typically located immediately adjacent to a drive lane. Overhead fans located in the drive lane are spaced to provide sufficient air movement into the individual bays. In service centers with heavily obstructed service bays, supplemental spot cooling fans can be used in areas where HVLS airflow cannot reach the occupants.			",
            "Customer Check-In Areas":	"These areas tend to be warmer in the spring and summer months and are often neglected. A customer’s first impression means everything. Commercial fans are sometimes utilized for these areas due to their quiet operation and high-end aesthetic.			"
        },
        "considerations":{
            "Car Lifts":	"Configurations vary by site, but nearly all service center applications have multiple throughout the facility. The total height of these obstructions when raised and loaded with a vehicle must be considered when placing overhead fans. As a general rule, HVLS fans are not placed within the footprint of a lift.			",
            "Exhaust and Line Drops":	"These typically exist near or within the footprint of the service bays and lifts. While these are sometimes fed at floor level, they are more often fed and hung from the roof structure. Given the flexible nature of line drops, it is imperative that any HVLS fan be outside the reasonable extents of the drops.			",
            "Radiant Heaters":	"Radiant/IR heaters are more common to automotive applications than any other. Radiant heaters are often placed along the drive lanse and have manufacturer specific clearances that must be maintained. BAF guidelines state that no part of an HVLS fan should spin under a radiant heater and all HVLS fans near radiant heaters should have a remote mounted VFD.			",
            "Bay Doors":	"Service lanes often have bay doors that extend into the working area. We must know if the bay doors are coiled, on overhead horizontal tracks, or travel vertically on a wall track to make an accurate recommendation. 			",
            "Carbon monoxide":	"HVLS fans can dilute carbon monoxide levels in a facility by evenly distributing particles throughout the facility rather than allowing them to concentrate in specific occupied zones. This is especially helpful in facilities with undersized exhaust systems. Service centers often utilize sensors to monitor and maintain acceptable levels. To ensure that carbon monoxide sensors get a reading reflective of actual levels in the space, fans should be placed at least one diameter from the center of the fan to the sensor where possible, and under no circumstances should a carbon monoxide sensor exist within the swept diameter of the fan.			",
        }
    },
    "Hangar":{
        "goals":"Occupant Cooling, Destratification, Condensation Mitigation",
        "selection":"Overhead industrial fans, preferably PFX 3.0, should be recommended in open areas or areas with primarily floor-level obstructions. Directional spot cooling fans can be recommended where the overhead fans may not direct air where needed. AirEye is the most economical directional option for smaller areas, work cells, etc. where airflow from overhead fans is not attainable or practical. AirEye produces minimal sound levels relative to similar products in the industry. The AirEye Occupancy Sensor (AEOS) should be added in areas that are frequently unoccupied to maximize overall energy savings. Yellow Jacket should be recommended for similar applications in heavy duty environments.Pivot 2.0 should be recommended in larger areas where air movement from overhead fans is not attainable or practical. Pivot 2.0 must maintain a minimum cage height of 10 feet AFF.",
        "areas":{
            "Hangar Floor":	"Since hangars are typically unconditioned and open, the AOC is typically made up of the entire hangar floor. HVLS fans are typically placed wherever possible if clearances permit, with supplemental spot cooling fans being used when limited deck or crane clearances dictate.",			
        },
        "considerations":{
            "Location of Plane(s)":	"Nearly all fan goals depend on airflow reaching floor level. Since most commercial aircraft have a significant clearance height above ground level as well as a large footprint, it is important for fans not to be placed directly above the aircraft. It is recommended to gather typical aircraft models housed within a hangar as well as their respective locations and orientations.			",
            "Tail of Plane(s)":	"The maximum clear height of the plane, which almost always coincides with the tail height, is important to keep in mind when making a fan recommendation to ensure that BAF clearances are met. If tail height is unknown, bay door height can be used as an estimate for plan clear height.			",
            "Crosswinds":	"Hangar doors are typically much larger than typical bay doors found in other applications, often making up a large portion of at least one wall of the hangar. This large opening makes any HVLS fan highly susceptible to crosswinds. Big Ass Fans maintains specific guidelines for placing fans in indoor or outdoor spaces susceptible to crosswinds which are critical to a safe and successful recommendation. It is required that any HVLS fan be above the hangar door and that guy wires be added for additional protection against crosswinds.			",
            "Cranes":	"Please consult an applications engineer or additional crane-specific BAF resources prior to recommending fans above cranes. We attempt to first use industrial fans in these areas, but commercial fans may be a secondary option dependent on the ceiling height and customer goals. If there is insufficient clearance for overhead fans, spot cooling fans can be recommended.			",
        }
    },
    "Gymnasium":{
        "goals":"Occupant Cooling, Destratification",
        "selection":"Overhead industrial fans, preferably PFX 3.0, should be recommended in active areas where fans may interact with athletic balls on occasion. Commercial fans may be used over inactive areas such as bleachers, especially in gymnasiums where sound and/or clear height is at a premium. Directional spot cooling fans can be recommended where the overhead fans may not direct air where needed.",
        "areas":{
            "Courts":	"These spaces are open and unobstructed by design. Larger overhead fans should be recommended to create higher air velocities with a lower quantity to provide cooling to the players. Constraints created by goals and other obstructions may dictate a larger quantity of smaller fans. Please see Gymnasium Specific Considerations below for more information.",			
            "Bleachers/Seating for Bystanders":	"Fans should be recommended so that the views of the bystanders and cameras are not obstructed by the overhead fans. Commercial fans are often used in these areas due to their flexibility in low ceiling environments. Note that overhead fans must be at least 10’ above the tallest bleacher in the area directly below the fans.	"		
        },
        "considerations":{
            "Basketball Goals":	"Knowing the location of basketball goals is required to make an accurate recommendation. In many gymnasiums, one or more goals will retract up to the building structure by pivoting either sideways or forward. Fans must remain outside the full swept path of the retractable goals.			",
            "Curtains":	"If basketball courts will be separated by curtains, the curtain locations must be known to make an accurate recommendation. The presence of a curtain typically necessitates additional fans. Curtains should be treated like full height walls when specifying sizing and spacing based on BAF fan clearance guidelines. A recommendation for a gymnasium with curtains should be designed such that proper performance clearances and coverage can be achieved both with and without the curtain lowered.	",		
            "Sport-Specific Clearance Heights":	"Depending on the level of sport being played in a given gymnasium, the league authority likely has a minimum clearance height required for play. This clear height varies between sports, so it is important to understand which sports will be played in multi-use gymnasiums. When a minimum clear height is not provided, BAF recommends maintaining 25’ feet above finished floor (AFF) where possible. Customer sign-off is recommended when fans spin less than 25’ AFF.			",
            "Central Scoreboards/Speaker Systems":	"The presence of a central hanging scoreboard or speaker system typically necessitates additional fans. While a single fan could be offset from the center of the space to reduce cost, it is more aesthetically pleasing to use two or more fans symmetrically in the space.			",
            "Lighting":	"Just like any other space, it is important to coordinate lighting with fans in gymnasiums. The high reflectivity of gymnasium floors combined with fan shadowing can make for an uncomfortable player and spectator experience. To minimize the impact of shadowing, fans should be centered between light fixtures where possible and spin at the same height or above lights. Absolute minimum 2’ safety clearance must be maintained between airfoils and any light fixture.			",
        }
    },
    "Fitness Center":{
        "goals":"Occupant Cooling, Destratification, Indoor Air Quality",
        "selection":"Overhead industrial or commercial fans, preferably PFX 3.0 or Essence fans, should be recommended in open areas or areas with primarily floor-level obstructions. Directional spot cooling fans can be recommended where the overhead fans may not direct air where needed.",
        "areas":{
            "Cardio":	"When deck height allows, these spaces typically utilize overhead fans spaced as closely as possible while maintaining minimum performance clearances. Fans should be placed above cardio equipment if possible for smaller cardio spaces, and above open aisleways for larger areas where fans must cover a larger area.		",	
            "Free Weights":	"When deck height allows, these spaces also utilize overhead fans. Fans are typically equally spaced throughout the area given the relatively large amount of open floor in a free weight area.			",
            "Studio/Classroom":	"These spaces typically have a lower ceiling height . Depending on activities in the space, either residential overhead or directional fans may be the most appropriate option.			",
        },
        "considerations":{
            "Equipment":	"The location of equipment informs the designer about where occupants will be concentrated and is required to make an accurate recommendation. For taller equipment, the height should be noted to ensure that all safety clearances are met.		",	
            "Lighting":	"Given the large number of reclined machines and highly reflective surfaces (equipment, displays, televisions) typically found in fitness facilities, it is imperative that fans be coordinated with lighting grid to avoid noticeable shadowing.		",	
            "Hanging Televisions":	"Televisions are often hung from the ceiling structure, especially in open cardio areas. Fans should be placed to not block line of sight to televisions from any point in the impacted area.			",
        }
    },
    "Natatorium":{
        "goals":"Mixing of Chloramines, Occupant Cooling, Destratification",
        "selection":"1. Per Big Ass Fans guidelines, the following fan models are recommended for use in natatorium environments:				- Powerfoil X3.0 with the harsh environment package and stainless steel hardware				- Powerfoil D with stainless steel hardware				- Isis Outdoor with stainless steel hardware				- Essence with stainless steel hardware				                2. Fans must include the airfoil restraint system				3. Fans must be installed at least 10ft (3m) from the surface of the water				4. An annual inspection of all critical hardware, including mounting, airfoil, and safety system hardware is required. (Proof of annual inspection may be required for warranty claims				",
        "areas":{
            "Pools":	"Pool surfaces are where the majority of chloramines are concentrated. Fans should be placed centered over the pool if possible, especially in larger competitive swimming pools. In recreational natatorium spaces where there may be multiple smaller pools or the overall square footage may be less, fans may be placed between pools over open deck to work more easily with customer budget.			",			
            "Spectator Seating":	"Natatoriums tend to be warm and humid environments. While this is not a concern for athletes during competition, spectators can easily become uncomfortable during long periods of competition. HVLS fans can provide cooling airflow to viewers while not altering the temperature of the space and negatively impacting athletes.			",
        },
        "considerations":{
            "Installation":	"More so than in any other facility type, installation methods are of chief concern in natatorium applications. Common installation methods include boom lifts and scaffolding, the latter of which often involves the time consuming and potentially expensive process of draining the pool itself. Be aware of customer limitations to installation prior to completing a recommendation, and be prepared to provide alternative options when needed.		",		
            "Lighting":	"It is critical to coordinate lighting with fans to prevent shadowing due to the highly reflective surface of the pool. Fans should spin at or above the height of all light fixtures if possible, while maintaining all recommended clearances.			",
            "Building Criteria":	"1. The space must meet ASHRAE standard 62.1 (Ventilation for Acceptable Indoor Air Quality) and all local electrical and safety codes.			2. The space must have an adequate and functioning ventilation system. Check that the other appliances and equipment (such as lighting fixtures) located in the space are well-maintained and do not show signs of corrosion.			",
        }
    },
    "Dairy Barn":{
        "goals":"Cooling of Dairy Cattle, Destratification        ",
        "selection":"Overhead industrial fans, preferably PFX 3.0 with Harsh Environment Package or Powerfoil D, should be recommended in areas directly over the cattle. Directional spot cooling fans can be recommended where the overhead fans may not direct air where needed or in barns where customer specified airflow requirements exceed those attainable with HVLS fans. Combinations of overhead and directional fans have been proven highly effective in demanding environments. The Yellow Jacket and Pivot 2.0 should be recommended where airflow from overhead fans is not attainable or practical. Pivot 2.0 must maintain minimum cage height of 10 feet AFF. All directional fans should be mounted above occupant level unless specifically requested by the customer. Floor fans are not recommended for this application.",
        "areas":{
            "Free Stall Pens":	"Where deck height allows, overhead fans are placed over stall rows where cattle spend most of their time. Since cattle tend to congregate, fans are closely spaced ~2-3X diameters apart to maximize airfoil area overhead.	",		
            "Feed Aisles":	"Typically, we do not focus on airflow in feed aisles. These are typically only occupied by feeding equipment and other farm vehicles.			",
        },
        "considerations":{
            "Open-air Siding":	"Many dairy barns are at least partially open-air, often on multiple sides of the barn. Open-air facilities do not provide the recirculation effects of fully enclosed spaces. Furthermore, considerations must be made for wind exposure mitigation in these environments.			",
            "Increased Air Velocity Requirements":	"Many dairy farmers have very specific requests for average air velocities in their facilities. These requirements may dictate a greater number or different type of fan than would typically be recommended. It is imperative that the designer fully understand the customer’s requirements in order to provide a solution that meets their needs.			",
        }
    },
    "Equine Stable":{
        "goals":"Equine Cooling, Destratification",
        "selection":"Overhead fan design in equine barns is highly dependent on the desired aesthetic and spatial limitations of the barn floor plan. Limited aisleway width and floor to ceiling columns often dictate smaller diameter fans, which in turn leads to commercial options being recommended disproportionately relative to what one would expect in an agricultural space. If there are no spatial and/or aesthetic constraints, the PFX3.0 with Harsh Environment package is the optimal solution. Designing for smaller functional areas such as horse stalls or tack rooms often requires the use of directional or residential fans that may be less common in agricultural applications. Please see the Areas of Concern section below for typical recommendations in specific functional areas.				",
        "areas":{
            "Stalls":	"Depending on customer’s desired aesthetic, either (1) AirEye or (1) Haiku is recommended for each stall. The AirEye caged design may allow for greater flexibility given the occasionally low clear height of horse stalls relative to the aisleways. In rare cases where deck height and building structure allow for larger HVLS fans, a single fan may be used to serve multiple stalls.			",
            "Aisleways":	"Overhead fans are typically recommended when clearances allow. The diameter of these fans is typically limited by aisleway width and columns. 			",
            "Work Areas":	"Haikus or AirEyes are typically recommended in additional work areas. The fan type, size, and quantity are dependent on the number of work areas and the size of the rooms.			",
        },
        "considerations":{
            "Stall Partition Walls":	"Stall walls can significantly limit the coverage area of overhead fans. Unless stall walls are constructed from chain or open bars from bottom to top, overhead fans in aisleways should not be expected to provide coverage to adjacent stalls.			",
            "Wash Stalls":	"Many equine barns have interior wash stalls. Fans should not be placed near these stalls to keep from disrupting the wash process and protect fans from excessive moisture.			",
            "Open-air Siding":	"Many equine barns are at least partially open-air, often on multiple ends of the barn. Open-air facilities do not provide the recirculation effects of fully enclosed spaces. Furthermore, considerations must be made for wind exposure mitigation in these environments.		",	
        }
    },
    "Equine Riding Area":{
        "goals":"Occupant Cooling, Destratification",
        "selection":"Overhead industrial fans, preferably PFX 3.0 with the Harsh Environment Package or Powerfoil D, should be recommended in areas directly over the arena. Directional spot cooling fans can be recommended where required clearance heights do not allow for overhead fans. The Yellow Jacket and Pivot 2.0 should be recommended where airflow from overhead fans is not attainable or practical. All fans over the riding area must be mounted at minimum clearance height specified by the customer, with 10ft being the absolute minimum. Floor fans are not recommended for this application.",
        "areas":{
            "Arena Floor":	"Overhead fans are the optimal solution if deck/overhead clearance height allows. Industrial fans are recommended due to the large amount of dust generated by equestrian activities.			",
            "Bleachers/Seating for Bystanders":	"Fans should be recommended so that the views of the bystanders and cameras are not obstructed by the overhead fans. Commercial fans are often used in these areas due to their flexibility in low ceiling environments. Customers should be aware that increased preventative maintenance may be required if commercial fans are chosen for a riding arena. Note that overhead fans must be at least 10’ above the tallest bleacher in the area directly below the fans.		",	        
        },
        "considerations":{
            "Clear Height":	"Riding arena owners typically specify a clearance height greater than 10ft above finished floor (AFF) to minimize the risk of fans interacting with horses and riders. If the customer 			",
            "Open-air Siding":	"Many riding arenas are at least partially open-air, often on multiple sides of the barn. Open-air facilities do not provide the recirculation effects of fully enclosed spaces. Furthermore, considerations must be made for wind exposure mitigation in these environments.			",
        }
    },
    "Auditorium":{
        "goals":"Occupant Cooling, Air Mixing, Destratification",
        "selection":"Overhead commercial fans, preferably Essence, should be recommended directly over the occupants for cooling and over open aisleways for destratification and air mixing. Industrial fans may be recommended in partially outdoor or open-air environments where sound levels are not as much of a concern and greater air velocities are required.",
        "areas":{
            "Patron Seating":	"For cooling, fans should be recommended over seating areas approximately 2-diameters apart so that the occupants receive the greatest amount of perceived cooling possible and meet said goals. Total airfoil area over occupants should be maximized. Depending on the layout of the space, this may mean that a smaller number of larger diameter fans or a larger number of smaller diameter fans may be best. It should be noted that smaller diameter commercial fans do create greater air velocities than larger diameters.			"
        },
        "considerations":{
            "Theatrical Lighting":	"Fans must neither interfere with support structures for theatrical lighting nor spin within direct line of sight between lights and the stage. 		",	
            "Patron Sightlines":	"Fans must not spin within sightlines between all patron seating locations and every portion of the stage or any projection screen. This same principle applies to any AV booths.			",
            "Catwalks":	"Many auditorium spaces have catwalks for general maintenance. Fans must be placed to avoid catwalk support structures and spin out of reach of any catwalk occupant. Fan clearance distance to the catwalk is highly dependent on spinning height relative to catwalk platform. Consult an applications engineer if clearances are questionable.			",
            "Acoustical/decorative ceiling tiles":	"Suspended acoustical ceiling tiles are rare but can create significant problems with fan placement. Fans should never spin directly above an acoustical tile and should always maintain absolute minimum 2’ clearances to any cabling. If possible, it is recommended that fans spin at or below the level of the clouds to reduce impact of fans on cloud system.			",
        }
    },
}

function cleanName(name){
    return name.replace(/\//g, ' ');
}
 
function ReactRow(props){
    const [expanded, setExpanded] = useState(false);
    if(expanded){
        return (
        <TableRow style={{background: props.background}}>
            <TableCell style={{fontWeight:"bold", cursor: "pointer"}} onClick={e => {setExpanded(!expanded)}}>{props.name}</TableCell>
            <TableCell>
                {props.goals}   
            </TableCell>
            <TableCell style={{fontSize: "0.75rem"}}>
                {props.selection}
                <br />
                <img alt={`${props.name}`} src={`${window.location.origin}/verticalMarket/${cleanName(props.name)}.jpg`} style={{display: "block", marginLeft: "auto", marginRight: "auto", width:"90%", marginTop:"5%"}}></img>
            </TableCell>
            <TableCell>{Object.keys(props.areas).map(e => <TableRow style={{fontSize: "0.75rem"}}><TableCell style={{fontWeight:"bold"}}>{e}</TableCell><TableCell style={{fontSize: "0.75rem"}}>{props.areas[e]}</TableCell></TableRow>)}</TableCell>
            <TableCell>{Object.keys(props.considerations).map(e => <TableRow ><TableCell style={{fontWeight:"bold"}}>{e}</TableCell><TableCell style={{fontSize: "0.75rem"}}>{props.considerations[e]}</TableCell></TableRow>)}</TableCell>
        </TableRow>)
    }

    return <TableRow style={{background: props.background, cursor: "pointer", justifyContent: 'center'}} onClick={e => {setExpanded(!expanded)}}><TableCell colSpan="5" style={{fontWeight:"bold"}}><ExpandMoreIcon  />{props.name}</TableCell></TableRow>
}

export default function VertictalMarketing(props){

    return(
        <div> 
            <div class="tableFixHead">
            <Table stickyHeader={true}>
                <TableHead >
                    <TableCell style={{textAlign: "center", fontWeight:"bold"}}>Vertical Market</TableCell>
                    <TableCell style={{fontWeight:"bold"}}>Common Project Goals</TableCell>
                    <TableCell style={{textAlign: "center", fontWeight:"bold"}}>Product Selection</TableCell>
                    <TableCell style={{textAlign: "center", fontWeight:"bold"}}>Areas of Concern</TableCell>
                    <TableCell style={{textAlign: "center", fontWeight:"bold"}}>Special Consideration</TableCell>
                </TableHead>
                <TableBody style={{border: ""}}>
                    {Object.keys(data).map((e, index) => <ReactRow name={e} goals={data[e].goals} selection={data[e].selection} areas={data[e].areas} considerations={data[e].considerations} background={index % 2 ? "lightgrey" : "inherit" }/>)}
                </TableBody>
            </Table>
            </div>
        </div>
    );
}