import React, { useState } from "react";
import ProjectDetails from "./ProjectDetails";
import Notes from "./Notes";
import Logo from "./Logo";

export default function UnitHeaterTemplate(props) {
  const [model, setModel] = useState("");

  let fan = props.fan;

  return (
    <div>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div>
          <ProjectDetails />
          <table>
            <thead>
              <th colSpan="2">Fan Details: </th>
            </thead>
            <tbody>
              <tr>
                <td>Quantity: </td>
                <td>
                  <input type="number" min="1" required></input>
                </td>
              </tr>
              <tr>
                <td>Model: </td>
                <td>
                  <select required onChange={(e) => setModel(e.target.value)}>
                    <option></option>
                    {fan.models.map((m, idx) => (
                      <option key={idx}>{m}</option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr><td>Fuel Type: </td><td><select required><option></option><option>LPG Propane</option><option>Natural Gas</option></select></td></tr>
              {model && (
                <>
                  <tr>
                    <td>Input Btu/h: </td>
                    <td>{model} MBH</td>
                  </tr>
                  <tr>
                    <td>Output Btu/h: </td>
                    <td>{fan.output[model] || ""} MBH</td>
                  </tr>
                  <tr>
                    <td>Weight: </td>
                    <td>{fan.weight[model] || ""} lbs</td>
                  </tr>
                  <tr>
                    <td>Supply Circuit Size:</td>
                    <td>{fan.scs[model] || ""} A</td>
                  </tr>
                  <tr>
                    <td>Max Amperage:</td>
                    <td>{fan.maxAmps[model] || ""} A</td>
                  </tr>
                  <tr>
                    <td>Fan Diameter:</td>
                    <td>{fan.fanDiameter[model] || ""}&quot; dia</td>
                  </tr>
                  <tr>
                    <td>Outlet Velocity:</td>
                    <td>{fan.outletVelocity[model] || ""} fpm</td>
                  </tr>
                  <tr>
                    <td>Gas Connection:</td>
                    <td>{fan.gasConnection[model] || ""}&quot; NPT</td>
                  </tr>
                  <tr>
                    <td>Vent Connection:</td>
                    <td>{fan.ventConnection[model] || ""} dia.</td>
                  </tr>
                  <tr>
                    <td>Input Power:</td>
                    <td>115 V, 60 Hz</td>
                  </tr>
                  <tr>
                    <td>Airflow:</td>
                    <td>{fan.airflow[model] || ""} cfm</td>
                  </tr>
                  <tr>
                    <td>Sound Level:</td>
                    <td>{fan.soundLevel[model] || ""} dba</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {fan.hb && (
            <table>
              <thead>
                <th colSpan="2">Mounting Hardware: </th>
              </thead>
              <tbody></tbody>
            </table>
          )}
          {model && (
            <table>
              <thead>
                <th colSpan="2">Critical Dimensions: </th>
              </thead>
              <tbody>
                <tr>
                  <td>Dimension (A, B, C)</td>
                  <td>{fan.dimensions[model][0]}</td>
                </tr>
                <tr>
                  <td>Dimension (D, E, F)</td>
                  <td>{fan.dimensions[model][1]}</td>
                </tr>
                <tr>
                  <td>Dimension (G, H, J)</td>
                  <td>{fan.dimensions[model][2]}</td>
                </tr>
                <tr>
                  <td>Dimension (K, M, N)</td>
                  <td>{fan.dimensions[model][3]}</td>
                </tr>
                <tr>
                  <td>Dimension (P, Q, R)</td>
                  <td>{fan.dimensions[model][4]}</td>
                </tr>
                <tr>
                  <td>Dimension (S, T)</td>
                  <td>{fan.dimensions[model][5]}</td>
                </tr>
              </tbody>
            </table>
          )}
          {fan.warranty && (
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Warranty Information </th>
                </tr>
                <tr>
                  <th>Item</th>
                  <th>Period of Coverage</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(fan.warranty).map((e, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{e}</td>
                      <td>{fan.warranty[e]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <div>
          {fan.name && <Logo name={`UH${model ? `-${model}` : ""}`} />}
          {fan.additions && (
            <table>
              <thead>
                <tr>
                  <th colSpan="2">Optional Accessories:</th>
                </tr>
              </thead>
              <tbody>
                {new Array(2).fill(0).map((_) => (
                  <>
                    <tr>
                      <td>
                        <select>
                          <option></option>
                          {fan.additions.map((a, idx) => (
                            <option key={idx}>{a}</option>
                          ))}
                        </select>
                      </td>
                      <td>
                        <select>
                          <option></option>
                          {fan.additions.map((a, idx) => (
                            <option key={idx}>{a}</option>
                          ))}
                        </select>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>
          )}
          {fan.features && (
            <table>
              <thead>
                <th colSpan="2">Features</th>
              </thead>
              <tbody>
                {fan.features.map((e, idx) => (
                  <tr key={idx}>
                    <td>{e}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          <table>
            <thead>
              <tr>
                <th colSpan="2">Technical Notes </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Before installation and operation of heating equipment, read
                  and understand the current installation, operation, and
                  service manual.
                </td>
              </tr>
              <tr>
                <td>
                  ETL rating for altitudes up to 2,000 ft (610m). Not Suitable
                  for wet or salt air environments.
                </td>
              </tr>
              <tr>
                <td>
                  See install manual for more information regarding required
                  clearances to combustables. Maintain clearance to combustables
                  at all times for safety.
                </td>
              </tr>
              <tr>
                <td>All data is subject to change without notice.</td>
              </tr>
              <tr>
                <td>
                  Data indicated in this document were correct at the time of
                  printing with the exception of clerical and/or printing
                  errors.
                </td>
              </tr>
            </tbody>
          </table>
          <Notes />
          {fan.links &&
            Object.keys(fan.links).map((li, idx) => (
              <a key={idx} href={fan.links[li]}>
                {li}
              </a>
            ))}
        </div>
      </div>
      <div
        style={{
          width: "50%",
          margin: "auto",
          display: "grid",
          height: "100",
          alignContent: "center",
        }}
      >
        <img
          style={{ maxHeight: 175, maxWidth: 800 }}
          alt={`${fan.name} dwg`}
          src={`${window.location.origin}/technicalDrawings/${fan.name}.png`}
        />
      </div>
    </div>
  );
}
