import React from "react";
import DropdownC4S1 from "./Navigation/dropdown-4-1";
import DropdownC4S2 from "./Navigation/dropdown-4-2";
import DropdownC4S3 from "./Navigation/dropdown-4-3";

function Chapter4SubNav(props) {
  return (
    <div>
      <DropdownC4S1 />

      <DropdownC4S2 />

      <DropdownC4S3 />
    </div>
  );
}

export default Chapter4SubNav;
