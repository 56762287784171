import React from 'react';
import styled from 'styled-components';

const StyledLogo = styled.img  `
    width:189px;
    margin-bottom:20px;
`;

function Logo(props) {
    return (
        <a href="/">
            <StyledLogo alt={props.alt} src={props.src} />
        </a>
    );
}

export default Logo;