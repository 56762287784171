import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledCaption = styled.p`
  && {
    color: #242424;
    font-size: 12px;
    font-style: italic;
    text-align:center;
    margin: 10px 0px 20px 0px;
    background-color: #ffffff;
  }
`;

function Caption(props) {
  const content = props.children || props.text;

  return <StyledCaption>{content}</StyledCaption>;
}

Caption.propTypes = {
  text: PropTypes.string,
  children: PropTypes.object
};

export default Caption;
