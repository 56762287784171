import React from 'react';
import ProductTemplate from './productTemplate';
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import db from './db'; 

const version = '18.0';

class Wrapper extends React.Component{
  
    render(){
      return(
        <div>
          {this.props.name && <ProductTemplate obj={db[this.props.name]} /> }
        </div>
      );
    }
  }

class Submittal extends React.Component {
    constructor(props){
      super(props);
      this.state = {name: "", valid:false};
    }
  
    render() {
      return (
        <div className="submittal">
        <style>{getStyle()}</style>
        <h1 style={{margin:"10px"}}>Submittal Report V{version}</h1>
        {!this.state.name && <h3>This is the Big Ass Fans Submittal Web Tool. You can begin by selecting a fan from the dropdown list. Required inputs will flash red.</h3>}
        <label>Choose a product:
        <select style={{ margin: 5 }} onChange={e => this.setState({name: e.target.value, valid:false})}>
          <option></option>
          {Object.keys(db).sort().map(e => {
            return(<option key={e} value={e}>{e}</option>);
          })}
        </select></label>
        <form onSubmit={(e)=> {
            e.preventDefault();
            this.setState({valid: true})
          }}>
        <ReactToPrint
            content={() => this.componentRef}
            documentTitle={`${this.state.name} Submittal`}
          > 
          <Wrapper name={this.state.name} ref={(el) => (this.componentRef = el) } valid={this.state.valid} />
          {!this.state.valid && this.state.name && 
          <button type="submit" style={{   
                  border:"none",
                  textAlign:"center",
                  padding:"16px 32px",
                  borderRadius: "4px",
                  width:"250px",
                  cursor:"pointer"
                }}>Validate
          </button>}
          {this.state.valid && <PrintContextConsumer>
          {({ handlePrint }) => (
                <button onClick={handlePrint} style={{
                  border:"none",
                  backgroundColor: "#4CAF50",
                  color:"white",
                  textAlign:"center",
                  padding:"16px 32px",
                  borderRadius: "4px",
                  width:"250px",
                  cursor:"pointer"

                }}>
                  Print
                </button>
              )}
          </PrintContextConsumer>}
          </ReactToPrint>
        </form>
        <div>
          {this.state.name && <h4>Full Warranty information can be found in the product catalog</h4>}
        </div>
        </div>
      );
    }
}

function getStyle(){
  return `
  input {
    border: none;
    width: 100%;
    background-color: inherit;
    font-size: 16px;
  }
  
  body {
    margin: 5px;
    font-family:Arial, Helvetica, sans-serif;
  }
  
  select {
    background-color: inherit;
  }
  
  table select {
    -webkit-appearance: none;
    border: none;
    font-size: 16px;
    width: 100%;
  }
  
  table {
    width: 380px;
    margin: 10px;
    margin-left: 0;
  }
  
  hr {
    margin-top: 0;
  }
  
  td {
    border: 1px solid #ddd;
    padding: 1px;
  }
  
  :required:focus {
    border: 2px solid rgb(228, 101, 101);
    background-color: inherit;
  }
  
  :required:invalid:focus{
    background-color: inherit;
    border: 2px solid rgb(228, 101, 101);
  }
  
  :required:invalid:not(:focus){
    background-color: red;
    animation: mymove 2s infinite;
  }
  
  :required:valid{
    border: none;
  }
  
  .submittal h1, h2, h3 {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  th {
    text-align: left;
    background-color: #565e5b;
    color: white;
    padding: 2px;
  }
  
  tr:hover {background-color: #ddd;}
  
  tr:nth-child(even){background-color: #f2f2f2;}
  
  textarea {
    resize: none;
    overflow: auto;
    width: 375px;
  }
  
  a { color: inherit; margin: 10px;  text-decoration: underline} 
  
  @keyframes mymove {
    0% {background-color: inherit;}
    50% {background-color: rgb(228, 101, 101);}
    100% {background-color: inherit;}
  }
  `
}

export default function Submittals(){

    return(
        <div style={{margin:"5%"}}>
            <Submittal />
        </div>
    )
}