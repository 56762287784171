import React from "react";
import H2 from "../UI-Kit/H2";
import H4 from "../UI-Kit/H4";
import Anchor from "../UI-Kit/Anchor";
import Caption from "../UI-Kit/Caption";
import Container from "@material-ui/core/Container";
import Image from "../UI-Kit/Image";

function C3S2() {
  return (
    <div className="chapter-container">
      <Anchor id="3-2-1" />
      <H2 name="3.2 Occupant Thermal Comfort" />
      <H4 name="3.2.1 Comfort Calculations" />
      <p>
        Applications pertaining specifically to maintaining or improving
        occupant comfort will be discussed in this section.{" "}
      </p>
      <p>
        The CBE Thermal Comfort Tool is an online calculator that performs
        thermal comfort calculations according to the ASHRAE Standard 55
        methodology. The user enters operative temperature, air speed, humidity,
        metabolic rate, and clothing level. The Thermal Comfort Tool calculates
        the PMV, PPD, Sensation, and SET. Using these results, it determines
        compliance with Standard 55. Additionally, it simultaneously plots the
        input condition and alternate space conditions that satisfy Standard 55
        on a psychrometric chart. This plot provides a visual representation of
        where the design condition falls relative to any acceptable conditions.{" "}
      </p>
      <div className="chapter-content">
        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          3.2.1.1 Example: Office Building
        </h5>
        <p>
          For example, if an office building is designed to maintain an
          operative temperature of 77°F (25°C) and relative humidity of 50% in
          the summer with no elevated air speed, the inputs to the Thermal
          Comfort Tool might be shown in Figure 3.2.1a. In this example, a
          typical metabolic rate for typing, 1.1 met, was selected, as well as a
          typical summer indoor clothing insulation level, 0.5 clo.{" "}
        </p>
        <p>
          The results show a PMV of -0.13, which is within the necessary range
          to comply with ASHRAE Standard 55 (-0.5 to 0.5). The Thermal Comfort
          Tool generated a chart illustrating the acceptable range of operative
          temperatures and humidity ratios at these occupant conditions. The
          design point was plotted on this chart and is within the comfort zone,
          demonstrating compliance with Standard 55.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1a.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.2.1a - Design conditions have been plotted on the psychrometric chart and fall within the thermal comfort zone." />
        </Container>

        <p>
          Now consider how thermal comfort would change if the occupants of this
          office building have an important meeting and are wearing suits. The
          clothing level increases to 0.96 clo and the thermal comfort zone has
          shifted. PMV now exceeds 0.5 and conditions no longer comply with
          Standard 55. As shown in Figure 3.2.1b, the design point now falls
          outside the acceptable range.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1b.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.2.1b - An increased clo value has shifted the acceptable thermal comfort zone to the left (cooler temperatures), leaving the space condition point outside the new zone." />
        </Container>

        <p>
          The CBE Thermal Comfort Tool is able to use ASHRAE Standard 55
          methodology to account for elevated air speed in thermal comfort
          calculations. Using the example of the same office with workers
          wearing suits (clothing level of 0.96 clo), the air speed can be
          increased to simulate the cooling effect provided by fans. If instead
          of still air at 20 fpm (0.1 m/s), the air speed is increased to 100
          fpm (0.5 m/s) the thermal comfort zone shifts as shown in Figure
          3.2.1c. The new PMV is 0.01, which complies with Standard 55. The
          Thermal Comfort Tool also calculates the equivalent dry bulb
          temperature that would be required to achieve the same level of
          comfort without the elevated air speed, the “Dry bulb temperature at
          still air.” The difference between the actual dry bulb temperature and
          the dry bulb temperature at still air is the “cooling effect”. These
          values are calculated by the software using the methodology of
          Standard 55 Appendix D.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1c.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.2.1c - Using the same high clo value (suits) as in the previous Figure, the acceptable thermal comfort zone can be shifted to the right (higher temperatures) by increasing the air speed." />
        </Container>

        <h5 className="mb10 mt40 light-grey uppercase font-medium">
          3.2.1.2 Determining Target Air Speed
        </h5>
        <p>
          When determining target air speed the primary consideration is
          providing equivalent comfort in air conditioned spaces or improving
          thermal comfort in unconditioned spaces. In general terms, as the
          space temperature increases to “warm” or “hot”, the air speed must be
          increased to improve thermal comfort.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1c.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.2.1c - Using the same high clo value (suits) as in the previous Figure, the acceptable thermal comfort zone can be shifted to the right (higher temperatures) by increasing the air speed." />
        </Container>
        
        <p>
          When increasing the cooling thermostat setpoint in air conditioned
          spaces to save energy, the air speed must be increased to maintain
          equal thermal comfort to the air conditioned space at the lower
          cooling thermostat setpoint. To achieve equal thermal comfort the PMV
          and PPD values for the new condition must match the baseline
          condition. If the cooling thermostat setpoint were increased without
          increasing the air speed, the PMV and PPD will increase beyond the
          acceptable threshold established by Standard 55.{" "}
        </p>
        <p>
          When adding ceiling fans to an unconditioned space, the primary
          concern is not to maintain the same level of thermal comfort, but to
          improve the thermal comfort. Although it may not be feasible to
          satisfy the requirements of Standard 55, the results will demonstrate
          a higher percentage of satisfied occupants than an unconditioned space
          without a ceiling fan and equate to increased worker productivity as
          well.{" "}
        </p>
        <p>
          Other considerations should be given to air speed, however. There are
          limits to how much air speed can be increased. For example in an
          office or school environment, high air speed may blow papers off desks
          and become a nuisance. Similarly, in industrial applications where
          critical processes are being performed, it may be necessary to limit
          air speed to mitigate interference with the process; adding partitions
          or barriers to protect sensitive processes while still providing
          worker comfort is also an option.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3-2-1-2a.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.2.1.2a - The above figure indicated where to find the equivalent Dry bulb temperature at still air and the corresponding cooling effect in the CBE Thermal Comfort Tool." />
        </Container>

        <p>
          In the example table in Figure 3.2.1.2b below, the Office and School
          space types achieve approximately the same PMV with different cooling
          setpoints. The air speed is increased and allows the thermostat
          setpoints to each be increased by 5°F (2.8°C), saving cooling energy.
          The Still Air examples for Fitness, Manufacturing, and Warehouse all
          have high PMV values, indicating uncomfortable spaces primarily due to
          the high metabolic rate of the occupants. So for these examples, the
          objective is to demonstrate that the addition of fans improves thermal
          comfort by significantly lowering the PMV value for each space. In the
          Fitness example though, the baseline used a lower temperature setpoint
          frequently used in Fitness Centers. Using elevated air speed allows
          the setpoint to be increased while still providing improved thermal
          comfort.{" "}
        </p>

        <Container maxWidth="sm" className="center mt40 mb40">
          <Image
            src="https://design-guide-images.s3.us-east-2.amazonaws.com/3.2.1.2b-Table.png"
            width="100%"
            alt=""
          />
          <Caption text="Figure 3.2.1.2b - The table above illustrates the acceptable rise in dry bulb temperature when elevated airspeed is implemented." />
        </Container>
      </div>
    </div>
  );
}

export default C3S2;
