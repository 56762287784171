import React from "react";
import PropTypes from 'prop-types';
import styled from "styled-components";

const StyledAnchorDiv = styled.a`
  && {
    display: block;
    content: " ";
    margin-top: -70px;
    height: 90px;
    visibility: hidden;
  }
`;

function Anchor(props) {
  return <StyledAnchorDiv id={props.id} />;
}

Anchor.propTypes = {
  id: PropTypes.string
};

export default Anchor;
