import React, { Fragment } from "react";
import { HashLink } from "react-router-hash-link";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

function DropdownC3S1() {
  return (
    <Fragment>
      <ListItem button>
        <HashLink
          scroll={el =>
            el.scrollIntoView({ behavior: "smooth", block: "start" })
          }
          to="/chapter-3#3-1"
        >
          <ListItemText primary="3.1 Introduction" className="white" />{" "}
        </HashLink>
      </ListItem>
    </Fragment>
  );
}

export default DropdownC3S1;
